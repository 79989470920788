import React from 'react';
import styled, { keyframes }  from 'styled-components';
import { mobile, mobileXL, tablet } from '../../responsive';
import { useDispatch, useSelector } from 'react-redux';
import "../../assets/css/loaders.css";


const Container = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #00000080;
    align-items: center;
    justify-content: center;
    ${({ dragging }) => dragging ? 'display: flex;' : 'display: none;'}
    z-index: 11;
`;
const Wrapper = styled.div`
    position: relative;
`;


export default function ResponseLoader() {
  return (
    <Container dragging={true}>
        <Wrapper>
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </Wrapper>
    </Container>
  )
}
