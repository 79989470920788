import {createSlice} from "@reduxjs/toolkit";


const collectionSlice = createSlice({
    name: "collection",
    initialState : {
        collections : []
    },
    reducers: {

        SetCollections: (state, action) => {
            state.collections = action.payload;
        },
        AddCollection: (state, action) => {
            state.collections.push(action.payload);
        },
        UpdateCollection: (state, action) => {
            let new_updated_data = 
                state.collections.map((col) => {
                    if (col.id === action.payload.id) {
                        return {
                            ...col,
                            ...action.payload,
                        };
                    }
                    return col;
                });
            
            state.collections = new_updated_data;
        },
        RemoveCollection: (state, action) => {
            state.collections.splice(
                state.collections.findIndex((x) =>  x.id === action.payload ),
                1
              );
        },
        ClearCollections: (state) => {
            state.collections = [];
        },
    },
});

export const { SetCollections, AddCollection, UpdateCollection, RemoveCollection, ClearCollections } = collectionSlice.actions;
export default collectionSlice.reducer;