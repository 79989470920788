import {createSlice} from "@reduxjs/toolkit";


const responseSlice = createSlice({
    name: "response",
    initialState : {
        response : {
            active: false,
            success: false,
            message: ""
        },
        loader : {
            active: false
        }
    },
    reducers: {

        updateResponse: (state, action) => {
            state.response = action.payload;
        },
        clearResponse: (state) => {
            state.response = {
                active: false,
                success: false,
                message: ""
            };
        },
        StartLoader: (state) => {
            state.loader = {
                active: true
            };
        },
        StopLoader: (state) => {
            state.loader = {
                active: false
            };
        },
    },
});

export const { updateResponse, clearResponse, StartLoader, StopLoader } = responseSlice.actions;
export default responseSlice.reducer;