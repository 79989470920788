import {createSlice} from "@reduxjs/toolkit";


const nonentitySlice = createSlice({
    name: "nonentity",
    initialState : {
        industries: [],
        businessCategories: [],
        collectionCategories: [],
        productCategories: [],
        currencies: []
    },
    reducers: {

        SetIndustries: (state, action) => {
            state.industries = action.payload;
        },
        SetBusinessCategories: (state, action) => {
            state.businessCategories = action.payload;
        },
        SetCollectionCategories: (state, action) => {
            state.collectionCategories = action.payload;
        },
        SetProductCategories: (state, action) => {
            state.productCategories = action.payload;
        },
        SetCurrencies: (state, action) => {
            state.currencies = action.payload;
        },
        ClearNonEntities: (state) => {
            state.industries = [];
            state.businessCategories = [];
            state.collectionCategories = [];
            state.productCategories = [];
            state.currencies = [];
        },

    },
});

export const { 
    SetIndustries, 
    SetBusinessCategories,
    SetCollectionCategories,
    SetProductCategories,
    SetCurrencies,
    ClearNonEntities
 } = nonentitySlice.actions;

export default nonentitySlice.reducer;