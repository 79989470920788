import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import { useSelector } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import { 
  Create,
  AddBoxOutlined,
  DeleteOutlined,
  ChevronRight,
  Add
  } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import {publicRequest , BASE_URL, BASE_RSEOURCE_URL} from "../../requestMethods";

import { useNavigate, useLocation } from "react-router-dom";

import axios from 'axios';
// import Moment from 'moment';

const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    min-height: 100vh;
    margin-left: 200px;
    padding: 40px;
    background-color: #e2e2e2;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "10px 0px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
    position: relative;
    ${mobile({
        marginTop: "40px"
    })} 
`;
const Header = styled.div`
    width: 100%;
    border-bottom: 1px solid #00000030;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none",
      padding: "10px 15px 20px 15px"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    ${mobile({
        fontSize: "23px"
    })}
`;
const AddBtnMob = styled.div`
    position: fixed;
    bottom: 30px;
    left: 20px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #B8F904;
    padding: 8px 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    ${mobile({
      display: "flex"
    })}
`;
const AddBtnText = styled.div`
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;
const AddBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000FD;
    padding: 8px 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #B8F904;
      ${AddBtnText} {
        color: #000;
      }
    }
    ${mobile({
      display: "none"
    })}
`;

const SubHeader = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #00000030;
    ${mobile({
      display: "none"
    })}
`;
const SortContainer = styled.div`
    width: 100%;
    margin: 10px 0px;
    ${mobile({
      padding: "0px 15px"
    })}
`;
const SortBtnText = styled.div` 
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 5px;
  transition: all 0.3s ease;
  ${mobile({
      fontSize: "15px"
  })}
`;
const SortIconWrap = styled.div`
  display: flex;
  /* justify-content: center;
  align-items: center; */
  /* border: 1px solid #00000040; */
  /* rotate: 90deg; */
  background-color: #000000;
  rotate: ${props=>props.dragging === true ? "0deg" : "90deg"};
  transition: all 0.3s ease;
`;
const SortBtn = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* &:hover {
        ${SortBtnText} {
            color: #00000080;
        }
        ${SortIconWrap} {
            background-color: #00000080;
        }
    } */
`;

const Body = styled.div`
  ${mobile({
    marginTop: "20px"
  })}
`;
const CollectionsWrap = styled.div`
    width: 100%;
`;
const Item = styled.div`
    width: 100%;
    background-color: #ffffff;
    padding: 20px 20px;
    margin-bottom: 10px;
    display: flex;
    transition: all 0.3s ease;
    background-color: ${props=>props.status === "success" ? "#ffffff" : "#faeaea"};
    cursor: pointer;
    &:hover {
      background-color: #ec7fbb;
      }
    ${mobile({
        padding: "15px 10px"
    })}
`;
const Image = styled.div`
  width: 80px;
  height: 100px;
  margin-right: 20px;
  background-color: #212121;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease;
  ${tablet({
    marginRight: "10px",
    width: "60px",
    height: "75px"
  })}
`;
const HeadItem = styled.div`
    width: 100%;
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
    display: flex;
`;

const HeadNameWrap = styled.div`
  width: 30%;
  //margin-right: 20px;
  display: flex;
  flex-direction: column;
  /* ${tablet({
    width: "calc(100% - 330px)",
    marginRight: "10px"
  })} */
  ${mobile({
    width: "calc(100% - 90px)",
    height: "75px",
    marginRight: "10px"
  })}
`;
const HeadName = styled.div`
  width: 100%;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
`;
const HeadCategory = styled.div`
  width: 30%;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  //margin-right: 20px;
  text-transform: capitalize;
  /* ${tablet({
      display: "none"
  })} */
`;
const HeadDetails = styled.div`
  display: flex;
  width: 40%;
  //margin-right: 20px;
  /* ${tablet({
    marginRight: "10px"
  })} */
  ${mobile({
      display: "none"
    })}
`;
const HeadReleaseDate = styled.div`
  width: calc(100% - 90px);
  //margin-right: 20px;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  /* ${tablet({
    marginRight: "10px"
  })} */
`;
const HeadStatus = styled.div`
  width: 90px;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
`;

///////// END HEAD /////////////
const NameWrap = styled.div`
  width: 30%;
  //margin-right: 20px;
  display: flex;
  flex-direction: column;
  /* ${tablet({
    width: "calc(100% - 340px)",
    marginRight: "10px"
  })} */
  ${mobile({
    width: "calc(100% - 90px)",
  })}
`;
const Name = styled.div`
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 5px;
`;
const Profile = styled.div`
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`;
const MobilePrice = styled.div`
  display: none;
  color: #00000060;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  ${mobile({
    marginTop: "5px",
      display: "block"
  })}
`;
const Category = styled.div`
  width: 30%;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  //margin-right: 20px;
  ${tablet({
      display: "none"
  })}
`;
const Details = styled.div`
    width: 40%;
    display: flex;
  //margin-right: 20px;
  /* ${tablet({
    marginRight: "10px"
  })} */
  ${mobile({
      display: "none"
    })}
`;
const ReleaseDate = styled.div`
  width: calc(100% - 90px);
  //margin-right: 20px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  /* ${tablet({
    marginRight: "10px"
  })} */
`;
const Status = styled.div`
  width: 90px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`;

const EditBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  margin-right: 10px;
  ${mobile({
    alignItems: "start",
    marginRight: "0px",
    marginBottom: "20px"
  })}
`;
const EditBtnText = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 3px;
`;
const DelBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
`;
const DelBtnText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    margin-top: 3px;
`;
const SortMobileBtn = styled.div`
  width: 30px;
  height: 30px;
  background-color: #4a4a53;
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 10px;
  display: none;
  ${mobile({
    display: "block",
  })}
`;

const NoContentText = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  margin-top: 50px;
  ${mobile({
    padding: "0px 15px",
  })}
`;

const useStyles = makeStyles({
  ActionIcons: {
      color: "#00000060",
      fontSize: 18,
      cursor: "pointer",
      '@media (max-width: 768px)': {
        fontSize: 22,
      }
  },
  AddIcon: {
    color: "#000",
    fontSize: 25
  },
  SortIcon: {
    color: "#B8F904",
    fontSize: 15
  },
})


export default function Orders() {
    const [orders, setOrders] = useState([]);

    const getOrders = async () => {
        try {
        const res = await publicRequest.get("/order");
          let sortedData = [...res.data].sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
          console.log(sortedData);
          setOrders(sortedData);

        } catch (err) {

        }
    };
    useEffect(()=>{
        getOrders();
    },[]);

  return (
    <Container>
        <Wrapper>
            <Header>
                <PageTitle>Orders</PageTitle>
            </Header>
            <SubHeader>
            <HeadItem>
                  <HeadNameWrap>
                    <HeadName>Email</HeadName>
                  </HeadNameWrap>
                  <HeadCategory>Reference</HeadCategory>
                  <HeadDetails>
                    <HeadReleaseDate>Amount</HeadReleaseDate>
                    <HeadStatus>Status</HeadStatus>
                  </HeadDetails>
                </HeadItem>
          </SubHeader>
          <Body>
              <CollectionsWrap>
              {orders.map(order => (
                    <Link to={`/user/order/${order.id}`} style={{ textDecoration: 'none' }}>
                        <Item key={order.id} status={order.paymentStatus}>
                            <NameWrap>
                            <Name>{order.customer.email}</Name>
                            {/* <MobilePrice>N120</MobilePrice> */}
                            </NameWrap>
                            <Category>{order.reference}</Category>
                            <Details>
                            <ReleaseDate>{order.amountCharged.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {order.currencyPaid}</ReleaseDate>
                            <Status>{order.paymentStatus}</Status>
                            </Details>
                        </Item>
                    </Link>

                ))}
              </CollectionsWrap>
{/* 
              <NoContentText>
                No item found
              </NoContentText> */}
          </Body>
        </Wrapper>
    </Container>
  )
}
