import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import MainApp from '../layouts/MainApp';

const PrivateRoutes = () => {
    let auth = {'token':false}
    const token = useSelector((state) => state.user.accessToken);
    return(
        token != "" ? <MainApp/> : <MainApp/> //<Navigate to="/"/>
    )
}

export default PrivateRoutes