import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import { useSelector } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import { 
  Create,
  AddBoxOutlined,
  DeleteOutlined,
  ChevronRight,
  Add
  } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import SortCategories from '../../components/product/SortCategories';
import {publicRequest , BASE_URL, BASE_RSEOURCE_URL} from "../../requestMethods";

// import ItemImg1 from '../../assets/images/items/item1.jpg';
// import ItemImg2 from '../../assets/images/items/item2.jpg';
// import ItemImg3 from '../../assets/images/items/item3.jpg';
// import ItemImg4 from '../../assets/images/items/item4.jpg';
// import ItemImg5 from '../../assets/images/items/item5.jpg';
// import ItemImg6 from '../../assets/images/items/item6.jpg';
// import ItemImg7 from '../../assets/images/items/item7.jpg';
// import ItemImg8 from '../../assets/images/items/item8.jpg';

import { useNavigate, useLocation } from "react-router-dom";
import Response from '../../components/generic/Response';
import ResponseLoader from '../../components/generic/ResponseLoader';

import axios from 'axios';
// import Moment from 'moment';
import DeleteProduct from '../../components/controllers/DeleteProduct';

const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    min-height: 100vh;
    margin-left: 200px;
    padding: 40px;
    background-color: #e2e2e2;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "10px 0px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
    position: relative;
    ${mobile({
        marginTop: "40px"
    })} 
`;
const Header = styled.div`
    width: 100%;
    border-bottom: 1px solid #00000030;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none",
      padding: "10px 15px 20px 15px"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    ${mobile({
        fontSize: "23px"
    })}
`;
const AddBtnMob = styled.div`
    position: fixed;
    bottom: 30px;
    left: 20px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #B8F904;
    padding: 8px 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    ${mobile({
      display: "flex"
    })}
`;
const AddBtnText = styled.div`
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;
const AddBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000FD;
    padding: 8px 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #B8F904;
      ${AddBtnText} {
        color: #000;
      }
    }
    ${mobile({
      display: "none"
    })}
`;

const SubHeader = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #00000030;
    ${mobile({
      display: "none"
    })}
`;
const SortContainer = styled.div`
    width: 100%;
    margin: 10px 0px;
    ${mobile({
      padding: "0px 15px"
    })}
`;
const SortBtnText = styled.div` 
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 5px;
  transition: all 0.3s ease;
  ${mobile({
      fontSize: "15px"
  })}
`;
const SortIconWrap = styled.div`
  display: flex;
  /* justify-content: center;
  align-items: center; */
  /* border: 1px solid #00000040; */
  /* rotate: 90deg; */
  background-color: #000000;
  rotate: ${props=>props.dragging === true ? "0deg" : "90deg"};
  transition: all 0.3s ease;
`;
const SortBtn = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* &:hover {
        ${SortBtnText} {
            color: #00000080;
        }
        ${SortIconWrap} {
            background-color: #00000080;
        }
    } */
`;

const Body = styled.div`
  ${mobile({
    marginTop: "20px"
  })}
`;
const CollectionsWrap = styled.div`
    width: 100%;
`;
const Item = styled.div`
    width: 100%;
    background-color: #ffffff;
    padding: 20px 20px;
    margin-bottom: 10px;
    display: flex;
    transition: all 0.3s ease;
    background-color: ${props=>props.active === false && "#faefef"};
    cursor: pointer;
    &:hover {
      background-color: #ec7fbb;
      }
    ${mobile({
        padding: "15px 10px"
    })}
`;
const Image = styled.div`
  width: 80px;
  height: 100px;
  margin-right: 20px;
  background-color: #212121;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease;
  ${tablet({
    marginRight: "10px",
    width: "60px",
    height: "75px"
  })}
`;
const HeadItem = styled.div`
    width: 100%;
    padding: 20px 20px 0px 0px;
    margin-bottom: 20px;
    display: flex;
`;
const HeadImage = styled.div`
  width: 100px;
  margin-right: 20px;
  ${tablet({
    marginRight: "10px",
    width: "80px"
  })}
`;
const HeadNameWrap = styled.div`
  width: 30%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  ${tablet({
    width: "calc(100% - 330px)",
    marginRight: "10px"
  })}
  ${mobile({
    width: "calc(100% - 90px)",
    height: "75px",
    marginRight: "10px"
  })}
`;
const HeadName = styled.div`
  width: 100%;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
`;
const HeadCategory = styled.div`
  width: calc(70% - 380px);
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  margin-right: 20px;
  text-transform: capitalize;
  ${tablet({
      display: "none"
  })}
`;
const HeadDetails = styled.div`
  display: flex;
  width: max-content;
  margin-right: 20px;
  ${tablet({
    marginRight: "10px"
  })}
  ${mobile({
      display: "none"
    })}
`;
const HeadReleaseDate = styled.div`
  width: 80px;
  margin-right: 20px;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  ${tablet({
    marginRight: "10px"
  })}
`;
const HeadStatus = styled.div`
  width: 90px;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
`;
const HeadActions = styled.div`
  width: 80px;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
`;

///////// END HEAD /////////////
const NameWrap = styled.div`
  width: 30%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  ${tablet({
    width: "calc(100% - 340px)",
    marginRight: "10px"
  })}
  ${mobile({
    width: "calc(100% - 90px)",
  })}
`;
const Name = styled.div`
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize;
  margin-bottom: 5px;
`;
const Profile = styled.div`
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`;
const MobilePrice = styled.div`
  display: none;
  color: #00000060;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  ${mobile({
    marginTop: "5px",
      display: "block"
  })}
`;
const Category = styled.div`
  width: calc(70% - 370px);
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
  text-transform: capitalize;
  ${tablet({
      display: "none"
  })}
`;
const Details = styled.div`
  display: flex;
  width: max-content;
  margin-right: 20px;
  ${tablet({
    marginRight: "10px"
  })}
  ${mobile({
      display: "none"
    })}
`;
const ReleaseDate = styled.div`
  width: 80px;
  margin-right: 20px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  ${tablet({
    marginRight: "10px"
  })}
`;
const Status = styled.div`
  width: 90px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`;
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: max-content;
  ${mobile({
    flexDirection: "column",
    justifyContent: "start",
    // alignItems: "flex-end",
  })}
`;
const EditBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  margin-right: 10px;
  ${mobile({
    alignItems: "start",
    marginRight: "0px",
    marginBottom: "20px"
  })}
`;
const EditBtnText = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 3px;
`;
const DelBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
`;
const DelBtnText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    margin-top: 3px;
`;
const SortMobileBtn = styled.div`
  width: 30px;
  height: 30px;
  background-color: #4a4a53;
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 10px;
  display: none;
  ${mobile({
    display: "block",
  })}
`;

const NoContentText = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  margin-top: 50px;
  ${mobile({
    padding: "0px 15px",
  })}
`;

const useStyles = makeStyles({
  ActionIcons: {
      color: "#00000060",
      fontSize: 18,
      cursor: "pointer",
      '@media (max-width: 768px)': {
        fontSize: 22,
      }
  },
  AddIcon: {
    color: "#000",
    fontSize: 25
  },
  SortIcon: {
    color: "#B8F904",
    fontSize: 15
  },
})

export default function Products() {
  const classes = useStyles();
  const [isSortOpen, setIsSortOpen] = useState(false);
  const  user = useSelector((state) => state.user.currentUser);
  const response = useSelector(state=>state.response.response);
  const responseLoader = useSelector(state=>state.response.loader);


  const collections = useSelector(state=>state.collection.collections);
  const products = useSelector(state=>state.product.products);
  const currencies = useSelector(state=>state.nonentity.currencies);

  console.log(products);

  const [productsInView, setProductsInview] = useState(products);
  const [collectionsMod, setCollectionsMod] = useState([]);
  const [currenciesMod, setCurrenciesMod] = useState([]);
  const [sortCollectionName , setCollectionName ] = useState("all products");

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const CloseDeleteModal = () => {
    setIsDeleteOpen(false);
  };

  const OpenDeleteModal = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteBtn = (e, id) => {
    e.preventDefault();
    setDeleteId(id);
    OpenDeleteModal();
  };


  useEffect(()=>{

    document.title = "";
    window.scrollTo(0, 0);

  },[]);

  useEffect(() => {
    const updateDimension = () => {
        setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    //console.log(screenSize.width);

    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
}, [screenSize])


  useEffect(()=>{
    const getCollectionMod = async () => {

      if (collections) {
        let _collectionX = [];
        for (let col in collections) {
          for (let cur in currencies ) {  
            if (currencies[cur].id == collections[col].currencyId) {
              _collectionX[collections[col].id] = {
                name: collections[col].name,
                currencyId: collections[col].currencyId,
                symbol: currencies[cur].symbol
              }
            }
          }
        }
        console.log("CollectionX: ", _collectionX);
        setCollectionsMod(_collectionX);
      }

    };
    getCollectionMod();
  },[collections, currencies]);



  const OpenSort = () => {
    setIsSortOpen(!isSortOpen);
  }; 

  const getProductsByCollection = (collection) => { 
    let sort_collection_name = "all products";
    
    if (collection == 0 ) {
      setProductsInview(products);
    }
    else {
      let productsArray = [];

      products.map(x => {
        if(x.collectionId == collection.id)
          productsArray.push(x);
          sort_collection_name = collection.name;
      });
  
      setProductsInview(productsArray);
    }

    if(screenSize.width < 559) {
      setIsSortOpen(false);
    }
    setCollectionName(sort_collection_name);

  };

  function getCurrentDimension() {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
  }

  return (
    <Container>
        <Wrapper>
        {response.active == true ?
            <Response />
            :
            <></>
        }
          <Link to={`/user/product/add`} style={{ textDecoration: 'none' }}>
            <AddBtnMob>
              <Add className={classes.AddIcon}/>
            </AddBtnMob>
          </Link>
          <Header>
            <PageTitle>Products ({productsInView.length})</PageTitle>
            <Link to={`/user/product/add`} style={{ textDecoration: 'none' }}>
              <AddBtn>
                <AddBtnText>Add product</AddBtnText>
              </AddBtn>
            </Link>
          </Header>
          <SubHeader>
            <HeadItem>
                <HeadImage>
                  {/* <SortBtn onClick={OpenSort}>
                      <SortBtnText>{sortCollectionName}</SortBtnText>
                      <SortIconWrap dragging={isSortOpen}>
                          <ChevronRight className={classes.SortIcon} />
                      </SortIconWrap>
                  </SortBtn> */}
                </HeadImage>
                  <HeadNameWrap>
                    <HeadName>Name & collection</HeadName>
                  </HeadNameWrap>
                  <HeadCategory>Type</HeadCategory>
                  <HeadDetails>
                    <HeadReleaseDate>price</HeadReleaseDate>
                    <HeadStatus>Status</HeadStatus>
                  </HeadDetails>
                  <HeadActions>
                    Actions
                  </HeadActions>
                </HeadItem>
          </SubHeader>
          <SortContainer>
            <SortBtn onClick={OpenSort}>
                <SortBtnText>{sortCollectionName}</SortBtnText>
                <SortIconWrap dragging={isSortOpen}>
                    <ChevronRight className={classes.SortIcon} />
                </SortIconWrap>
            </SortBtn>
          </SortContainer>
          <Body>
            {productsInView.length > 0 ?
              <CollectionsWrap>
              {productsInView.map(product => (
                <Link key={product.id} to={`/user/product/${product.id}`} style={{ textDecoration: 'none' }}>
                  <Item active={product.active}>
                    <Image style={{ backgroundImage: `url(${BASE_RSEOURCE_URL + "/uploads/images/product/" + product.colors[0].coverImage})`}} />
                    <NameWrap>
                      <Name>{product.name}</Name>
                      <Profile>nan</Profile>
                      <MobilePrice>N120</MobilePrice>
                    </NameWrap>
                    <Category>nan</Category>
                    <Details>
                      <ReleaseDate>N{product.colors[0].priceUSD.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</ReleaseDate>
                      <Status>{product.active == true ? "active" : "suspended"}</Status>
                    </Details>
                    <Actions>
                      <EditBtn>
                        {/* <Create className={classes.ActionIcons} /> */}
                        <Tooltip title="Edit">
                          <Create className={classes.ActionIcons} />
                        </Tooltip>
                      </EditBtn>
                      <DelBtn onClick={e => handleDeleteBtn(e, product.id)}>
                        <Tooltip title="Delete">
                          <DeleteOutlined className={classes.ActionIcons} />
                        </Tooltip>
                        {/* <DeleteOutlined className={classes.ActionIcons} /> */}
                      </DelBtn>
                    </Actions>
                  </Item>
                </Link>
              ))}

              </CollectionsWrap>
              :
              <NoContentText>
                No item found
              </NoContentText>
            }
          </Body>
          <SortCategories 
            isSortOpen={isSortOpen}
            setIsSortOpen={setIsSortOpen}
            collections={collections}
            getProductsByCollection={getProductsByCollection}
          />
          <DeleteProduct
            deleteId={deleteId}
            isDeleteOpen={isDeleteOpen}
            setIsDeleteOpen={setIsDeleteOpen}
            CloseDeleteModal={CloseDeleteModal}
            productsInView={productsInView}
            setProductsInview={setProductsInview}
          />
        </Wrapper>
        { responseLoader.active == true ?
            <ResponseLoader />
            :
            <></>
        }
    </Container>
  )
}
