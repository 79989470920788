import { configureStore, combineReducers } from "@reduxjs/toolkit";
import responseReducer from "./responseRedux";
import userReducer from "./userRedux";
import profileReducer from "./profileRedux";
import productReducer from "./productRedux";
import collectionReducer from "./collectionRedux";
import categoryReducer from "./categoryRedux";
import nonentityReducer from "./nonentityRedux";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer = combineReducers({ 
  user: userReducer,
  response: responseReducer,
  profile: profileReducer,
  product: productReducer,
  collection: collectionReducer,
  category: categoryReducer,
  nonentity: nonentityReducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer, 
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);