import {publicRequest, userRequest } from "../requestMethods";
import { useDispatch, useSelector } from "react-redux";
import { LogoutUser } from "../redux/userRedux";
import { ClearProfiles } from "../redux/profileRedux";
import { ClearProducts } from "../redux/productRedux";
import { ClearCollections } from "../redux/collectionRedux";
import { ClearNonEntities } from "../redux/nonentityRedux";

const useLogout = () => {
    const dispatch = useDispatch();
    const  user = useSelector((state) => state.user);

    const logout = async () => {
        const response = await publicRequest.get('/Authentication/logout/',
        {
            withCredentials: true
        });
        //localStorage.clear();
        dispatch(LogoutUser());
        dispatch(ClearProfiles());
        dispatch(ClearProducts());
        dispatch(ClearCollections());
        dispatch(ClearNonEntities());
        return response.data;
    }
    return logout;
};

export default useLogout;