import React, { useEffect, useState } from "react";
import styled, { keyframes }  from 'styled-components';
import { mobile, mobileXL, tablet } from '../responsive';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from "@material-ui/icons";
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';

import { BASE_PUBLIC_URL, BASE_RSEOURCE_URL} from "../requestMethods";


const Container = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #00000050;
    align-items: center;
    justify-content: center;
    ${({ dragging }) => dragging ? 'display: flex;' : 'display: none;'}
    z-index: 11;
`;
const Wrapper = styled.div`
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    margin: 0px 15px;
    position: relative;
`;
const Top = styled.div`
    width: 100%;
    padding: 20px;
`;
const Title= styled.div`
    color: #000000;
    font-size: 20px;
    font-weight: 600px;
    text-transform: capitalize;
    margin-bottom: 20px;
`;
const Desc = styled.div`
    color: #777777;
    font-size: 16px;
    font-weight: 300px;
`;
const Bottom = styled.div`
    width: 100%;
    padding: 15px 20px;
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const Cancel = styled.div`
    color: #000000;
    font-size: 16px;
    font-weight: 600px;
    text-transform: capitalize;
    cursor: pointer;
`;
const DeleteBtn = styled.div`
    padding: 8px 15px;
    background-color: #000;
    margin-left: 30px;
    cursor: pointer;
`;
const DelText = styled.div`
    color: #B8F904;;
    font-size: 16px;
    font-weight: 600px;
    text-transform: capitalize;
`;

const Banner = styled.div`
    width: 100%;
    padding: 30px 20px 30px 20px;
    background-color: #B8F904;
`;
const BannerTop = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const ProfileName = styled.div`
    width: calc(50% - 10px);
    color: #000;
    font-size: 21px;
    font-weight: 500;
    line-height: 26px;
    text-transform: capitalize;
`;
const WebUrl= styled.div`
    width: calc(50% - 10px);
    text-align: right;
    color: #000;
    font-size: 15px;
    font-weight: 400;
`;
const BannerCenter = styled.div`
    width: 100%;
    margin-top: 10px;
    aspect-ratio: 5 / 2;
    background-color: #afafaf;
    background-size: cover;
    background-position: center;
    &:before {
      float: left;
      padding-top: 45%;
      content: '';
  }
  &:after {
      display: block;
      content: '';
      clear: both;
  }
`;
const BannerCenterImg = styled.img`
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    //display: none;
`;

const BannerBottom = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;
const QrCode = styled.div`
    width: calc(50% - 5px);
    height: min-content;
    padding: 15px;
    background-color: #fff;
`;
const ScanCodeWrap = styled.div`
    width: calc(50% - 5px);
`;
const ScanCodeText = styled.div`
    color: #000;
    font-size: 23px;
    font-weight: 400;
`;
const ScanText = styled.div`
    color: #000;
    font-size: 16px;
    font-weight: 400;
`;

const useStyles = makeStyles({
    CloseIcon: {
        color: "#000000",
        fontSize: 22
      },
  })

export default function ProfileQrCodePoster({
    CloseQrCode,
    isQrCodeOpen,
    business,
    assetUrl
}) {
    const classes = useStyles();


    const downloadImage = () => {
        const screenshotTarget = document.getElementById("poster");
      
        html2canvas(screenshotTarget, {allowTaint: true, useCORS: true}).then(canvas => {
            const base64image = canvas.toDataURL("image/png");
            var anchor = document.createElement('a');
            anchor.setAttribute('href', base64image);
            anchor.setAttribute('download', "qrcode_poster_klustaa.png");
            anchor.click();
            anchor.remove();
        });

        CloseQrCode();
      
      };
  return (
    <Container dragging={isQrCodeOpen}>
        <Wrapper>
            <Top>
                <Banner id="poster">
                    <BannerTop>
                        <ProfileName>{business.businessName}</ProfileName>
                        <WebUrl>www.klustaa.com</WebUrl>
                    </BannerTop>
                    <BannerCenter>
                        <BannerCenterImg crossOrigin="anonymous" src={BASE_RSEOURCE_URL + "/uploads/user/business/images/" + business.coverImage}/>
                    </BannerCenter>
                    {/* <BannerCenterImg crossorigin="anonymous" src={BASE_RSEOURCE_URL + "/uploads/user/business/images/" + business.coverImage} />
                    <BannerCenter style={{ backgroundImage: `url(${BASE_RSEOURCE_URL + "/uploads/user/business/images/" + business.coverImage})`}}/> */}
                    <BannerBottom>
                        <QrCode>
                            <QRCode
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={assetUrl} />
                        </QrCode>
                        <ScanCodeWrap>
                            <ScanCodeText>Scan code</ScanCodeText>
                            <ScanText>
                                to see <span className="asset_name">{business.businessName}'s</span> collections on <span className="logo_klustaa">klustaa</span>
                            </ScanText>
                        </ScanCodeWrap>
                    </BannerBottom>
                </Banner>
            </Top>
            <Bottom>
                <Cancel onClick={CloseQrCode}>Cancel</Cancel>
                <DeleteBtn onClick={downloadImage}>
                    <DelText>Download</DelText>
                </DeleteBtn>
            </Bottom>
            {/* <CloseIcon onClick={CloseQrCode}>
                <Close className={classes.CloseIcon}/>
            </CloseIcon> */}
        </Wrapper>
    </Container>
  )
}
