import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import { Link, useNavigate } from "react-router-dom";
import {publicRequest , BASE_RSEOURCE_URL} from "../../requestMethods";
import { makeStyles } from '@material-ui/core/styles';
import { AddCollection as AddToCollections } from '../../redux/collectionRedux';
import { updateResponse, StartLoader, StopLoader } from '../../redux/responseRedux';
import {  AddAPhoto } from "@material-ui/icons";
import "../../assets/css/form.css";
import { useDispatch, useSelector } from "react-redux";
import ResponseLoader from '../../components/generic/ResponseLoader';
import useUserRequest from '../../hooks/useUserRequest';

const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    margin-left: 200px;
    padding: 60px 60px;
    background-color: #e2e2e2;
    ${tablet({
        padding: "20px 15px"
    })}  
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "20px 0px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;
    ${mobile({
        marginTop: "40px"
    })}  
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none",
      padding: "10px 15px 20px 15px"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    ${mobile({
        fontSize: "23px"
    })}
`;
const ProgressBarWrap = styled.div`
    width: 100%;
    background-color: #d8d8d8;
    border-bottom: 1px solid #61616140;
    padding: 0px 20px;
    ${tablet({
        padding: "0px 15px",
        height: "63px"
    })} 
`;
const ProgressBar = styled.div`
    width: 100%;
    display: flex;
    ${tablet({
      display: "block",
      position: "relative"
    })}
`;

const ProgressBarItem = styled.div`
    width: ${props=>props.width}%;
    //border: 1px solid #000;
    @media (max-width: 1024px) {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        //transform: ${props=>props.dragging === true ? "translateX(0%)" : "translateX(-101%)"};
    }
`;
const ProgressBarText = styled.div`
    width: max-content;
    transition: all 0.3s ease;
    color: ${props=>props.active === true ? "#0000FD" : "#7e7e7e"};
    font-size: 14px;
    font-weight: ${props=>props.active === true ? "600" : "300"};
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 20px 0px;
    border-bottom: ${props=>props.active === true ? "2px solid #0000FD" : "none"};
    @media (max-width: 1024px) {
        opacity:  ${props=>props.active === true ? 1 : 0};
    }
`;
const FormContainer = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    transition: all 0.3s ease;
    transform: translateX(${props=>props.xValue}%);
    ${tablet({
        //marginTop: "100px"
    })}
`;
const FormSectionA = styled.div`
    width: 100%;
        ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;

const FormItem = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    ${tablet({
        flexDirection: "column"
    })}  
`;
const Left = styled.div`
    width: 35%;
    margin-right: 5%;
    ${tablet({
        width: "100%",
        marginRight: "0%",
        marginBottom: "10px"
    })}  
`;
const LabelDesc = styled.div`
    width: 80%;
    margin-top: 5px;
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 300;
`;


const Right = styled.div`
    width: 60%;
    ${tablet({
        width: "100%"
    })}  
`;
const InputItem = styled.div`

`;
const LinkSamleText = styled.div`
    color: #a8a8a8;
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
`;
const ErrorMsg = styled.div`
    color: #f53b3b;
    font-size: 16px;
    font-weight: 300;
    margin-top: 5px;
`;
const CtaWrap = styled.div`
    width: 100%;
    margin-top: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${tablet({
        width: "calc(100% - 20px)"
    })}  

`;
const BtnWrap = styled.div`
    display: flex;
    width: max-content;
    ${mobile({
        width: "100%",
        justifyContent: "space-between"
    })} 
`;
const CancelBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    margin-right: 20px;
    cursor: pointer;
`;
const CancelBtnText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
`;
const ContinueBtnText = styled.div`
    color: #B8F904;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;
const ContinueBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    background-color: #000;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #B8F904;
      ${ContinueBtnText} {
        color: #000;
      }
    }
    ${mobile({
        padding: "15px 30px",
    })} 
`;


/////////
const ImageSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;
const BgPhoto = styled.div`
    width: 100%;
    position: relative;
    aspect-ratio: 10 / 4;
    background-color: #939494;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    &:before {
        float: left;
        padding-top: 40%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
    @media (max-width: 768px) {
        aspect-ratio: 10 / 5;
        &:before {
        padding-top: 50%;
        }
    }
`;

const AddPhotoBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 5px 10px;
    background-color: #f0f1f5;
    border-radius: 7px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #e9e6e6;
    }
    ${mobile({
      bottom: "5px",
      left: "5px"
    })}
`;
const AddPhotoText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    margin-top: 3px;
    ${mobile({
      fontSize: "11px"
    })}
`;
const CoverPhoto = styled.div`
    width: 40%;
    margin-top: -27%;
    margin-right: 30px;
    aspect-ratio: 4 / 5;
    background-color: #212121;
    border: 3px solid #ffffff;
    background-size: cover;
    background-position: center;
    position: relative;
    /* z-index: 2; */
    cursor: pointer;
    &:before {
        float: left;
        padding-top: 125%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
    @media (max-width: 768px) {
        width: 45%;
    }
`;

const ToggleSwitchWrap = styled.div`
    display: flex;
    align-items: center;
`;

//////////
const Switch = styled.div`
    //border: 1px solid #000;
    display: flex;
    align-items: center;
`;
const SwitchValue = styled.div`
    margin-left: 20px;
    margin-bottom: 5px;
`;
const Submit = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    ${mobile({
        justifyContent: "center",
    })}  
`;
const SubmitBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    padding: 15px 20px;
    background-color: #1b87e6;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #3da3fc;
    }
    ${mobile({
        width: "100%",
        padding: "25px 10px"
    })}  
`;

const SubmitBtnText = styled.div`
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
`;

const PriceSection = styled.div`
    width: 100%;
    display: flex;

`;
const Currency = styled.div`
    width: 80px;
    height: 40px;
    background-color: #eeeeee;
    border: 1px solid #bdbdbd;
    padding: 0px 10px;
    display: flex;
    align-items: center;
`;
const CurrencyText = styled.div`
    color: #000000;
    font-size: 16px;
    text-transform: uppercase;
`;
const PriceInput = styled.div`
    width: calc(100% - 80px);
`;

const useStyles = makeStyles({
    AddBgImgIcon: {
      color: "#000",
      marginRight: 4,
      fontSize: 18,
      '@media (max-width: 768px)': {
        fontSize: 14,
      }
    },
    ProductIcon: {
        color: "#fff",
        marginRight: 4,
        fontSize: 18
      },
  })
export default function AddCollection() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    //const user = useSelector((state) => state.user.currentUser);
    const responseLoader = useSelector(state=>state.response.loader);
    const businessProfiles = useSelector(state=>state.profile.profiles);
    const currencies = useSelector(state=>state.nonentity.currencies);
    const [selectedCurrency, setSelectedCurrency] = useState({
        id: "64939e6c9812c74866fcc2d8",
        name: "Nigerian Naira",
        code: "NGN",
        symbol: ""
    });
    const collectionCategories = useSelector(state=>state.nonentity.collectionCategories);

    const userRequest = useUserRequest();

    const bgImageRef = useRef(null);
    const coverImageRef = useRef(null);

    const [showPriceText, setShowPriceText] = useState("no");

    const [selectedImageUrl, setSelectedImageUrl] = useState({
        CoverImageFile: `${BASE_RSEOURCE_URL + "/uploads/user/collection/images/default_cover.jpg"}`,
        BackgroundImageFile: `${BASE_RSEOURCE_URL + "/uploads/user/collection/images/default_background.jpg"}`
    });
    
        /////// Progress Bar ///////////////
        const [sectionXvalue, setSectionXvalue] = useState(0);
        const [progressBar, setProgressBar] = useState({
            first: true,
            second: false
        });
    
        const handleSectionClick = section => () => {
            let bar = {
                first: true,
                second: false
            };

            let value = 0;

            let result = isNext(section);

            if(section == "A" && result == true){
                bar = {first: true, second: false}
                value = 0;
            }
            if(section == "B" && result == true){
                bar = {first: false, second: true}
                value = -(100/2);
            }

    
            setProgressBar(bar);
            setSectionXvalue(value);
            window.scrollTo(0, 0);
        };
        const isNext = (section) => {

            let error_msg = {...errors};
            let _focus = {...focus};
            let next = true;
    
            if (section == "B"){
                if (values.BusinessId == "" ) {
                    error_msg = {
                        ...error_msg, 
                        ["BusinessId"]: "You must select a profile"
                    };
                    _focus = {
                        ..._focus, 
                        ["BusinessId"]: true
                    };
                    next = false;
                }
                if (values.CollectionCategoryId == "") {
                    next = false;
                    error_msg = {
                        ...error_msg, 
                        ["CollectionCategoryId"]: "You must select a category"
                    };
                    _focus = {
                        ..._focus, 
                        ["CollectionCategoryId"]: true
                    };
                }
                if (values.Name == "") {
                    next = false;
                    error_msg = {
                        ...error_msg, 
                        ["Name"]: "Enter your collection name"
                    };
                    _focus = {
                        ..._focus, 
                        ["Name"]: true
                    };
                }
                
                if (values.ReleasedOn == "") {
                    next = false;
                    error_msg = {
                        ...error_msg, 
                        ["ReleasedOn"]: "Select the date of release"
                    };
                    _focus = {
                        ..._focus, 
                        ["ReleasedOn"]: true
                    };
                }

            }
    
            setErrors(error_msg);
            setFocus(_focus);
    
            return next;
    
        };
    
        ////////// End ////////////////
    const [values, setValues] = useState({
        Name: "",
        Description: "",
        CoverImageFile: "",
    });

    const [errors, setErrors] = useState({
        Name: "",
        Description: "",
        ImageFile: ""   
    });

    const [focus, setFocus] = useState({
        Name: "",
        Description: "",
        ImageFile: ""   
    });

    const onChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setValues({ ...values, [name]: value });
        console.log(values);
        validateInput(name, value);
    };

    const handleFocus = (e) => {
        setFocus({ ...focus, [e.target.name]: true });
        //console.log(focus);
    };

    const handleImageClick = (ref) => {
        ref.current.click();
    };

    const handleFileChange = event => {
        let error_msg = {...errors};
        let _focus = {...focus};

        const fileObj = event.target.files && event.target.files[0];

        if (!fileObj) {
          return;
        }
        if (fileObj.type == "image/jpeg" || fileObj.type == "image/jpg" || fileObj.type == "image/png") {
            if((fileObj.size/1024)/1024 < 2.1)  {
                console.log('fileObj is', fileObj);
                setSelectedImageUrl({...selectedImageUrl, [event.target.name]: URL.createObjectURL(event.target.files[0]) });
                setValues({ ...values, [event.target.name]: event.target.files[0] });
                event.target.value = null;
                error_msg = {
                    ...error_msg, 
                    ["ImageFile"]: ""
                }; 
            }else{
                error_msg = {
                    ...error_msg, 
                    ["ImageFile"]: "Image size should not be more than 2mb"
                };
            }
            
        }else {
            error_msg = {
                ...error_msg, 
                ["ImageFile"]: "Invalid image format"
            };
        }  
        _focus = {
            ..._focus, 
            ["ImageFile"]: true
        };
        setFocus(_focus);
        setErrors(error_msg);
    };

    const validateInput = (inputName, InputValue) => {
        let regex = "";

        let valid = true;

        let error_msg = {...errors};

        //// Category Ids /////
        if (inputName === "BusinessId" || inputName === "CollectionCategoryId") {
            if(InputValue == "") {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Select a category"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Name /////
        if (inputName === "Name") {
            //regex = /^(?=.{3,100}$)[^\W_]+(?: [^\W_]+)*$/;
            regex = /^[A-Za-z0-9 ]{3,100}$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "character length should be between 3 and 100. Only alphabets and numbers."
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Description /////
        if (inputName === "Description") {
            //regex = /^[A-Za-z0-9 -/,.]{3,10}$/;
            regex = /^.{10,500}$/s;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "character length should be between 10 and 500"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Release On /////
        if (inputName === "ReleasedOn" ) {
            if(InputValue == "") {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Select date of release"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Currency /////
        if (inputName === "CurrencyId" ) {
            if(InputValue == "") {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Select a currency"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }


        //console.log(errors);
        setErrors(error_msg);

    };

    const checkRequiredInputs = () => {
        let error_msg = {...errors};
        let _focus = {...focus};

        let result = true;

        if (values.CoverImageFile == "" ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["ImageFile"]: "You must choose a cover image for this collection"
            };
            _focus = {
                ..._focus, 
                ["ImageFile"]: true
            };
        }

        if (values.BusinessId == "" ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["BusinessId"]: "You must select a profile"
            };
            _focus = {
                ..._focus, 
                ["BusinessId"]: true
            };
        }
        if (values.CollectionCategoryId == "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["CollectionCategoryId"]: "You must select a category"
            };
            _focus = {
                ..._focus, 
                ["CollectionCategoryId"]: true
            };
        }
        if (values.Name == "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Name"]: "Enter your collection name"
            };
            _focus = {
                ..._focus, 
                ["Name"]: true
            };
        }

        if (values.ReleasedOn == "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["ReleasedOn"]: "Select the date of release"
            };
            _focus = {
                ..._focus, 
                ["ReleasedOn"]: true
            };
        }

         if (values.ShowPrice == true && values.CurrencyId == "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["CurrencyId"]: "Select a currency"
            };
            _focus = {
                ..._focus, 
                ["CurrencyId"]: true
            };
        }

        setErrors(error_msg);
        setFocus(_focus);
        return result;
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        let result = true//checkRequiredInputs();

        if(result) {
            dispatch(StartLoader());

            let formData = new FormData();
            formData.append('Name', values.Name);
            formData.append('Description', values.Description);
            formData.append('CoverImageFile', values.CoverImageFile);
    
            try {
                const res = await userRequest.post("/Collection", formData);
                dispatch(AddToCollections(res.data));
                dispatch(updateResponse(
                    {
                        active: true,
                        success: true,
                        message: "Success: Your collection was created successfully"
                    }
                ));
                dispatch(StopLoader());
                navigate("/user/collections");
            } catch (err) {
                console.log(err);
                dispatch(updateResponse(
                    {
                        active: true,
                        success: false,
                        message: "Error: The operation failed. Check your internet and try again"
                    }
                ));
                dispatch(StopLoader());
                navigate("/user/collections");
            }
        }else {
            window.scrollTo(0, 0);
            console.log("FORM IS NOT GOOD");
        }

    };

    
    useEffect(()=>{
        document.title = "";
        window.scrollTo(0, 0);
    
    },[]);

  return (
    <Container>
        <Wrapper>
            <Header>
                <PageTitle>new collection settings</PageTitle>
            </Header>
            <form  className="c_form">
                <ProgressBarWrap>
                    <ProgressBar>
                        <ProgressBarItem width={50}>
                            <ProgressBarText active={progressBar.first}>Category & description</ProgressBarText>
                        </ProgressBarItem>
                        <ProgressBarItem width={50}>
                            <ProgressBarText active={progressBar.second}>image & price</ProgressBarText>
                        </ProgressBarItem>
                    </ProgressBar>
                </ProgressBarWrap>
                <FormContainer xValue={sectionXvalue}>
                    <FormSectionA>

                        <FormItem>
                            <Left>
                                <label for="Name">Collection Name</label>
                                <LabelDesc>
                                    Give your collection a name
                                </LabelDesc>
                            </Left>
                            <Right>
                                <InputItem>
                                    <input
                                        className="main_input c_input"
                                        type="text" 
                                        name="Name"
                                        onChange={onChange}
                                        onBlur={handleFocus}
                                        placeholder=''
                                    required />              
                                </InputItem>
                                <ErrorMsg>{focus.Name && errors.Name != "" && errors.Name}</ErrorMsg>
                            </Right>
                        </FormItem>
                        <FormItem>
                            <Left>
                                <label for="desc_id">Description</label>
                                <LabelDesc>
                                    Describe your collection in less than 500 words
                                </LabelDesc>
                            </Left>
                            <Right>
                                <InputItem>
                                    <textarea
                                        className=""
                                        id="desc_id"
                                        rows="5"
                                        name="Description"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        placeholder=''
                                        />          
                                </InputItem>
                                <ErrorMsg>{focus.Description && errors.Description != "" && errors.Description}</ErrorMsg>
                            </Right>
                        </FormItem>
                        <FormItem>
                            <Left>
                                <label for="cover_image_id">Collection Images</label>
                                <LabelDesc>
                                    Upload cover photos for your colection
                                </LabelDesc>
                            </Left>
                            <Right>
                                <InputItem>
                                <ImageSection>
                                    <BgPhoto style={{ backgroundImage: `url(${selectedImageUrl.BackgroundImageFile})`}}>
                                        <AddPhotoBtn onClick={()=>handleImageClick(bgImageRef)}>
                                            <AddAPhoto className={classes.AddBgImgIcon} />
                                            <AddPhotoText>Background photo</AddPhotoText>
                                        </AddPhotoBtn>
                                    </BgPhoto>
                                    <CoverPhoto style={{ backgroundImage: `url(${selectedImageUrl.CoverImageFile})`}}>
                                        <AddPhotoBtn onClick={()=>handleImageClick(coverImageRef)}>
                                            <AddAPhoto className={classes.AddBgImgIcon} />
                                            <AddPhotoText>cover photo</AddPhotoText>
                                        </AddPhotoBtn>
                                    </CoverPhoto>
                                </ImageSection>
                                <input
                                    ref={bgImageRef}
                                    type="file"
                                    name="BackgroundImageFile"
                                    style={{display: 'none'}}
                                    onChange={handleFileChange}
                                    />
                                <input
                                    ref={coverImageRef}
                                    type="file"
                                    name="CoverImageFile"
                                    style={{display: 'none'}}
                                    onChange={handleFileChange}
                                    />     
                                </InputItem>
                                <ErrorMsg>{focus.ImageFile && errors.ImageFile != "" && errors.ImageFile}</ErrorMsg>
                            </Right>
                        </FormItem>
                        <CtaWrap>
                            <BtnWrap>
                                <Link to={`/user/collections`} style={{ textDecoration: 'none' }} >
                                    <CancelBtn>
                                        <CancelBtnText>cancel</CancelBtnText>
                                    </CancelBtn>
                                </Link>
                                <ContinueBtn onClick={handleSubmit}>
                                    <ContinueBtnText>create collection</ContinueBtnText>
                                </ContinueBtn>
                            </BtnWrap>
                        </CtaWrap>
                    </FormSectionA>
                </FormContainer>
            </form>
        </Wrapper>
        {responseLoader.active == true ?
            <ResponseLoader />
            :
            <></>
        }
    </Container>
  )
}
