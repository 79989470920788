import React from 'react';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { mobile, tablet } from '../responsive';
import { publicRequest } from "../requestMethods";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser, SetUserToken } from "../redux/userRedux";

import { makeStyles } from '@material-ui/core/styles';
import { 
  Create,
  AddBoxOutlined,
  DeleteOutlined,
  PhotoCamera,
  List,
  VisibilityOutlined,
  VisibilityOffOutlined,
  AddAPhoto
  } from "@material-ui/icons";
import { Link } from "react-router-dom";

import "../assets/css/mainStyle.css";
import BgImage from '../assets/images/main_bg.jpg';
import LogoMain from '../assets/images/Store-Manager-Logo.png';

import { SetProfiles } from '../redux/profileRedux';
import { SetProducts } from '../redux/productRedux';
import { SetCollections } from '../redux/collectionRedux';
import { SetCategories } from '../redux/categoryRedux';

import { SetIndustries } from '../redux/nonentityRedux';
import { SetBusinessCategories } from '../redux/nonentityRedux';
import { SetCollectionCategories } from '../redux/nonentityRedux';
import { SetProductCategories } from '../redux/nonentityRedux';
import { SetCurrencies } from '../redux/nonentityRedux';

import useUserRequest from '../hooks/useUserRequest';
import useLogout from '../hooks/useLogout';

import axios from "axios";

const Container = styled.div`
width: 100%;
height: 100vh;
${mobile({
  height: "auto",
  padding: "50px 15px"
})}  
`;
const Wrapper = styled.div`
width: 100%;
height: 100%;
display: flex;
${mobile({
  height: "auto",  
  marginBottom: "30px"
})}  
`;
const Left = styled.div`
width: 55%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
${mobile({
    width: "100%",
    height: "auto",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "30px"
})}  
`;
const LeftWrap = styled.div`
width: 80%;
max-width: 300px;
overflow: hidden;
//border: 1px solid #000;
${mobile({
    width: "100%"
})} 
`;
const SectionWrap = styled.div`
width: 200%;
display: flex;
transition: all 0.3s ease;
transform: translateX(${props=>props.xValue}%);
`;
const Section = styled.div`
width: 100%;
`;
const Logo = styled.img`
    width: 170px;
`;
const LogoDesc = styled.div`
color: #666666;
font-size: 15px;
font-weight: 300;
margin-bottom: 40px;
`;

const ServerResponse = styled.div`
    color: #000;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 20px;
    background-color: #fad7d7;
    margin-bottom: 10px;
    display: ${props=>props.error === false ? "none" : "block"};
`;

const FormWrap = styled.div`
width: 100%;
//margin-top: 5px;
${mobile({
    //marginTop: "50px"
})} 
`;
const FormGroup = styled.div`
width: 100%;
margin-bottom: 10px;
display: flex;
flex-direction: column;
//border: 1px solid #000;
`;
const FormInput = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;
const PasswordWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
const VisibilityIconWrap = styled.div`
    width: 40px;
    height: 40px;
    background-color: #eeeeee;
    border-left: none;
    border-right: 1px solid #bdbdbd;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const Error = styled.div`
    color: red;
    font-size: 14px;
    font-weight: 300;
`;
const ForgotPass = styled.div`
    margin-bottom: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease;
    &:hover {
        color: #0000FD;
    }
`;
const Signup = styled.div`
margin-top: 20px;
color: #646464;
font-size: 14px;
font-weight: 400;
`;

const SubmitBtnText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;

const SubmitBtn = styled.div`
    width: 100%;
    height: 40px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #ec7fbb;
        ${SubmitBtnText} {
            color: #000;
        }
    }
    ${mobile({
        height: "40px"
    })}  
`;


////// Section B
const Loader = styled.div`
width: 100%;
height: 100%;
//background-color: red;
display: flex;
align-items: center;
justify-content: center;
`;





const Right = styled.div`
width: 45%;
height: 100%;
background-color: #e4e4e4;
height: 100%;
display: flex;
align-items: flex-end;
justify-content: center;
background-size: cover;
background-position: center;
${mobile({
  display: "none"
})}  
`;

const RightWrap = styled.div`
width: 100%;
margin: 0px 20px 40px 20px;
padding: 20px;
background: rgba(255, 255, 255, 0.2);
backdrop-filter: blur(10px);
`;
const Top = styled.div`
width: 100%;
color: #ffffff;
font-size: 18px;
font-weight: 600;
margin-bottom: 30px;
`;

const Bottom = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
`;
const BottomLeft = styled.div`
width: calc(50% - 10px);
`;
const RefName = styled.div`
color: #fff;
font-size: 18px;
font-weight: 600;
text-transform: capitalize;
//margin-bottom: 5px;
`;
const RefJob = styled.div`
color: #fff;
font-size: 14px;
font-weight: 500;
text-transform: capitalize;
//margin-bottom: 5px;
`;
const RefDesc = styled.div`
color: #fff;
font-size: 14px;
font-weight: 300;
text-transform: capitalize;
`;
const BottomRight = styled.div`
width: calc(50% - 10px);
display: flex;
align-items: flex-end;
justify-content: flex-end;
`;
const Stars = styled.div`
width: 70px;
`;
const Star = styled.img`
width: 100%;
`;




const useStyles = makeStyles({
    ActionIcons: {
        color: "#00000060",
        fontSize: 18,
        cursor: "pointer",
        '@media (max-width: 768px)': {
        fontSize: 22,
        }
    },
    AddIcon: {
        color: "#000",
        fontSize: 35
    },
    SortIcon: {
        color: "#fff",
        fontSize: 15
    },
    ShowIcon: {
        color: "#9e9e9e",
        fontSize: 16
    },
})

export default function Login() {
    const classes = useStyles();

    const navigate = useNavigate();
    //const [loginResponse, setLoginResponse] = useState("");
    const dispatch = useDispatch();

    const userRequest = useUserRequest();
    const logout = useLogout();

    const  user = useSelector((state) => state.user);

    const [loadPercent, setLoadPercent] = useState(0);

    //// Password Visibility///
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };

    const [serverResponse, setServerResponse,] = useState({
        error: false,
        message: ""
    });

    const [sectionXvalue, setSectionXvalue] = useState(0);

    // const handleSubmit = async () => {
    //     setSectionXvalue(-(100/2));
    // }

    const [values, setValues] = useState({
        Email: "",
        Password: ""
    });

    const [errors, setErrors] = useState({
        Email: "",
        Password: ""
    });

    const [focus, setFocus] = useState({
        Email: false,
        Password: false
    });

    const onChange = (e) => {
        setServerResponse({
            error: false,
            message: ""
        })
        setValues({ ...values, [e.target.name]: e.target.value });
        validateInput(e.target.name, e.target.value);
        console.log(values);
    };

    const handleFocus = (e) => {
        setFocus({ ...focus, [e.target.name]: true });
    };
    
    const validateInput = (inputName, InputValue) => {
        let regex = "";

        let valid = true;

        let error_msg = {...errors};

        //// Email /////
        if (inputName === "Email") {
            regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Enter a valid email address"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        if (inputName == "Password") {
            regex = /^[^\s]{8,}$/;
            valid = regex.test(InputValue);

            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Password must have no whitespace and atleast 8 characters long"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        console.log(errors);
        setErrors(error_msg);

    };

    const checkRequiredInputs = () => {
        let error_msg = {...errors};
        let _focus = {...focus};

        let result = true;

        if (values.Email == "" || errors.Email != "" ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Email"]: "Enter a valid email"
            };
            _focus = {
                ..._focus, 
                ["Email"]: true
            };
        }
        if (values.Password == "" || errors.Password != "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Password"]: "Password must have no whitespace and atleast 8 characters long"
            };
            _focus = {
                ..._focus, 
                ["Password"]: true
            };
        }
        setErrors(error_msg);
        setFocus(_focus);
        return result;
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        let result = checkRequiredInputs();

        if(result) {
            setSectionXvalue(-(100/2));
            getCategories();
            getProducts();
            getCollections();


            // try {

            //     const res = await publicRequest.post(
            //         "/Authentication/login", 
            //         values,
            //         {
            //             withCredentials: true
            //         }
            //     );
            //     console.log("Data" , res.data.user.token);

            //     getCategories();
            //     getProducts();
            //     getCollections();

            //     dispatch(LoginUser({
            //         user: {
            //             userId: res.data.user.userId,
            //             userName: res.data.user.userName
            //         },
            //         token: res.data.user.token
            //     }));

            // } catch (err) {
            //     let _response = {};
            //     if(err.response) {
            //         _response = err.response.data.errors;
            //     }else {
            //         _response = "No connection"
            //     }
            //     setServerResponse({
            //         error: true,
            //         message: _response 
            //     })
            //     setSectionXvalue(0);
            // }
        }
    };

    useEffect(()=>{
        let isMounted = true
        if(loadPercent == 100){
            navigate("/user/orders");
        }
        return () => isMounted = false;
   },[loadPercent]);


    const getCollections = async (userId, token) => {
        try {
        const res = await userRequest.get("/Collection");
            dispatch(SetCollections(res.data));
            setLoadPercent((loadPercent) => loadPercent + 40);
        } catch (err) {
            const res = await logout();
            setServerResponse({
                error: true,
                message: "Authorization failed. Try again"
            })
            setSectionXvalue(0);
        }
    };

    const getCategories = async (userId, token) => {
        try {
        const res = await userRequest.get("/ProductCategory");
            dispatch(SetCategories(res.data));
            setLoadPercent((loadPercent) => loadPercent + 30);
        } catch (err) {
            const res = await logout();
            setServerResponse({
                error: true,
                message: "Authorization failed. Try again"
            })
            setSectionXvalue(0);
        }
    };

    // const getUserProducts = async (userId, token) => {
    //     try {
    //        const res = await userRequest.get(
    //          "/product/" + userId, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         });
    //         dispatch(SetProducts(res.data));
    //         setLoadPercent((loadPercent) => loadPercent + 12.5);
    //     } catch (err) {
    //         const res = await logout();
    //         setServerResponse({
    //             error: true,
    //             message: "Authorization failed. Try again"
    //         })
    //         setSectionXvalue(0);
    //     }
    // };

        const getProducts = async () => {
        try {
           const res = await axios.get("https://lisafolawiyo01-001-site1.btempurl.com/api/product");
            dispatch(SetProducts(res.data));
            setLoadPercent((loadPercent) => loadPercent + 30);
        } catch (err) {
            const res = await logout();
            setServerResponse({
                error: true,
                message: "Authorization failed. Try again"
            })
            setSectionXvalue(0);
        }
    };


  return (
    <Container>
        <Wrapper>
            <Left>
                <LeftWrap>
                    <SectionWrap xValue={sectionXvalue}>
                        <Section>
                            <Logo src={LogoMain} />
                            <LogoDesc></LogoDesc>
                            <ServerResponse error={serverResponse.error}>
                                {serverResponse.message}
                            </ServerResponse>
                            <FormWrap>
                                <FormGroup>
                                    <FormInput>
                                        <label className="register_labels">email</label>
                                        <input
                                            className="login_inputs"
                                            type="text"
                                            name="Email"
                                            placeholder=''
                                            onChange={onChange}
                                            onBlur={handleFocus}
                                            required />
                                            <Error>{focus.Email && errors.Email != "" && errors.Email}</Error>
                                    </FormInput>
                                    <FormInput>
                                        <label className="register_labels">password</label>
                                        <PasswordWrap>
                                            <input
                                                className="login_password_input"
                                                type={passwordShown ? "text" : "password"}
                                                name="Password"
                                                onChange={onChange}
                                                onBlur={handleFocus}
                                                placeholder=''
                                                required />
                                            <VisibilityIconWrap onClick={togglePassword}>
                                                {
                                                    passwordShown == true ?
                                                        <VisibilityOutlined className={classes.ShowIcon} />
                                                        :
                                                        <VisibilityOffOutlined className={classes.ShowIcon} />
                                                }
                                            </VisibilityIconWrap>
                                        </PasswordWrap>
                                        <Error>{focus.Password && errors.Password != "" && errors.Password}</Error>
                                    </FormInput>
                                </FormGroup>
                                <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <ForgotPass>Forgot password?</ForgotPass>
                                </Link>
                                <SubmitBtn onClick={handleSubmit}>
                                    <SubmitBtnText>login</SubmitBtnText>
                                </SubmitBtn>
                                {/* <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <Signup>Don't have an account? <span className="no_acct">Sign up for free</span></Signup>
                                </Link> */}
                            </FormWrap>
                        </Section>
                        <Section>
                            <Loader>
                                <span className="loader"></span>
                            </Loader>
                        </Section>
                    </SectionWrap>
                </LeftWrap>
            </Left>
            <Right style={{ backgroundImage: `url(${BgImage})`}} >
                {/* <div className='right_wrap'>
                    <Top>
                        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                    </Top>
                    <Bottom>
                        <BottomLeft>
                            <RefName>Andi lane</RefName>
                            <RefJob>Founder, catalog</RefJob>
                            <RefDesc>web design agency</RefDesc>
                        </BottomLeft>
                        <BottomRight>
                            <Stars>
                                <Star src={StarsImg}/>
                            </Stars>
                        </BottomRight>
                    </Bottom>
                </div> */}
            </Right>
        </Wrapper>
    </Container>
  )
}
