import TopMenu from '../components/navigation/TopMenu';
import Sidebar from '../components/navigation/Sidebar';
import { Outlet } from "react-router-dom";
import Footer from '../components/navigation/Footer';

export default function MainApp() {


    return (
        <div>
            <TopMenu />
            <Sidebar />
            <Outlet/>
            <Footer />
        </div>
    );
}
