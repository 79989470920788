import React, {useEffect, useState} from "react";
import styled, { keyframes }  from 'styled-components';
import { mobile, mobileXL, tablet } from '../../responsive';
import { 
    Check,
    Close,
    ErrorOutline
    } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { clearResponse } from '../../redux/responseRedux';
import { useDispatch, useSelector } from 'react-redux';

const fadeOut = keyframes`
    10% {
        visibility: visible;
        opacity: 1;
    }
    90% {
        visibility: visible;
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
`;

const Container = styled.div`
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    //display: ${props=>props.active === true ? "block" : "none"};
    animation-name: ${fadeOut};
    animation-iteration-count: 1;
    animation-duration: 6s;
`;

const Wrapper = styled.div`
    width: 100%;
    background-color: ${props=>props.success === true ? "#e1ffde" : "#ffdede"};
    border: ${props=>props.success === true ? "1px solid #9fc99c" : "1px solid #c4a1a1"};;
    display: flex;
    position: relative;
    padding: 15px 0px 20px 0px;
`;
const ResponseIcon = styled.div`
    width: 30px;
    height: 30px;
    //background-color: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
`;
const Message = styled.div`
    margin: 0px 50px 0px 60px;
    color: ${props=>props.success === true ? "#3c613a" : "#613a3a"};
`;
const CloseIcon = styled.div`
    width: 30px;
    height: 30px;
    //background-color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
`;

const useStyles = makeStyles({
    // ActionIcons: {
    //     color: "#00000060",
    //     fontSize: 18,
    //     cursor: "pointer",
    //     '@media (max-width: 768px)': {
    //       fontSize: 22,
    //     }
    // },
    SuccessIcon: {
      color: "#3c613a",
      fontSize: 30
    },
    ErrorIcon: {
        color: "#613a3a",
        fontSize: 30
      },
    CloseSuccessIcon: {
      color: "#3c613a",
      fontSize: 22
    },
    CloseErrorIcon: {
        color: "#613a3a",
        fontSize: 22
      },
  })
const Response = () => {
    const classes = useStyles();

    const response = useSelector(state=>state.response.response);
    const dispatch = useDispatch();
    const [isResponseOpen, setIsResponseOpen] = useState(false);


    useEffect(() => {
        if(Object.keys(response).length != 0 && response.active === true) {
            setIsResponseOpen(true);
            window.scrollTo(0, 0);
        }
     }, [response]);

     useEffect(() => {
        if(isResponseOpen == true) {
            setTimeout(() => {
                dispatch(clearResponse());
            }, 6300);
            setIsResponseOpen(false);
        }
     }, [isResponseOpen]);

     const CloseResponseModal = () => {
        dispatch(clearResponse());
        setIsResponseOpen(false);
    };

    if (response.active === true && response.success === true) {
        return (
            <Container active={isResponseOpen}>
                <Wrapper success={response.success}>
                    <ResponseIcon>
                        <Check className={classes.SuccessIcon}/>
                    </ResponseIcon>
                    <Message success={response.success}>{response.message}</Message>
                    <CloseIcon onClick={CloseResponseModal}>
                        <Close className={classes.CloseSuccessIcon}/>
                    </CloseIcon>
                </Wrapper>
            </Container>
        );
    }
    if (response.active === true && response.success === false) {
        return (
            <Container 
                active={isResponseOpen}>
                <Wrapper success={response.success}>
                    <ResponseIcon>
                        <ErrorOutline className={classes.ErrorIcon}/>
                    </ResponseIcon>
                    <Message success={response.success}>{response.message}</Message>
                    <CloseIcon onClick={CloseResponseModal}>
                        <Close className={classes.CloseErrorIcon}/>
                    </CloseIcon>
                </Wrapper>
            </Container>
        );
    }

}

export default Response;