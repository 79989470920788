import {publicRequest} from "../requestMethods";
import { useDispatch, useSelector } from "react-redux";
import { SetUserToken } from "../redux/userRedux";

const useRefreshToken = () => {
    const dispatch = useDispatch();
    const  user = useSelector((state) => state.user);

    const refresh = async () => {
        const response = await publicRequest.get('/Authentication/RefreshToken/' + user.accessToken, {
            withCredentials: true
        });
        dispatch(SetUserToken(response.data));
        return response.data;
    }
    return refresh;
};

export default useRefreshToken;