import React, { useState } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Create,
  AddBoxOutlined,
  DeleteOutlined,
  ChevronRight,
  ArrowBack,
  EmailOutlined,
  MoreVertOutlined
  } from "@material-ui/icons";
import { Link } from "react-router-dom";



const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    margin-left: 200px;
    padding: 40px;
    background-color: #e2e2e2;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "10px 15px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
`;
const AddBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5e68f5;
    padding: 10px 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #9299fc;
    }
`;
const AddBtnText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-top: 2px;
    text-transform: capitalize;
`;

const Body = styled.div`
    width: 100%;
    margin-bottom: 30px;
`;
const BodyHead = styled.div`
    width: 100%;
    border-top: 1px solid #00000010;
    padding: 10px 0px;
`;
const ActionItems = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;
const Item = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #00000010;
    }
`;
const BodyMain = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 30px 30px;
`;
const BmFrom = styled.div`

`;
const BmName = styled.div`
    color: #00000060;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
`;
const BmTitle = styled.div`
    color: #00000090;
    font-size: 16px;
    font-weight: 700;
    margin-top: 2px;
    text-transform: capitalize;
`;
const BmSubject = styled.div`
    color: #00000090;
    font-size: 20px;
    font-weight: 700;
    margin-top: 2px;
    text-transform: capitalize;
    margin-top: 20px;
`;
const BmMessage = styled.div`
    color: #00000070;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
`;





const useStyles = makeStyles({
  ActionIcons: {
      color: "#424242",
      fontSize: 20
  }
})

export default function Message() {
  const classes = useStyles();

  return (
    <Container>
        <Wrapper>
          <Header>
            <PageTitle>Inbox</PageTitle>
            {/* <Link to={`/user/collection/add`} style={{ textDecoration: 'none' }}>
              <AddBtn>
                <AddBoxOutlined className={classes.AddIcon}/>
                <AddBtnText>Add collection</AddBtnText>
              </AddBtn>
            </Link> */}
          </Header>
          <Body>
            <BodyHead>
                <ActionItems>
                    <Link to={`/user/messages`} style={{ textDecoration: 'none' }}>
                        <Item>
                            <ArrowBack className={classes.ActionIcons} />
                        </Item>
                    </Link>
                    <Item>
                        <DeleteOutlined className={classes.ActionIcons} />
                    </Item>
                    <Item>
                        <EmailOutlined className={classes.ActionIcons} />
                    </Item>
                    <Item>
                        <MoreVertOutlined className={classes.ActionIcons} />
                    </Item>
                </ActionItems>
            </BodyHead>
            <BodyMain>
                <BmFrom>
                    <BmName>Obasi John</BmName>
                    <BmTitle>myCatalog customer care</BmTitle>
                </BmFrom>
                <BmSubject>
                    You have to get the premium plan
                </BmSubject>
                <BmMessage>
                    <p>Hello Odega,</p>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.</p>
                    <p>No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.</p>
                    <p>To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure.</p>
                </BmMessage>

            </BodyMain>
          </Body>
        </Wrapper>
    </Container>
  )
}
