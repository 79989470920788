import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import { useSelector } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import { 
  Create,
  AddBoxOutlined,
  DeleteOutlined,
  ChevronRight,
  Add
  } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import {publicRequest , BASE_URL, BASE_RSEOURCE_URL} from "../../requestMethods";

import { useNavigate, useLocation } from "react-router-dom";

import axios from 'axios';

const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    min-height: 100vh;
    margin-left: 200px;
    padding: 40px;
    background-color: #e2e2e2;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "10px 0px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
    position: relative;
    ${mobile({
        marginTop: "40px"
    })} 
`;
const Header = styled.div`
    width: 100%;
    border-bottom: 1px solid #00000030;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none",
      padding: "10px 15px 20px 15px"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    ${mobile({
        fontSize: "23px"
    })}
`;
const Body = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
const Left = styled.div`
    width: calc(60% - 20px);
`;
const Right = styled.div`
    width: 40%;
`;
const Item = styled.div`
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 20px;
`;
const ItemHead = styled.div`
    width: 100%;
    padding: 20px 20px;
    border-bottom: 1px solid #00000030;
`;
const ItemHeadText = styled.div`
    color: #000;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
`;
const ItemBody = styled.div`
    width: 100%;
    padding: 20px 20px;
`;
const Info = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;
const InfoField = styled.div`
    width: 40%;
`;
const FieldText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
`;
const InfoValue = styled.div`
    width: 60%;
`;
const ValueText = styled.div`
    color: #9c9c9c;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
`;
const ProductsHead = styled.div`
    width: 100%;
    display: flex;
`;
const ProductsName = styled.div`
    width: calc(100% - 310px);
`;
const ProductsColor = styled.div`
    width: 80px;
`;
const ProductsSize = styled.div`
    width: 80px;
`;
const ProductsQty = styled.div`
    width: 30px;

`;
const ProductsPrice = styled.div`
    width: 120px;
`;
const ProductsHeadText = styled.div`
    color: #0c0c0c;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
`;
const ProductsItemText = styled.div`
    color: #9c9c9c;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
`;
const ProductsWrap = styled.div`
    width: 100%;
    margin-top: 20px;
`;
const ProductItem = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
`;



const useStyles = makeStyles({
    ActionIcons: {
        color: "#00000060",
        fontSize: 18,
        cursor: "pointer",
        '@media (max-width: 768px)': {
          fontSize: 22,
        }
    },
    AddIcon: {
      color: "#000",
      fontSize: 25
    },
    SortIcon: {
      color: "#B8F904",
      fontSize: 15
    },
  })

export default function Order() {
    const location = useLocation();
    const orderId = location.pathname.split("/")[3];
    const [order, setOrder] = useState();

    const getOrder = async () => {
        try {
        const res = await publicRequest.get("/order/" + orderId);
          console.log(res.data);
          setOrder(res.data);

        } catch (err) {

        }
    };
    useEffect(()=>{
        getOrder();
    },[]);

  return (
    <Container>
        <Wrapper>
            {/* <Header>
                <PageTitle>Order Details</PageTitle>
            </Header> */}
            { order &&
            <Body>
                <Left>
                    <Item>
                        <ItemHead>
                            <ItemHeadText>order summary</ItemHeadText>
                        </ItemHead>
                        <ItemBody>
                            <Info>
                                <InfoField>
                                    <FieldText>Reference:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.reference}</ValueText>
                                </InfoValue>
                            </Info>
                            <Info>
                                <InfoField>
                                    <FieldText>Invoice Number:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.invoice}</ValueText>
                                </InfoValue>
                            </Info>
                            <Info>
                                <InfoField>
                                    <FieldText>Cart Total:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.cartTotal.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {order.currencyPaid}</ValueText>
                                </InfoValue>
                            </Info>
                            <Info>
                                <InfoField>
                                    <FieldText>Shipping:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.shippingRate.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {order.currencyPaid}</ValueText>
                                </InfoValue>
                            </Info>
                            <Info>
                                <InfoField>
                                    <FieldText>Total:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.amountCharged.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {order.currencyPaid}</ValueText>
                                </InfoValue>
                            </Info>
                            <Info>
                                <InfoField>
                                    <FieldText>Trans Status:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.paymentStatus}</ValueText>
                                </InfoValue>
                            </Info>
                        </ItemBody>
                    </Item>
                    <Item>
                        <ItemHead>
                            <ItemHeadText>order items</ItemHeadText>
                        </ItemHead>
                        <ItemBody>
                            <ProductsHead>
                                <ProductsName>
                                    <ProductsHeadText>Name</ProductsHeadText>
                                </ProductsName>
                                <ProductsColor>
                                    <ProductsHeadText>color</ProductsHeadText>
                                </ProductsColor>
                                <ProductsSize>
                                    <ProductsHeadText>size</ProductsHeadText>
                                </ProductsSize>
                                <ProductsQty>
                                    <ProductsHeadText>Qty</ProductsHeadText>
                                </ProductsQty>
                                <ProductsPrice>
                                    <ProductsHeadText>Price</ProductsHeadText>
                                </ProductsPrice>
                            </ProductsHead>
                            <ProductsWrap>
                                { order.products.map(product => (
                                    <ProductItem>
                                        <ProductsName>
                                            <ProductsItemText>{product.name}</ProductsItemText>
                                        </ProductsName>
                                        <ProductsColor>
                                            <ProductsItemText>{product.color}</ProductsItemText>
                                        </ProductsColor>
                                        <ProductsSize>
                                            <ProductsItemText>{product.size}</ProductsItemText>
                                        </ProductsSize>
                                        <ProductsQty>
                                            <ProductsItemText>{product.quantity}</ProductsItemText>
                                        </ProductsQty>
                                        <ProductsPrice>
                                            <ProductsItemText>{product['price' + order.currencyPaid].toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {order.currencyPaid}</ProductsItemText>
                                        </ProductsPrice>
                                    </ProductItem>
                                ))}

                            </ProductsWrap>
                        </ItemBody>
                    </Item>

                </Left>
                <Right>
                <Item>
                        <ItemHead>
                            <ItemHeadText>Shipping</ItemHeadText>
                        </ItemHead>
                        <ItemBody>
                            <Info>
                                <InfoField>
                                    <FieldText>Customer name:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.customer.name}</ValueText>
                                </InfoValue>
                            </Info>
                            <Info>
                                <InfoField>
                                    <FieldText>Phone:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.customer.phone}</ValueText>
                                </InfoValue>
                            </Info>
                            <Info>
                                <InfoField>
                                    <FieldText>Email:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.customer.email}</ValueText>
                                </InfoValue>
                            </Info>
                            <Info>
                                <InfoField>
                                    <FieldText>Address:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.customer.address} {order.customer.city} {order.customer.country} {order.customer.postcode}</ValueText>
                                </InfoValue>
                            </Info>
                        </ItemBody>
                    </Item>
                    <Item>
                        <ItemHead>
                            <ItemHeadText>Others</ItemHeadText>
                        </ItemHead>
                        <ItemBody>
                            <Info>
                                <InfoField>
                                    <FieldText>Gift Order:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.giftReceipt == true ? "Yes" : "No"}</ValueText>
                                </InfoValue>
                            </Info>
                            <Info>
                                <InfoField>
                                    <FieldText>Comment:</FieldText>
                                </InfoField>
                                <InfoValue>
                                    <ValueText>{order.customerComment}</ValueText>
                                </InfoValue>
                            </Info>
                        </ItemBody>
                    </Item>

                </Right>

            </Body>
            }
        </Wrapper>
    </Container>
  )
}
