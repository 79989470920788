import {createSlice} from "@reduxjs/toolkit";


const productSlice = createSlice({
    name: "product",
    initialState : {
        products : []
    },
    reducers: {

        SetProducts: (state, action) => {
            state.products = action.payload;
        },
        AddProduct: (state, action) => {
            state.products.push(action.payload);
        },
        UpdateProduct: (state, action) => {
            let new_updated_data = 
                state.products.map((product) => {
                    if (product.id === action.payload.id) {
                        return {
                            ...product,
                            ...action.payload,
                        };
                    }
                    return product;
                });
            
            state.products = new_updated_data;
        },
        RemoveProduct: (state, action) => {
            state.products.splice(
                state.products.findIndex((x) =>  x.id === action.payload),
                1
              );
        },
        ClearProducts: (state) => {
            state.products = [];
        },
    },
});

export const { SetProducts, AddProduct, UpdateProduct, RemoveProduct, ClearProducts } = productSlice.actions;
export default productSlice.reducer;