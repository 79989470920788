import React from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Create,
  AddBoxOutlined,
  DeleteOutlined,
  PhotoCamera,
  List,
  AddAPhoto
  } from "@material-ui/icons";
import { Link } from "react-router-dom";
import "../../assets/css/form.css";

const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    margin-left: 200px;
    padding: 40px;
    background-color: #e2e2e2;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "10px 15px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
`;
const Header = styled.div`
    width: 100%;
    border-bottom: 1px solid #00000030;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
`;
const AddBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5e68f5;
    padding: 10px 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #9299fc;
    }
`;
const AddBtnText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-top: 2px;
    text-transform: capitalize;
`;
const Main = styled.div`
    width: 100%;
    padding: 50px 20px 50px 20px;
    /* border: 1px solid #000; */
`;
const Top = styled.div`
    width: 100%;
`;
const Detail = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    ${mobile({
        flexDirection: "column",
        justifyContent: 'start',
        marginBottom: "0px"
    })}  
`;
const DetailGroup = styled.div`
    width: calc(50% - 5px);
    /* border: 1px solid red; */
    ${mobile({
        width: "100%",
        marginBottom: "30px"
    })}  
`;
const DetailItem = styled.div`
    width: 100%;
`;
const Field = styled.div`
    color: #00000060;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 2px;
    text-transform: capitalize;
`;
const Value = styled.div`
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
`;
const EmailValue = styled.div`
    color: #000000;
    font-size: 16px;
    font-weight: 400;
`;
const Bottom = styled.div`
    width: 100%;
    margin-top: 100px;
    ${mobile({
        marginTop: "50px"
    })} 
`;
const BottomHead = styled.div`

`;
const BottomHeadText = styled.div`
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
`;
const BottomBody = styled.div`
    margin-top: 30px;
    width: 100%;
`;
const BottomDetail = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    ${mobile({
        flexDirection: "column",
        justifyContent: 'start',
        marginBottom: "0px"
    })} 
`;
const FormInput = styled.div`
    width: 100%;
    /* height: ${props=> props.f_height}px; */
    display: flex;
    flex-direction: column;
`;
const Submit = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    ${mobile({
        justifyContent: "center",
    })}  
`;
const SubmitBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    padding: 15px 20px;
    background-color: #1b87e6;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #3da3fc;
    }
    ${mobile({
        width: "100%",
        padding: "25px 10px"
    })}  
`;
const SubmitBtnText = styled.div`
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
`;

const useStyles = makeStyles({
    ActionIcons: {
        color: "#000",
        marginRight: 3,
        fontSize: 16
    },
    AddIcon: {
      color: "#fff",
      marginRight: 4,
      fontSize: 16
    },
  })
export default function Account() {
    const classes = useStyles();

  return (
    <Container>
        <Wrapper>
            <Header>
                <PageTitle>Account details</PageTitle>
                <Link to={`/user/profile/add`} style={{ textDecoration: 'none' }}>
                <AddBtn>
                    <AddBoxOutlined className={classes.AddIcon}/>
                    <AddBtnText>Add profile</AddBtnText>
                </AddBtn>
                </Link>
            </Header>
            <Main>
                <Top>
                    <Detail>
                        <DetailGroup>
                            <DetailItem>
                                <Field>Username</Field>
                                <Value>tonero</Value>
                            </DetailItem>
                        </DetailGroup>
                        <DetailGroup>
                            <DetailItem>
                                <Field>email</Field>
                                <EmailValue>tonero@yahoo.com</EmailValue>
                            </DetailItem>
                        </DetailGroup>
                    </Detail>
                    <Detail>
                        <DetailGroup>
                            <DetailItem>
                                <Field>current plan</Field>
                                <Value>free</Value>
                            </DetailItem>
                        </DetailGroup>
                        <DetailGroup>
                            <DetailItem>
                                <Field>registerd</Field>
                                <Value>4 oct 2022</Value>
                            </DetailItem>
                        </DetailGroup>
                    </Detail>
                </Top>
                <Bottom>
                    <BottomHead>
                        <BottomHeadText>Change password</BottomHeadText>
                    </BottomHead>
                    <BottomBody>
                        <BottomDetail>
                            <DetailGroup>
                                <DetailItem>
                                    <FormInput f_height={60}>
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="firstname"
                                            placeholder='Current Password'
                                            required />
                                        <span className="form_error_msg">Enter 3 or more characters</span>
                                    </FormInput>
                                </DetailItem>
                            </DetailGroup>
                        </BottomDetail>
                        <BottomDetail>
                            <DetailGroup>
                                <DetailItem>
                                    <FormInput f_height={60}>
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="firstname"
                                            placeholder='New Password'
                                            required />
                                        <span className="form_error_msg">Enter 3 or more characters</span>
                                    </FormInput>
                                </DetailItem>
                            </DetailGroup>
                            <DetailGroup>
                                <DetailItem>
                                    <FormInput f_height={60}>
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="firstname"
                                            placeholder='Repeat Password'
                                            required />
                                        <span className="form_error_msg">Enter 3 or more characters</span>
                                    </FormInput>
                                </DetailItem>
                            </DetailGroup>
                        </BottomDetail>

                    </BottomBody>
                    <Submit>
                    <SubmitBtn>
                        <SubmitBtnText>change password</SubmitBtnText>
                    </SubmitBtn>
                </Submit>
                </Bottom>
            </Main>
        </Wrapper>
    </Container>
  )
}
