import React from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import {useEffect, useState, useRef} from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import {publicRequest , BASE_URL, BASE_RSEOURCE_URL} from "../../requestMethods";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { AddProfile as AddToProfiles } from '../../redux/profileRedux';
import { updateResponse, StartLoader, StopLoader } from '../../redux/responseRedux';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Create,
  AddBoxOutlined,
  DeleteOutlined,
  PhotoCamera,
  List,
  Search,
  ChevronRight,
  AddAPhoto
  } from "@material-ui/icons";
import "../../assets/css/form.css";
import {countries} from '../../data';

import { Icon } from '@iconify/react';
import ResponseLoader from '../../components/generic/ResponseLoader';

import useUserRequest from '../../hooks/useUserRequest';
import useLogout from '../../hooks/useLogout';

const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    margin-left: 200px;
    padding: 60px 60px;
    background-color: #e2e2e2;
    ${tablet({
        padding: "20px 15px"
    })}  
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "20px 0px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;
    ${mobile({
        marginTop: "40px"
    })}  
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none",
      padding: "10px 15px 20px 15px"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    ${mobile({
        fontSize: "23px"
    })}
`;
const ProgressBarWrap = styled.div`
    width: 100%;
    background-color: #d8d8d8;
    border-bottom: 1px solid #61616140;
    padding: 0px 20px;
    ${tablet({
        padding: "0px 15px",
        height: "63px"
    })} 
`;
const ProgressBar = styled.div`
    width: 100%;
    display: flex;
    ${tablet({
      display: "block",
      position: "relative"
    })}
`;

const ProgressBarItem = styled.div`
    width: ${props=>props.width}%;
    //border: 1px solid #000;
    @media (max-width: 1024px) {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        //transform: ${props=>props.dragging === true ? "translateX(0%)" : "translateX(-101%)"};
    }
`;
const ProgressBarText = styled.div`
    width: max-content;
    transition: all 0.3s ease;
    color: ${props=>props.active === true ? "#0000FD" : "#7e7e7e"};
    font-size: 14px;
    font-weight: ${props=>props.active === true ? "600" : "300"};
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 20px 0px;
    border-bottom: ${props=>props.active === true ? "2px solid #0000FD" : "none"};
    @media (max-width: 1024px) {
        opacity:  ${props=>props.active === true ? 1 : 0};
    }
`;

const FormContainer = styled.div`
    width: 300%;
    margin-top: 30px;
    display: flex;
    transition: all 0.3s ease;
    transform: translateX(${props=>props.xValue}%);
    ${tablet({
        //marginTop: "100px"
    })}
`;
const FormSectionA = styled.div`
    width: 100%;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;
const FormSectionB = styled.div`
    width: 100%;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;
const FormSectionC = styled.div`
    width: 100%;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;
const FormItem = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    ${tablet({
        flexDirection: "column"
    })}  
`;
const Left = styled.div`
    width: 35%;
    margin-right: 5%;
    ${tablet({
        width: "100%",
        marginRight: "0%",
        marginBottom: "10px"
    })}  
`;
const LabelDesc = styled.div`
    width: 80%;
    margin-top: 5px;
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 300;
`;


const Right = styled.div`
    width: 60%;
    ${tablet({
        width: "100%"
    })}  
`;
const InputItem = styled.div`

`;
const LinkSamleText = styled.div`
    color: #a8a8a8;
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
`;
const ErrorMsg = styled.div`
    color: #f53b3b;
    font-size: 16px;
    font-weight: 300;
    margin-top: 5px;
`;
const CtaWrap = styled.div`
    width: 100%;
    margin-top: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${tablet({
        width: "calc(100% - 20px)"
    })}  

`;
const BtnWrap = styled.div`
    display: flex;
    width: max-content;
    ${mobile({
        width: "100%",
        justifyContent: "space-between"
    })} 
`;
const CancelBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    margin-right: 20px;
    cursor: pointer;
`;
const CancelBtnText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
`;
const ContinueBtnText = styled.div`
    color: #B8F904;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;
const ContinueBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    background-color: #000;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #B8F904;
      ${ContinueBtnText} {
        color: #000;
      }
    }
    ${mobile({
        padding: "15px 30px",
    })} 
`;




///////////
const ImageSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;
const BgPhoto = styled.div`
    width: 100%;
    position: relative;
    aspect-ratio: 10 / 4;
    background-color: #a7a7a7;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    &:before {
        float: left;
        padding-top: 40%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
    @media (max-width: 768px) {
        aspect-ratio: 10 / 5;
        &:before {
        padding-top: 50%;
        }
    }
`;

const AddPhotoBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 5px 10px;
    background-color: #f0f1f5;
    border-radius: 7px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #e9e6e6;
    }
    ${mobile({
      bottom: "5px",
      left: "5px"
    })}
`;
const AddPhotoText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    margin-top: 3px;
    ${mobile({
      fontSize: "11px"
    })}
`;


const PhoneInputWrapper= styled.div`
    width: 100%;
    display: flex;
`;
const SelectCountryCode = styled.div`
    width: 100px;
    position: relative;
    font-size: 14px;
    cursor: pointer;
    ${mobile({

     })}
`;
const DropDownContainer = styled.div`
    width: 100%;
`;
const DropDownHeadWrap = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    z-index: 10;
`;
const DropDownListContainer = styled.div`
    width: 300px;
    position: absolute;
    z-index: 15;
    top: 50px;
    ${mobile({

    })}
`;

const DropDownList = styled.div`
    width: 100%;
`;
const CountryArrowWrap = styled.div`
  display: flex;
  rotate: ${props=>props.dragging != true ? "90deg" : "270deg"};
  transition: all 0.2s ease;
  margin-right: 5px;
`;
const ActiveFlag = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;
const ActiveTelCode = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
    color: #000;
    font-size: 14px;
    font-weight: 300;
`;
const CListItemSearch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  background-color: #eeeded;
  border-bottom: 1px solid #00000020;

`;
const CountryList = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    background-color: #eeeded;
`;
const CListItem = styled.div`
  margin-bottom: 0.8em;
  width: 100%;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  background-color: #eeeded;
  transition: all 0.3s ease;
  &:hover {
    background-color: #d6d6d6;
  }
`;
const CLeft = styled.div`
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const CRight = styled.div`
    width: calc(100% - 40px);
    padding-left: 10px;
    color: #000;
    font-size: 13px;
    font-weight: 600;
`;



const useStyles = makeStyles({
    AddBgImgIcon: {
      color: "#000",
      marginRight: 4,
      fontSize: 18,
      '@media (max-width: 768px)': {
        fontSize: 14,
      }
    },
    ProductIcon: {
        color: "#fff",
        marginRight: 4,
        fontSize: 18
      },
    SortIcon: {
        color: "#000",
        fontSize: 18
    },
    SearchIcon: {
        color: "#000",
        fontSize: 17
    },
  })

export default function AddProfile() {
    const classes = useStyles();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const userRequest = useUserRequest();

    const user = useSelector((state) => state.user.currentUser);
    const responseLoader = useSelector(state=>state.response.loader);
    const businessGroups = useSelector(state=>state.nonentity.industries);
    const businessCategories = useSelector(state=>state.nonentity.businessCategories);

    const [_businessCategories, setBusinessCategories] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState("");
    const [selectedImageUrl, setSelectedImageUrl] = useState(`${BASE_RSEOURCE_URL + "/uploads/user/business/images/default.jpg"}`);
    const inputRef = useRef(null);

    /////// Progress Bar ///////////////
    const [sectionXvalue, setSectionXvalue] = useState(0);
    const [progressBar, setProgressBar] = useState({
        first: true,
        second: false,
        third: false
    });

    const handleSectionClick = section => () => {
        let bar = {
            first: true,
            second: false,
            third: false
        }
        let value = 0;

        let result = isNext(section);

        if(section == "A" && result == true){
            bar = {first: true, second: false, third: false}
            value = 0;
            //value = -(100/3);
        }
        if(section == "B" && result == true){
            bar = {first: false, second: true, third: false};
            value = -(100/3);
            //value = -((2/3)*100);
        }
        if(section == "C" && result == true){
            bar = {first: false, second: false, third: true}
            value = -((2/3)*100);
        }

        setProgressBar(bar);
        setSectionXvalue(value);
        window.scrollTo(0, 0);
    };

    ////////// End ////////////////

    //// Country Telephone Code Selector - BusinessPhone///

    const [isPhoneCodeSelectorOpen, setIsPhoneCodeSelectorOpen] = useState(false);

    const [businessPhoneCountries, setBusinessPhoneCountries] = useState(countries);


    const [selectedPhoneCode, setSelectedPhoneCode] = useState(countries[0]);

const toggleBusinessPhoneCodeSelector = () => setIsPhoneCodeSelectorOpen(!isPhoneCodeSelectorOpen);

 
    const handlePhoneCodeClick = value => () => {
        setSelectedPhoneCode(value);
        setBusinessPhoneCountries(countries);
        setIsPhoneCodeSelectorOpen(false);
    };

    const onChangePhoneSearch = (e) => {
        let x = [...countries];
        x = x.filter(y => {
            return y.name.toLowerCase().includes(e.target.value.toLocaleLowerCase())
        });
        setBusinessPhoneCountries(x);
    };



    //// Country Telephone Code Selector - WhatsApp ///
    const [isWhatsAppCodeSelectorOpen, setIsWhatsAppCodeSelectorOpen] = useState(false);
    const [whatsAppCountries, setWhatsAppCountries] = useState(countries);
    const [selectedWhatsAppCode, setSelectedWhatsAppCode] = useState(countries[0]);

    
    const toggleWhatsAppCodeSelector = () => setIsWhatsAppCodeSelectorOpen(!isWhatsAppCodeSelectorOpen);
    
    const handleWhatsAppCodeClick = value => () => {
        setSelectedWhatsAppCode(value);
        setWhatsAppCountries(countries);
        setIsWhatsAppCodeSelectorOpen(false);
    };

    const onChangeWhatsAppSearch = (e) => {
        let x = [...countries];
        x = x.filter(y => {
            return y.name.toLowerCase().includes(e.target.value.toLocaleLowerCase())
        });
        setWhatsAppCountries(x);
    };

    ///////////////////////////////////



    const [values, setValues] = useState({
        industries: "",
        BusinessCategoryId: "",
        UserId: user.userId,
        BusinessName: "",
        Description: "",
        CoverImageFile: "",
        BusinessPhone: "",
        BusinessEmail: "",
        BusinessAddress: "",
        WhatsAppNumber: "",
        FacebookUrl: "",
        InstagramUrl: "",
        YoutubeUrl: ""
    
    });

    const [errors, setErrors] = useState({
        industries: "",
        BusinessCategoryId: "",
        BusinessName: "",
        Description: "",
        CoverImageFile: "",
        BusinessPhone: "",
        BusinessEmail: "",
        BusinessAddress: "",
        WhatsAppNumber: "",
        FacebookUrl: "",
        InstagramUrl: "",
        YoutubeUrl: ""
    
    });

    const [focus, setFocus] = useState({
        industries: false,
        BusinessCategoryId: false,
        BusinessName: false,
        Description: false,
        CoverImageFile: false,
        BusinessPhone: false,
        BusinessEmail: false,
        BusinessAddress: false,
        WhatsAppNumber: false,
        FacebookUrl: false,
        InstagramUrl: false,
        YoutubeUrl: false
    
    });

    useEffect(()=>{
        document.title = "";
        window.scrollTo(0, 0);
    
    },[]);
    
    const handleImageClick = () => {
        // 👇️ open file input box on click of another element
        inputRef.current.click();
    };

    const handleFileChange = event => {
        let error_msg = {...errors};
        let _focus = {...focus};
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
        if (fileObj.type == "image/jpeg" || fileObj.type == "image/jpg" || fileObj.type == "image/png") {
            if((fileObj.size/1024)/1024 < 2.1) {
                console.log('fileObj is', fileObj);
                console.log('fileObj Size', (fileObj.size/1024)/1024);
    
                //setSelectedImage(event.target.files[0]);
                setSelectedImageUrl(URL.createObjectURL(event.target.files[0]));
                setValues({ ...values, ["CoverImageFile"]: event.target.files[0] });
                //let tmp_path = URL.createObjectURL(event.target.files[0]);
                //document.getElementbyId('bgImage').style.background = "url(" + tmp_path + ")";
    
                // 👇️ reset file input
                event.target.value = null;
    
                // 👇️ is now empty
                console.log(event.target.files);
    
                // 👇️ can still access file object here
                console.log(fileObj.type);
                console.log(fileObj.name);
    
                error_msg = {
                    ...error_msg, 
                    ["CoverImageFile"]: ""
                };
            }else{
                error_msg = {
                    ...error_msg, 
                    ["CoverImageFile"]: "Image size should not be more than 2mb"
                };
            }
        }else {
        //console.log("invalid image format");
        error_msg = {
            ...error_msg, 
            ["CoverImageFile"]: "Invalid image format"
        };
        }

        _focus = {
            ..._focus, 
            ["CoverImageFile"]: true
        };
        setFocus(_focus);
        setErrors(error_msg);
    };

    const handleGroupChange = event => {
        setSelectedGroupId(event.target.value);
        getBusinessCategories(event.target.value);
        setValues({ ...values, [event.target.name]: event.target.value});
        validateInput(event.target.name, event.target.value);
      };

    const getBusinessCategories = async (id) => {
        let cats = [];
        businessCategories.map(x => {
            if(x.businessGroupId == id) {
                cats.push(x);
            }
        });
        setBusinessCategories(cats);
    };

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value.trim()});
        validateInput(e.target.name, e.target.value);
        //console.log(values);
    };

    const handleFocus = (e) => {
        setFocus({ ...focus, [e.target.name]: true });
        //console.log(focus);
    };

    const isNext = (section) => {

        let error_msg = {...errors};
        let _focus = {...focus};
        let next = true;

        if (section == "B"){
            if (values.industries == "" ) {
                error_msg = {
                    ...error_msg, 
                    ["industries"]: "Select an industry"
                };
                _focus = {
                    ..._focus, 
                    ["industries"]: true
                };
                next = false;
            }
            if (values.BusinessCategoryId == "" ) {
                next = false;
                error_msg = {
                    ...error_msg, 
                    ["BusinessCategoryId"]: "Select a business category"
                };
                _focus = {
                    ..._focus, 
                    ["BusinessCategoryId"]: true
                };
            }
            if (values.BusinessName == "") {
                next = false;
                error_msg = {
                    ...error_msg, 
                    ["BusinessName"]: "Enter your business name"
                };
                _focus = {
                    ..._focus, 
                    ["BusinessName"]: true
                };
            }
        }

        if (section == "C"){
            if (values.CoverImageFile == "" ) {
                error_msg = {
                    ...error_msg, 
                    ["CoverImageFile"]: "Selelct a background image"
                };
                _focus = {
                    ..._focus, 
                    ["CoverImageFile"]: true
                };
                next = false;
            }
        }

        setErrors(error_msg);
        setFocus(_focus);

        return next;

    };

    const validateInput = (inputName, InputValue) => {
        let regex = "";

        let valid = true;

        let error_msg = {...errors};

        //// BusinessName /////
        if (inputName === "BusinessName") {
            //regex = /^(?=.{3,100}$)[^\W_]+(?: [^\W_]+)*$/;
            regex = /^[A-Za-z0-9 ]{3,100}$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "character length should be between 3 and 100. Only alphabets and numbers."
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Category Ids /////
        if (inputName === "industries" || inputName === "BusinessCategoryId") {
            // regex = /^.{10,500}$/s;
            // valid = regex.test(InputValue);
            if(InputValue == "") {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Select a category"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Description /////
        if (inputName === "Description") {
            //regex = /^[A-Za-z0-9 -/,.]{3,10}$/;
            regex = /^.{10,500}$/s;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "character length should be between 10 and 500"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Phone /////
        if (inputName === "BusinessPhone" || inputName === "WhatsAppNumber") {
            regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Only numbers of 10 characters"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }
     
        //// Email /////
        if (inputName === "BusinessEmail") {
            regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Enter a valid email address"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Address /////
        if (inputName === "BusinessAddress") {
            regex = /^[A-Za-z0-9 -.,]{3,200}$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Enter a valid address not more than 200 characters"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Url Links /////
        if (inputName === "FacebookUrl" || inputName === "InstagramUrl" || inputName === "YoutubeUrl") {
            regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Enter a valid url with http protocol (http:// or https://)"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }


        console.log(errors);
        setErrors(error_msg);

    };
 
    const checkRequiredInputs = () => {
        let error_msg = {...errors};
        let _focus = {...focus};

        let result = true;

        if (values.industries == "" ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["industries"]: "Select an industry"
            };
            _focus = {
                ..._focus, 
                ["industries"]: true
            };
        }
        if (values.BusinessCategoryId == "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["BusinessCategoryId"]: "Select a business category"
            };
            _focus = {
                ..._focus, 
                ["BusinessCategoryId"]: true
            };
        }
        if (values.BusinessName == "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["BusinessName"]: "Enter your business name"
            };
            _focus = {
                ..._focus, 
                ["BusinessName"]: true
            };
        }

        setErrors(error_msg);
        setFocus(_focus);
        return result;
    };


    
    const handleSubmit = async (e) => {
        e.preventDefault();

        /// Check if required values are not empty ///
        let result = checkRequiredInputs();

        if(result) {
            //console.log("FORM IS GOOD");
            dispatch(StartLoader());
            let formData = new FormData();
            formData.append('BusinessCategoryId', values.BusinessCategoryId);
            formData.append('UserId', values.UserId);
            formData.append('BusinessName', values.BusinessName);
            formData.append('Description', values.Description);
            formData.append('CoverImageFile', values.CoverImageFile);
            formData.append('BusinessPhone', values.BusinessPhone);
            formData.append('BusinessPhoneCountryCode', selectedPhoneCode.phone);
            formData.append('BusinessEmail', values.BusinessEmail);
            formData.append('BusinessAddress', values.BusinessAddress);
            formData.append('WhatsAppNumber', values.WhatsAppNumber);
            formData.append('WhatsAppNumberCountryCode', selectedWhatsAppCode.phone);
            formData.append('FacebookUrl', values.FacebookUrl);
            formData.append('InstagramUrl', values.InstagramUrl);
            formData.append('YoutubeUrl', values.YoutubeUrl);
    
            try {
                const res = await userRequest.post("/Business", formData);
                //console.log("Returned Object:", res.data)
                dispatch(AddToProfiles(res.data));
                dispatch(updateResponse(
                    {
                        active: true,
                        success: true,
                        message: "Success: The business profile was created successfully"
                    }
                ));
                dispatch(StopLoader());
                navigate("/user/profiles");
            } catch (err) {
                dispatch(updateResponse(
                    {
                        active: true,
                        success: false,
                        message: "Error: The operation failed. Check your internet and try again"
                    }
                ));
                dispatch(StopLoader());
                navigate("/user/profiles");
                console.log("Returned Error:", err);
            }
        }else {
            window.scrollTo(0, 0);
            console.log("FORM IS NOT GOOD");
        }

    };
  return (
    <Container>
        <Wrapper>
            <Header>
                <PageTitle>New profile settings</PageTitle>
            </Header>
            <form  className="c_form">
            <ProgressBarWrap>
                <ProgressBar>
                    <ProgressBarItem width={40}>
                        <ProgressBarText active={progressBar.first}>Category & description</ProgressBarText>
                    </ProgressBarItem>
                    <ProgressBarItem width={35}>
                        <ProgressBarText active={progressBar.second}>image & contact</ProgressBarText>
                    </ProgressBarItem>
                    <ProgressBarItem width={25}>
                        <ProgressBarText active={progressBar.third}>socials</ProgressBarText>
                    </ProgressBarItem>
                </ProgressBar>
            </ProgressBarWrap>
            <FormContainer xValue={sectionXvalue}>
                <FormSectionA>
                    <FormItem>
                        <Left>
                            <label for="industries">Industry</label>
                            <LabelDesc>
                                Select your business type
                            </LabelDesc>

                        </Left>
                        <Right>
                            <InputItem>
                                <select 
                                    className="main_input c_input"
                                    name="industries" 
                                    id="industries"
                                    onBlur={handleFocus}
                                    value={selectedGroupId} 
                                    onChange={handleGroupChange}
                                    >
                                    <option value="">Select business type</option>
                                    {businessGroups.map(group => (
                                        <option key={group.id} value={group.id}>{group.name}</option>
                                    ))}
                                </select>                  
                            </InputItem>
                            <ErrorMsg>{focus.industries && errors.industries != "" && errors.industries}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <FormItem>
                        <Left>
                            <label for="categories">Business category</label>
                            <LabelDesc>
                                Select your business category
                            </LabelDesc>

                        </Left>
                        <Right>
                            <InputItem>
                                <select 
                                    className="main_input c_input"
                                    name="BusinessCategoryId" 
                                    id="categories"
                                    onBlur={handleFocus}
                                    onChange={onChange}>
                                    <option value="">Select category</option>
                                    {_businessCategories.map(cat => (
                                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                                    ))}
                                </select>                  
                            </InputItem>
                            <ErrorMsg>{focus.BusinessCategoryId && errors.BusinessCategoryId != "" && errors.BusinessCategoryId}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <FormItem>
                        <Left>
                            <label for="name_id">business name</label>
                            <LabelDesc>
                                Enter your business name
                            </LabelDesc>

                        </Left>
                        <Right>
                            <InputItem>
                                <input
                                    className="main_input c_input"
                                    type="text"
                                    id="name_id"
                                    onBlur={handleFocus}
                                    name="BusinessName"
                                    placeholder=''
                                    onChange={onChange}
                                    required />                  
                            </InputItem>
                            <ErrorMsg>{focus.BusinessName && errors.BusinessName != "" && errors.BusinessName}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <FormItem>
                        <Left>
                            <label for="desc_id">Description</label>
                            <LabelDesc>
                                Describe your business in less than 500 words
                            </LabelDesc>

                        </Left>
                        <Right>
                            <InputItem>
                                <textarea
                                    className=""
                                    id="desc_id"
                                    rows="5"
                                    name="Description"
                                    onBlur={handleFocus}
                                    onChange={onChange}
                                    placeholder=''
                                />                   
                            </InputItem>
                            <ErrorMsg>{focus.Description && errors.Description != "" && errors.Description}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <CtaWrap>
                        <BtnWrap>
                            <Link to={`/user/profiles`} style={{ textDecoration: 'none' }} >
                                <CancelBtn>
                                    <CancelBtnText>cancel</CancelBtnText>
                                </CancelBtn>
                            </Link>
                            <ContinueBtn onClick={handleSectionClick("B")}>
                                <ContinueBtnText>continue</ContinueBtnText>
                            </ContinueBtn>
                        </BtnWrap>
                    </CtaWrap>
                </FormSectionA>
                
                <FormSectionB>
                    <FormItem>
                        <Left>
                            <label for="cover_image_id">background image</label>
                            <LabelDesc>
                                Upload a background photo for your profile
                            </LabelDesc>
                        </Left>
                        <Right>
                            <InputItem>
                                <ImageSection>
                                    <BgPhoto style={{ backgroundImage: `url(${selectedImageUrl})`}}>
                                        <AddPhotoBtn onClick={handleImageClick}>
                                            <AddAPhoto className={classes.AddBgImgIcon} />
                                            <AddPhotoText>Background photo</AddPhotoText>
                                        </AddPhotoBtn>
                                    </BgPhoto>
                                </ImageSection>
                                <input
                                    ref={inputRef}
                                    type="file"
                                    id="cover_image_id"
                                    style={{display: 'none'}}
                                    onChange={handleFileChange}
                                    />                  
                            </InputItem>
                            <ErrorMsg>{focus.CoverImageFile && errors.CoverImageFile != "" && errors.CoverImageFile }</ErrorMsg>
                        </Right>
                    </FormItem>
                    <FormItem>
                        <Left>
                            <label for="phone_id">business phone</label>
                            <LabelDesc>
                            </LabelDesc>
                        </Left>
                        <Right>
                            <InputItem>
                                <PhoneInputWrapper>
                                    <SelectCountryCode>
                                        <DropDownContainer>
                                            <DropDownHeadWrap onClick={toggleBusinessPhoneCodeSelector}>
                                                <CountryArrowWrap dragging={isPhoneCodeSelectorOpen}>
                                                    <ChevronRight className={classes.SortIcon} />
                                                </CountryArrowWrap>
                                                <ActiveFlag>
                                                    <Icon icon={`flag:${selectedPhoneCode.code.toLowerCase()}-4x3`} />
                                                </ActiveFlag>
                                                <ActiveTelCode>
                                                    +{selectedPhoneCode.phone}
                                                </ActiveTelCode>     
                                            </DropDownHeadWrap>
                                            {isPhoneCodeSelectorOpen && (
                                            <DropDownListContainer>
                                                <DropDownList>
                                                    <CListItemSearch>
                                                        <CLeft>
                                                            <Search className={classes.SearchIcon}/>
                                                        </CLeft>
                                                        <CRight>
                                                            <input 
                                                                type="text" 
                                                                className="country-search-box"
                                                                onChange={onChangePhoneSearch} 
                                                                name="search-box" 
                                                                placeholder="Search Country" />
                                                        </CRight>
                                                    </CListItemSearch>
                                                    <CountryList>
                                                        {businessPhoneCountries.map(c => (
                                                            <CListItem onClick={handlePhoneCodeClick(c)} key={Math.random()}>
                                                                <CLeft>
                                                                    <Icon icon={`flag:${c.code.toLowerCase()}-4x3`} />
                                                                </CLeft>
                                                                <CRight>{c.name} (+{c.phone})</CRight>
                                                            </CListItem>
                                                        ))}
                                                    </CountryList>
                                                </DropDownList>
                                            </DropDownListContainer>
                                            )}
                                        </DropDownContainer>
                                    </SelectCountryCode>
                                    <input
                                        className="main_input phone_input"
                                        type="text"
                                        id="phone_id"
                                        name="BusinessPhone"
                                        placeholder='0000000000'
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        required />
                                </PhoneInputWrapper>                 
                            </InputItem>
                            <ErrorMsg>{focus.BusinessPhone && errors.BusinessPhone != "" && errors.BusinessPhone}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <FormItem>
                        <Left>
                            <label for="email_id">business email</label>
                            <LabelDesc>
                            </LabelDesc>
                        </Left>
                        <Right>
                            <InputItem>
                                <input
                                    className="main_input c_input"
                                    type="text"
                                    id="email_id"
                                    name="BusinessEmail"
                                    placeholder=''
                                    onBlur={handleFocus}
                                    onChange={onChange}
                                    required />                 
                            </InputItem>
                            <ErrorMsg>{focus.BusinessEmail && errors.BusinessEmail != "" && errors.BusinessEmail}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <FormItem>
                        <Left>
                            <label for="address_id">business address</label>
                            <LabelDesc>
                            </LabelDesc>
                        </Left>
                        <Right>
                            <InputItem>
                                <input
                                    className="main_input c_input"
                                    type="text"
                                    id="address_id"
                                    name="BusinessAddress"
                                    placeholder=''
                                    onBlur={handleFocus}
                                    onChange={onChange}
                                    required />             
                            </InputItem>
                            <ErrorMsg>{focus.BusinessAddress && errors.BusinessAddress != "" && errors.BusinessAddress}</ErrorMsg>
                        </Right>
                    </FormItem>                            
                    <CtaWrap>
                        <BtnWrap>
                            <CancelBtn onClick={handleSectionClick("A")}>
                                <CancelBtnText>back</CancelBtnText>
                            </CancelBtn>
                            <ContinueBtn onClick={handleSectionClick("C")}>
                                <ContinueBtnText>continue</ContinueBtnText>
                            </ContinueBtn>
                        </BtnWrap>
                    </CtaWrap>
                </FormSectionB>
                <FormSectionC>
                <FormItem>
                        <Left>
                            <label for="whatsapp_id">whatsApp number</label>
                            <LabelDesc>
                            </LabelDesc>
                        </Left>
                        <Right>
                            <InputItem>
                                <PhoneInputWrapper>
                                    <SelectCountryCode>
                                        <DropDownContainer>
                                            <DropDownHeadWrap onClick={toggleWhatsAppCodeSelector}>
                                                <CountryArrowWrap dragging={isWhatsAppCodeSelectorOpen}>
                                                    <ChevronRight className={classes.SortIcon} />
                                                </CountryArrowWrap>
                                                <ActiveFlag>
                                                    <Icon icon={`flag:${selectedWhatsAppCode.code.toLowerCase()}-4x3`} />
                                                </ActiveFlag>
                                                <ActiveTelCode>
                                                    +{selectedWhatsAppCode.phone}
                                                </ActiveTelCode>     
                                            </DropDownHeadWrap>
                                            {isWhatsAppCodeSelectorOpen && (
                                            <DropDownListContainer>
                                                <DropDownList>
                                                    <CListItemSearch>
                                                        <CLeft>
                                                            <Search className={classes.SearchIcon}/>
                                                        </CLeft>
                                                        <CRight>
                                                            <input 
                                                                type="text" 
                                                                className="country-search-box"
                                                                onChange={onChangeWhatsAppSearch} 
                                                                name="search-box" 
                                                                placeholder="Search Country" />
                                                        </CRight>
                                                    </CListItemSearch>
                                                    <CountryList>
                                                        {whatsAppCountries.map(c => (
                                                            <CListItem onClick={handleWhatsAppCodeClick(c)} key={Math.random()}>
                                                                <CLeft>
                                                                    <Icon icon={`flag:${c.code.toLowerCase()}-4x3`} />
                                                                </CLeft>
                                                                <CRight>{c.name} (+{c.phone})</CRight>
                                                            </CListItem>
                                                        ))}
                                                    </CountryList>
                                                </DropDownList>
                                            </DropDownListContainer>
                                            )}
                                        </DropDownContainer>
                                    </SelectCountryCode>
                                <input
                                    className="main_input phone_input"
                                    type="text"
                                    id="whatsapp_id"
                                    name="WhatsAppNumber"
                                    placeholder='0000000000'
                                    onBlur={handleFocus}
                                    onChange={onChange}
                                    required />
                                </PhoneInputWrapper>                   
                            </InputItem>
                            <ErrorMsg>{focus.WhatsAppNumber && errors.WhatsAppNumber != "" && errors.WhatsAppNumber}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <FormItem>
                        <Left>
                            <label for="facebook_id">Facebook URL</label>
                            <LabelDesc>
                            </LabelDesc>
                        </Left>
                        <Right>
                            <InputItem>
                                <input
                                    className="main_input c_input"
                                    type="text"
                                    id="facebook_id"
                                    name="FacebookUrl"
                                    placeholder=''
                                    onBlur={handleFocus}
                                    onChange={onChange}
                                    required />                  
                            </InputItem>
                            <LinkSamleText>https://web.facebook.com/klustaa</LinkSamleText>
                            <ErrorMsg>{focus.FacebookUrl && errors.FacebookUrl != "" && errors.FacebookUrl}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <FormItem>
                        <Left>
                            <label for="instagram_id">Instagram URL</label>
                            <LabelDesc>
                            </LabelDesc>
                        </Left>
                        <Right>
                            <InputItem>
                                <input
                                    className="main_input c_input"
                                    type="text"
                                    id="instagram_id"
                                    name="InstagramUrl"
                                    placeholder=''
                                    onBlur={handleFocus}
                                    onChange={onChange}
                                    required />                  
                            </InputItem>
                            <LinkSamleText>https://www.instagram.com/klustaa.ng/</LinkSamleText>
                            <ErrorMsg>{focus.InstagramUrl && errors.InstagramUrl != "" && errors.InstagramUrl}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <FormItem>
                        <Left>
                            <label for="youtube_id">youtube URL</label>
                            <LabelDesc>
                            </LabelDesc>
                        </Left>
                        <Right>
                            <InputItem>
                                <input
                                    className="main_input c_input"
                                    type="text"
                                    id="youtube_id"
                                    name="YoutubeUrl"
                                    placeholder=''
                                    onBlur={handleFocus}
                                    onChange={onChange}
                                    required />                   
                            </InputItem>
                            <LinkSamleText>https://www.youtube.com/@klustaa</LinkSamleText>
                            <ErrorMsg>{focus.YoutubeUrl && errors.YoutubeUrl != "" && errors.YoutubeUrl}</ErrorMsg>
                        </Right>
                    </FormItem>
                    <CtaWrap>
                        <BtnWrap>
                            <CancelBtn onClick={handleSectionClick("B")}>
                                <CancelBtnText>back</CancelBtnText>
                            </CancelBtn>
                            <ContinueBtn onClick={handleSubmit}>
                                <ContinueBtnText>create profile</ContinueBtnText>
                            </ContinueBtn>
                        </BtnWrap>
                    </CtaWrap>
                </FormSectionC>
            </FormContainer>

            </form>
        </Wrapper>
        {responseLoader.active == true ?
            <ResponseLoader />
            :
            <></>
          }
    </Container>
  )
}
