import React from "react";
import styled, { keyframes }  from 'styled-components';
import { mobile, mobileXL, tablet } from '../../responsive';


const waveLines = keyframes`
    0% {
            background-position: -468px 0;
        }
    100% {
        background-position: 468px 0;
        }
`;

const waveSquares= keyframes`
    0% {
         background-position: -468px 0;
        }
    100% {
        background-position: 468px 0;
        }
`;

//// List ////

const ListItem = styled.div`
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;


/// Head ////////

const Container = styled.div`
    width: 100%;
`;
const Wrapper = styled.div`
    width: 100%;
`;
const HeaderText = styled.div`
    width: 65%;
    height: 40px;
    margin-bottom:20px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;

const DescText = styled.div`
    width: 85%;
    height: 20px;
    margin-bottom:20px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;

const LandscapeImage = styled.div`
    width: 100%;
    aspect-ratio: 5 / 2;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveSquares};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:before {
        float: left;
        padding-top: 45%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
    @media (max-width: 768px) {
        aspect-ratio: 5 / 3;
        &:before {
        float: left;
        padding-top: 65%;
        content: '';
        }
        &:after {
            display: block;
            content: '';
            clear: both;
        }
    }

`;


///////// CollectionWrap ///////////

const CollectionWrap = styled.div`
    width: 100%;
`;

const CollectionWrapItem = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 100px;
`;

const CollectionWrapItemAlt = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 100px;
    ${mobile({
        flexDirection: "column",
    })}
`;

const CollectionWrapImageContainer = styled.div`
    width: 40%;
    ${mobile({
        width: "100%",
    })}
`;

const CollectionWrapImageBg = styled.div`
    width: 100%;
    aspect-ratio: 4 / 5;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveSquares};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:before {
        float: left;
        padding-top: 125%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
`;
const CollectionWrapDetail = styled.div`
    width: 60%;
    padding-left: 30px;
`;
const CollectionWrapDetailAlt = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 30px;
    ${mobile({
        width: "100%",
        marginBottom: "20px",
        paddingRight: "0px",
        alignItems: "flex-start"
    })}
`;
const CollectionWrapName = styled.div`
    width: 180px;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 50px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;
const CollectionWrapReleaseDate = styled.div`
    width: 160px;
    height: 10px;
    margin-bottom: 10px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;
const CollectionWrapDateValue = styled.div`
    width: 220px;
    height: 30px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;

//// Products /////////
const ProductsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;
const ProductsWrapItem = styled.div`
    width: calc((100% / 3) - 30px);
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    ${mobileXL({
        width: "calc((100% / 2) - 10px)",
        marginLeft: "5px",
        marginRight: "5px",
        marginBottom: "20px"
    })}
    ${mobile({
        marginLeft: "5px",
        marginRight: "5px",
    })}
`;
const ProductsWrapProductCover = styled.div`
    width: 100%;
    aspect-ratio: 4 / 5;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveSquares};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:before {
        float: left;
        padding-top: 125%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
`;
const ProductsWrapName = styled.div`
    margin-top: 5px;
    width: 90%;
    height: 10px;
    margin-bottom: 7px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;
const ProductsWrapPrice = styled.div`
    width: 80%;
    height: 10px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;

///// ProductWrap ////

const ProductWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 100px;
`;
const ProductWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
${mobile({ 
    width: "100%",
    flexDirection: "column"
})}
`;
const ProductImageSection = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    ${mobile({ 
      width: "100%",
    })}
`;
const ProductImages = styled.div`
    width: 20%;
`;
const ProductImgItemWrap = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;

const ProductCurrentImgWrap = styled.div`
    width: calc(80% - 20px);
    margin-left: 20px;
    display: flex;
    ${mobile({ 
      width: "calc(80% - 10px)",
      marginLeft: "10px"
    })}
`;
const ProductDetailWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;
const ProductDesc = styled.div`
    width: 95%;
    height: 10px;
    margin-bottom: 10px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;
const ProductDescShort = styled.div`
    width: 50%;
    height: 10px;
    margin-bottom: 10px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;
const ProductPrice = styled.div`
    width: 160px;
    height: 30px;
    margin-bottom: 10px;
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation-name: ${waveLines};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;

////// Profile ///

const ProfileWrapper = styled.div`
  width: 100%;
   margin-top: 100px;
`;


const Loader = ({type}) => {

    if (type === "list") {
        return (
            <Container>
                <Wrapper>
                    <ListItem/>
                    <ListItem/>
                    <ListItem/>
                    <ListItem/>
                </Wrapper>
            </Container>
        );
    }








    if (type === "head") {
        return (
            <Container>
                <Wrapper>
                    <HeaderText/>
                    <DescText/>
                    <LandscapeImage />
                </Wrapper>
            </Container>
        );
    }
    if (type === "collectionWrap") {
        return (
            <Container>
                <CollectionWrap>
                    <CollectionWrapItem>
                        <CollectionWrapImageContainer>
                            <CollectionWrapImageBg />
                        </CollectionWrapImageContainer>
                        <CollectionWrapDetail>
                            <CollectionWrapName />
                            <CollectionWrapReleaseDate />
                            <CollectionWrapDateValue />
                        </CollectionWrapDetail>
                    </CollectionWrapItem>
                </CollectionWrap>
            </Container>
        );
    }
        if (type === "collectionWrapMob") {
        return (
            <Container>
                <CollectionWrap>
                    <CollectionWrapItemAlt>
                        <CollectionWrapDetailAlt>
                            <CollectionWrapName />
                            <CollectionWrapReleaseDate />
                            <CollectionWrapDateValue />
                        </CollectionWrapDetailAlt>
                        <CollectionWrapImageContainer>
                            <CollectionWrapImageBg />
                        </CollectionWrapImageContainer>
                    </CollectionWrapItemAlt>
                </CollectionWrap>
            </Container>
        );
    }
    if (type === "products") {
        return (
            <Container>
                <ProductsWrap>
                    <ProductsWrapItem>
                        <ProductsWrapProductCover />
                        <ProductsWrapName />
                            <ProductsWrapPrice />
                    </ProductsWrapItem>
                    <ProductsWrapItem>
                        <ProductsWrapProductCover />
                        <ProductsWrapName />
                            <ProductsWrapPrice />
                    </ProductsWrapItem>
                    <ProductsWrapItem>
                        <ProductsWrapProductCover />
                        <ProductsWrapName />
                            <ProductsWrapPrice />
                    </ProductsWrapItem>
                    <ProductsWrapItem>
                        <ProductsWrapProductCover />
                        <ProductsWrapName />
                            <ProductsWrapPrice />
                    </ProductsWrapItem>
                </ProductsWrap>
            </Container>
        );
    }
        if (type === "product") {
        return (
            <Container>
                <ProductWrap>
                    <HeaderText/>
                    <DescText/>
                    <ProductWrapper>
                        <ProductImageSection>
                            <ProductImages>
                                <ProductImgItemWrap>
                                    <CollectionWrapImageBg/>
                                </ProductImgItemWrap >
                                <ProductImgItemWrap>
                                    <CollectionWrapImageBg/>
                                </ProductImgItemWrap >
                                <ProductImgItemWrap>
                                    <CollectionWrapImageBg/>
                                </ProductImgItemWrap >
                            </ProductImages>
                            <ProductCurrentImgWrap>
                                <CollectionWrapImageBg/>
                            </ProductCurrentImgWrap>
                        </ProductImageSection>
                    </ProductWrapper>
                    <ProductDetailWrap>
                        <ProductDesc/>
                        <ProductDesc/>
                        <ProductDescShort/>
                        <ProductPrice/>
                    </ProductDetailWrap>
                </ProductWrap>
            </Container>
        );
    }
            if (type === "profile") {
        return (
            <Container>
                <ProfileWrapper>
                    <HeaderText/>
                    <DescText/>
                    <LandscapeImage />
                    <ProductDetailWrap>
                        <ProductDesc/>
                        <ProductDesc/>
                        <ProductDescShort/>
                    </ProductDetailWrap>
                </ProfileWrapper>
            </Container>
        );
    }


}

export default Loader;