import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import { 
  Create,
  AddBoxOutlined,
  Add,
  DeleteOutlined
  } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';

// import ItemImg1 from '../../assets/images/home-bg.jpg';
// import ItemImg2 from '../../assets/images/bg4.jpg';
// import ItemImg3 from '../../assets/images/hero-bg.jpg';

import { useNavigate, useLocation } from "react-router-dom";
import { BASE_RSEOURCE_URL} from "../../requestMethods";
//import axios from 'axios';
import Moment from 'moment';
//import Loader from '../../components/generic/Loader';
import Response from '../../components/generic/Response';
import ResponseLoader from '../../components/generic/ResponseLoader';
import DeleteBusiness from '../../components/controllers/DeleteBusiness';

const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    margin-left: 200px;
    padding: 40px;
    background-color: #e2e2e2;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "10px 0px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
    position: relative;
    ${mobile({
        marginTop: "40px"
    })}  
`;
const Header = styled.div`
    width: 100%;
    border-bottom: 1px solid #00000030;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none",
      padding: "10px 15px 20px 15px"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    ${mobile({
        fontSize: "23px"
    })}
`;
const AddBtnMob = styled.div`
    position: fixed;
    bottom: 30px;
    left: 20px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #B8F904;
    padding: 8px 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    ${mobile({
      display: "flex"
    })}
`;
const AddBtnText = styled.div`
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;
const AddBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000FD;
    padding: 8px 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #B8F904;
      ${AddBtnText} {
        color: #000;
      }
    }
    ${mobile({
      display: "none"
    })}
`;

const SubHeader = styled.div`
    display: flex;
    width: 100%;
    ${mobile({
      display: "none"
    })}
`;
const SortBtnText = styled.div` 
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 5px;
  transition: all 0.3s ease;
`;
const SortIconWrap = styled.div`
  display: flex;
  /* justify-content: center;
  align-items: center; */
  /* border: 1px solid #00000040; */
  /* rotate: 90deg; */
  background-color: #00000050;
  rotate: ${props=>props.dragging === true ? "0deg" : "90deg"};
  transition: all 0.3s ease;
`;
const SortBtn = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        ${SortBtnText} {
            color: #00000080;
        }
        ${SortIconWrap} {
            background-color: #00000080;
        }
    }
`;

const Body = styled.div`
  ${mobile({
    marginTop: "20px"
  })}
    
`;
const CollectionsWrap = styled.div`
    width: 100%;
`;
const Item = styled.div`
    width: 100%;
    background-color: #ffffff;
    padding: 20px 20px;
    margin-bottom: 10px;
    display: flex;
    transition: all 0.3s ease;
    background-color: ${props=>props.active === false && "#faefef"};
    cursor: pointer;
    &:hover {
      background-color: #accf4c;
      }
    ${mobile({
        padding: "15px 10px"
    })}
`;
const Image = styled.div`
  width: 120px;
  height: 72px;
  margin-right: 20px;
  background-color: #212121;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease;
  ${tablet({
    marginRight: "10px",
    width: "100px",
    height: "60px"
  })}
`;
const HeadItem = styled.div`
    width: 100%;
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
    display: flex;
`;
const HeadImage = styled.div`
  width: 120px;
  margin-right: 20px;
    ${tablet({
      marginRight: "10px",
      width: "100px",
  })}
`;
const HeadNameWrap = styled.div`
  width: 30%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  ${tablet({
    width: "calc(100% - 380px)",
    marginRight: "10px"
  })}
  ${mobile({
    width: "calc(100% - 90px)",
  })}
`;
const HeadName = styled.div`
  width: 100%;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
`;
const HeadCategory = styled.div`
  width: calc(70% - 410px);
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  margin-right: 20px;
  text-transform: capitalize;
  ${tablet({
      display: "none"
  })}
`;
const HeadDetails = styled.div`
  display: flex;
  width: max-content;
  margin-right: 20px;
  ${tablet({
    marginRight: "10px"
  })}
  ${mobile({
      display: "none"
    })}
`;
const HeadReleaseDate = styled.div`
  width: 80px;
  margin-right: 20px;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  ${tablet({
    marginRight: "10px"
  })}
`;
const HeadStatus = styled.div`
  width: 90px;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
`;
const HeadActions = styled.div`
  width: 80px;
  color: #00000040;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  ${tablet({
    width: "70px"
  })}
`;

///////// END HEAD /////////////
const NameWrap = styled.div`
  width: 30%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  ${tablet({
    width: "calc(100% - 380px)",
    marginRight: "10px"
  })}
  ${mobile({
    width: "calc(100% - 90px)",
  })}
`;
const Name = styled.div`
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize;
  margin-bottom: 5px;
`;
const Profile = styled.div`
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`;
const Category = styled.div`
  width: calc(70% - 410px);
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
  text-transform: capitalize;
  ${tablet({
      display: "none"
  })}
`;
const Details = styled.div`
  display: flex;
  width: max-content;
  margin-right: 20px;
  ${tablet({
    marginRight: "10px"
  })}
  ${mobile({
      display: "none"
    })}
`;
const ReleaseDate = styled.div`
  width: 80px;
  margin-right: 20px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  ${tablet({
    marginRight: "10px"
  })}
`;
const Status = styled.div`
  width: 90px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`;
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: max-content;
  ${mobile({
    flexDirection: "column",
    justifyContent: "start",
    // alignItems: "flex-end",
  })}
`;
const EditBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  margin-right: 10px;
  ${mobile({
    alignItems: "start",
    marginRight: "0px",
    marginBottom: "20px"
  })}
`;
const EditBtnText = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 3px;
`;
const DelBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
`;
const DelBtnText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    margin-top: 3px;
`;

const NoContentText = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  margin-top: 50px;
  ${mobile({
    padding: "0px 15px",
  })}
`;

const useStyles = makeStyles({
  ActionIcons: {
      color: "#00000060",
      fontSize: 18,
      cursor: "pointer",
      '@media (max-width: 768px)': {
        fontSize: 22,
      }
  },
  AddIcon: {
    color: "#000",
    fontSize: 25
  },
  SortIcon: {
    color: "#fff",
    fontSize: 15
  },
})

export default function Profiles() {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const response = useSelector(state=>state.response.response);
  const responseLoader = useSelector(state=>state.response.loader);


  const  user = useSelector((state) => state.user.currentUser);
  const profiles = useSelector((state) => state.profile.profiles);
  const businessCategories = useSelector(state=>state.nonentity.businessCategories);

  const [businessCategoriesMod, setBusinessCategoriesMod] = useState([]);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const CloseDeleteModal = () => {
    setIsDeleteOpen(false);
  };

  const OpenDeleteModal = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteBtn = (e, id) => {
    e.preventDefault();
    setDeleteId(id);
    OpenDeleteModal();
  };



  useEffect(()=>{
    document.title = "";
    window.scrollTo(0, 0);
    
    getBusinessCategories();
  },[]);

  const getBusinessCategories = async () => {
    let _profiles = [];
    businessCategories.map(x => {
      _profiles[x.id] = x.name;
    });
    setBusinessCategoriesMod(_profiles);
  };

  return (
    <Container>
        <Wrapper>
          {response.active == true ?
            <Response/>
            :
            <></>
          }
          <Link to={`/user/profile/add`} style={{ textDecoration: 'none' }}>
            <AddBtnMob>
              <Add className={classes.AddIcon}/>
            </AddBtnMob>
          </Link>
          <Header>
            <PageTitle>Profiles ({profiles.length})</PageTitle>
            <Link to={`/user/profile/add`} style={{ textDecoration: 'none' }}>
              <AddBtn>
                <AddBtnText>Add profile</AddBtnText>
              </AddBtn>
            </Link>
          </Header>
          <SubHeader>
            <HeadItem>
                <HeadImage>

                </HeadImage>
                  <HeadNameWrap>
                    <HeadName>Name & industry</HeadName>
                  </HeadNameWrap>
                  <HeadCategory>Category</HeadCategory>
                  <HeadDetails>
                    <HeadReleaseDate>Created</HeadReleaseDate>
                    <HeadStatus>Status</HeadStatus>
                  </HeadDetails>
                  <HeadActions>
                    Actions
                  </HeadActions>
                </HeadItem>
          </SubHeader>
          <Body>
            {profiles.length > 0 ?
                <CollectionsWrap>
                {profiles.map(profile => (
                  <Link key ={profile.id} to={`/user/profile/${profile.id}`} style={{ textDecoration: 'none' }}>
                    <Item active={profile.active}>
                      <Image style={{ backgroundImage: `url(${BASE_RSEOURCE_URL + "/uploads/user/business/images/" + profile.coverImage})`}} />
                      <NameWrap>
                        <Name>{profile.businessName}</Name>
                        <Profile>{businessCategoriesMod[profile.businessCategoryId]}</Profile>
                      </NameWrap>
                      <Category>Fashion</Category>
                      <Details>
                        <ReleaseDate>{Moment().utc().format('Do MMMM YYYY')}</ReleaseDate>
                        <Status>{profile.active === true ? "Active" : "Suspended"}</Status>
                      </Details>
                      <Actions>
                        <EditBtn>
                          <Tooltip title="Edit">
                            <Create className={classes.ActionIcons} />
                          </Tooltip>
                        </EditBtn>
                        <DelBtn onClick={e => handleDeleteBtn(e, profile.id)} >
                          <Tooltip title="Delete">
                            <DeleteOutlined className={classes.ActionIcons} />
                          </Tooltip>
                        </DelBtn>
                      </Actions>
                    </Item>
                  </Link>
                ))}
              </CollectionsWrap>
            :
            <NoContentText>
              No item found
            </NoContentText>
            }
          </Body>
          <DeleteBusiness
            deleteId={deleteId}
            isDeleteOpen={isDeleteOpen}
            setIsDeleteOpen={setIsDeleteOpen}
            CloseDeleteModal={CloseDeleteModal}
          />
        </Wrapper>
        { responseLoader.active == true ?
            <ResponseLoader />
            :
            <></>
        }
    </Container>
  )
}
