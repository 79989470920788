import styled from 'styled-components';
import {theme_colors} from '../../data';
import { mobile, tablet } from '../../responsive';



const Container = styled.div`
    width: calc(100% - 200px);
    margin-left: 200px;
    padding: 10px;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        padding: "10px 15px"
    })}
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
const FooterText = styled.div`
    color: #00000060;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
`;
export default function Footer() {

  
  return (
    <Container>
      <Wrapper>
        <FooterText>&copy; {new Date().getFullYear()} LISAFOLAWIYO</FooterText>
      </Wrapper>

    </Container>
  );
}
