import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import { Link, useNavigate, useLocation } from "react-router-dom";
import {publicRequest , BASE_URL} from "../../requestMethods";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { updateResponse, StartLoader, StopLoader } from '../../redux/responseRedux';
import { AddProduct as AddToProducts } from '../../redux/productRedux';
import { 
  Create,
  AddBoxOutlined,
  DeleteOutlined,
  PhotoCamera,
  List,
  AddAPhoto
  } from "@material-ui/icons";
import "../../assets/css/form.css";
import useUserRequest from '../../hooks/useUserRequest';
import ResponseLoader from '../../components/generic/ResponseLoader';



const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    margin-left: 200px;
    padding: 60px 60px;
    background-color: #e2e2e2;
    ${tablet({
        padding: "20px 15px"
    })}  
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "20px 0px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;
    ${mobile({
        marginTop: "40px"
    })}  
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none",
      padding: "10px 15px 20px 15px"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    ${mobile({
        fontSize: "23px"
    })}
`;
const ProgressBarWrap = styled.div`
    width: 100%;
    background-color: #d8d8d8;
    border-bottom: 1px solid #61616140;
    padding: 0px 20px;
    ${tablet({
        padding: "0px 15px",
        height: "63px"
    })} 
`;
const ProgressBar = styled.div`
    width: 100%;
    display: flex;
    ${tablet({
      display: "block",
      position: "relative"
    })}
`;

const ProgressBarItem = styled.div`
    width: ${props=>props.width}%;
    //border: 1px solid #000;
    @media (max-width: 1024px) {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        //transform: ${props=>props.dragging === true ? "translateX(0%)" : "translateX(-101%)"};
    }
`;
const ProgressBarText = styled.div`
    width: max-content;
    transition: all 0.3s ease;
    color: ${props=>props.active === true ? "#0000FD" : "#7e7e7e"};
    font-size: 14px;
    font-weight: ${props=>props.active === true ? "600" : "300"};
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 20px 0px;
    border-bottom: ${props=>props.active === true ? "2px solid #0000FD" : "none"};
    @media (max-width: 1024px) {
        opacity:  ${props=>props.active === true ? 1 : 0};
    }
`;
const FormContainer = styled.div`
    width: 300%;
    margin-top: 30px;
    display: flex;
    transition: all 0.3s ease;
    transform: translateX(${props=>props.xValue}%);
    ${tablet({
        //marginTop: "100px"
    })}
`;
const FormSectionA = styled.div`
    width: 100%;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;
const FormSectionB = styled.div`
    width: 100%;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;
const FormSectionC = styled.div`
    width: 100%;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;
const FormItem = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    ${tablet({
        flexDirection: "column"
    })}  
`;
const Left = styled.div`
    width: 35%;
    margin-right: 5%;
    ${tablet({
        width: "100%",
        marginRight: "0%",
        marginBottom: "10px"
    })}  
`;
const LabelDesc = styled.div`
    width: 80%;
    margin-top: 5px;
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 300;
`;


const Right = styled.div`
    width: 60%;
    ${tablet({
        width: "100%"
    })}  
`;
const InputItem = styled.div`

`;
const LinkSamleText = styled.div`
    color: #a8a8a8;
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
`;
const ErrorMsg = styled.div`
    color: #f53b3b;
    font-size: 16px;
    font-weight: 300;
    margin-top: 5px;
`;
const CtaWrap = styled.div`
    width: 100%;
    margin-top: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${tablet({
        width: "calc(100% - 20px)"
    })}  
`;
const BtnWrap = styled.div`
    display: flex;
    width: max-content;
    ${mobile({
        width: "100%",
        justifyContent: "space-between"
    })} 
`;
const CancelBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    margin-right: 20px;
    cursor: pointer;
`;
const CancelBtnText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
`;
const ContinueBtnText = styled.div`
    color: #B8F904;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;
const ContinueBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    background-color: #000;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #B8F904;
      ${ContinueBtnText} {
        color: #000;
      }
    }
    ${mobile({
        padding: "15px 30px",
    })} 
`;


const MainImgWrap = styled.div`
    width: 100%;
    display: flex;
    ${mobile({ 
      flexDirection: "column"
    })}
`;
const AddPhotoBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 5px 8px;
    background-color: #f0f1f5;
    border-radius: 7px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #e9e6e6;
    }
    ${mobile({
      bottom: "5px",
      left: "5px"
    })}
`;
const AddPhotoText = styled.div`
    color: #000;
    font-size: 13px;
    font-weight: 400;
    text-transform: capitalize;
    margin-top: 3px;
    ${mobile({
      fontSize: "11px"
    })}
`;
const ColorSamplePhoto = styled.div`
    width: 150px;
    height: 150px;
    background-color: #6e6e6e;
    background-size: cover;
    background-position: center;
    position: relative;
    /* z-index: 2; */
    cursor: pointer;
`;
const CoverPhoto = styled.div`
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #6e6e6e;
    background-size: cover;
    background-position: center;
    position: relative;
    /* z-index: 2; */
    cursor: pointer;
    &:before {
        float: left;
        padding-top: 100%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
`;
const AltImages = styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    ${mobile({ 
      width: "100%",
      flexDirection: "row",
      marginTop: "10px",
      justifyContent: "space-between"
    })}
`;
const AltPhoto = styled.div`
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #9b9999;
    background-size: cover;
    background-position: center;
    position: relative;
    /* z-index: 2; */
    cursor: pointer;
    &:before {
        float: left;
        padding-top: 100%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
`;

/// SubItem ///

const SubItemWrap = styled.div`
    width: 100%;
`;
const SubItem = styled.div`
    width: 100%;
    background-color: #f3f3f3;
    padding: 20px;
    margin-bottom: 10px;
`;
const SubItemBody = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;
const SubItemLabel = styled.div`
    width: 100%;
`;
const SubItemValue = styled.div`
    width: 100%;
`;
const SubCta = styled.div`
    width: 100%;
    margin-top: 20px;
`;
const SubCtaWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

`;
const SubRemoveBtn = styled.div`
    width: max-content;
    border: 1px solid #000;
    padding: 5px 15px;
    cursor: pointer;
`;
const SubRemoveText = styled.div`
    color: #000;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
`;

const SubAddBtnWrap = styled.div`
    width: 100%;
    margin-top: 20px;
`;
const SubAddBtn = styled.div`
    width: max-content;
    background-color: blue;
    padding: 5px 15px;
    cursor: pointer;
`;
const SubAddBtnText = styled.div`
    color: #000;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
`;
const SubImageWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

`;
const SubImageItem = styled.div`
    width: 30%;
`;


const PriceSection = styled.div`
    width: 100%;
    display: flex;

`;
const Currency = styled.div`
    width: 80px;
    height: 40px;
    background-color: #eeeeee;
    border: 1px solid #bdbdbd;
    padding: 0px 10px;
    display: flex;
    align-items: center;
`;
const CurrencyText = styled.div`
    color: #000000;
    font-size: 16px;
    text-transform: uppercase;
`;
const PriceInput = styled.div`
    width: calc(100% - 80px);
`;



const useStyles = makeStyles({
    AddBgImgIcon: {
      color: "#000",
      marginRight: 4,
      fontSize: 16,
      '@media (max-width: 768px)': {
        fontSize: 14,
      }
    },
    ProductIcon: {
        color: "#fff",
        marginRight: 4,
        fontSize: 18
      },
  })

export default function AddProduct() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const user = useSelector((state) => state.user.currentUser);
    const collections = useSelector(state=>state.collection.collections);
    const _categories = useSelector(state=>state.category.categories);
    const productCategories = useSelector(state=>state.nonentity.productCategories);
    const currencies = useSelector(state=>state.nonentity.currencies);
    const responseLoader = useSelector(state=>state.response.loader);


    const userRequest = useUserRequest();

    /////// Progress Bar ///////////////
    const [sectionXvalue, setSectionXvalue] = useState(0);
    const [progressBar, setProgressBar] = useState({
        first: true,
        second: false,
        third: false
    });

    const handleSectionClick = section => () => {
        let bar = {
            first: true,
            second: false,
            third: false
        }
        let value = 0;

        let result = true;//isNext(section);

        if(section == "A" && result == true){
            bar = {first: true, second: false, third: false}
            value = 0;
            //value = -(100/3);
        }
        if(section == "B" && result == true){
            bar = {first: false, second: true, third: false};
            value = -(100/3);
            //value = -((2/3)*100);
        }
        if(section == "C" && result == true){
            bar = {first: false, second: false, third: true}
            value = -((2/3)*100);
        }

        setProgressBar(bar);
        setSectionXvalue(value);
        window.scrollTo(0, 0);
    };

    const isNext = (section) => {

        let error_msg = {...errors};
        let _focus = {...focus};
        let next = true;

        if (section == "B"){

            if (values.CollectionId == "" ) {
                next = false;
                error_msg = {
                    ...error_msg, 
                    ["CollectionId"]: "Select a collection"
                };
                _focus = {
                    ..._focus, 
                    ["CollectionId"]: true
                };
            }
            if (values.ProductCategoryId == "") {
                next  = false;
                error_msg = {
                    ...error_msg, 
                    ["ProductCategoryId"]: "Select a product category"
                };
                _focus = {
                    ..._focus, 
                    ["ProductCategoryId"]: true
                };
            }
            if (values.Name == "") {
                next  = false;
                error_msg = {
                    ...error_msg, 
                    ["Name"]: "Enter your collection name"
                };
                _focus = {
                    ..._focus, 
                    ["Name"]: true
                };
            }

    }

    setErrors(error_msg);
    setFocus(_focus);

    return next;

};

    ////////// End ////////////////
    
    const [collectionCatIds, setCollectionCatIds] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState({});
    const [_productCategories, setProductCategories] = useState([]);

    const [values, setValues] = useState({
        CategoryId: "",
        CollectionId: "",
        Name: "",
        Description: "",
        Categories: [],
        ShowPrice: true,
        InStock: true,
        Active: true,
    });

    const onChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        setValues({ ...values, [name]: value });
        console.log(values);
        //validateInput(name, value);
    };

    const handleFocus = (e) => {
        setFocus({ ...focus, [e.target.name]: true });
    };

    // const handleCollectionChange = event => {
    //     console.log("Event target cat id: ", collectionCatIds[event.target.value]);
    //     getSelectedCollection(event.target.value);
    //     setValues({ 
    //         ...values, 
    //         [event.target.name]: event.target.value
    //     });
    //     getProductCategories(collectionCatIds[event.target.value]);
    //     validateInput(event.target.name, event.target.value);
    //   };

    /// Categories ////
    const [categories, setCategories] = useState([""]);

    const handleAddCategory = () => {
        let abc = [...categories, []];
        setCategories(abc);
    }
    const handleCategorySave = (e,i) => {

        let value = e.target.value;
        //let name = e.target.name;

        let catArray = [...categories];
        //let catItem = {...catArray[i]};
         
        // sizeObject[name] = value;

        // sizeArray[i] = sizeObject;
        catArray.push(value);

        setCategories(catArray);
        console.log(categories);
    }

    const handleCategoryDelete=(i)=>{
        let sizeArray = [...sizes];
        if( i != 0){
            sizeArray.splice(i,1);
        }
        setSizes(sizeArray);
        console.log(sizes);
    }

    //// SIZES ////

    const [sizes, setSizes] = useState([
        {
            Name: "",
            Description: "",
        }
    ]);

    const handleAddSize = () => {
        let abc = [...sizes,{
            Name: "",
            Description: "",
        }];
        setSizes(abc);
    }
    const handleSizeChange = (e,i) => {

        let value = e.target.value;
        let name = e.target.name;

        let sizeArray = [...sizes];
        let sizeObject = {...sizeArray[i]};
         
        sizeObject[name] = value;

        sizeArray[i] = sizeObject;

        setSizes(sizeArray);
        console.log(sizes);
    }

    const handleSizeDelete=(i)=>{
        let sizeArray = [...sizes];
        if( i != 0){
            sizeArray.splice(i,1);
        }
        setSizes(sizeArray);
        console.log(sizes);
    }


    /// COLORS ///

    // const myRefs = useRef([]);

    // myRefs.current = [0,0,0].map(
    //     (ref, index) =>   myRefs.current[index] = React.createRef()
    // );

    const [imageRefs, setImageRefs] = useState([
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        },
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        },
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        },
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        },
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        }
    ]);

    // useEffect(()=>{

    // },[colors]);

    const [colors, setColors] = useState([
        {
            Name: "",
            ColorSampleImageFile: "",
            CoverImageFile: "",
            AltImage1File: "",
            AltImage2File: "",
            ColorSampleImageUrl: "",
            CoverImageUrl: "",
            AltImage1Url: "",
            AltImage2Url: "",
            PriceNGN: 0,
            PriceUSD: 0,
            PriceGBP: 0,
            PriceEUR: 0
        }
    ]);

    const handleAddColor = () => {
        let abc;
        let len = colors.length;

        abc = [...colors];
        
        if (len <= 4) {
            abc = [...colors, {
                Name: "",
                ColorSampleImageFile: "",
                CoverImageFile: "",
                AltImage1File: "",
                AltImage2File: "",
                ColorSampleImageUrl: "",
                CoverImageUrl: "",
                AltImage1Url: "",
                AltImage2Url: "",
                PriceNGN: 0,
                PriceUSD: 0,
                PriceGBP: 0,
                PriceEUR: 0
            }];
        }

        setColors(abc);
    }
    const handleColorChange = (e,i) => {

        let value = e.target.value;
        let name = e.target.name;

        let colorArray = [...colors];
        let colorObject = {...colorArray[i]};
         
        colorObject[name] = value;

        colorArray[i] = colorObject;

        setColors(colorArray);
        console.log(colors);
    }

    const handleImageClick = (ref) => {
        ref.current.click();
    };

    const handleFileChange = (event, i)=> {
        let colorArray = [...colors];
        let colorObject = {...colorArray[i]};

        let error_msg = {...errors};
        let _focus = {...focus};
        const fileObj = event.target.files && event.target.files[0];

        if (!fileObj) {
          return;
        }
        if (fileObj.type == "image/jpeg" || fileObj.type == "image/jpg" || fileObj.type == "image/png") {
            if((fileObj.size/1024)/1024 < 2.1)  {
                //console.log('fileObj is', fileObj);
                colorObject[event.target.name.concat("Url")] = URL.createObjectURL(event.target.files[0]);
                colorObject[event.target.name.concat("File")] = event.target.files[0];

                colorArray[i] = colorObject;
                setColors(colorArray);
                console.log(colors);

                // setSelectedImageUrl({...selectedImageUrl, [event.target.name]: URL.createObjectURL(event.target.files[0]) });
                // setValues({ ...values, [event.target.name]: event.target.files[0] });

                event.target.value = null;
                error_msg = {
                    ...error_msg, 
                    ["ImageFile"]: ""
                }; 
            }else{
                error_msg = {
                    ...error_msg, 
                    ["ImageFile"]: "Image size should not be more than 2mb"
                };
            }
            
        }else {
            error_msg = {
                ...error_msg, 
                ["ImageFile"]: "Invalid image format"
            };
        }  
        _focus = {
            ..._focus, 
            ["ImageFile"]: true
        };
        //setFocus(_focus);
        //setErrors(error_msg);
    };

    const handleColorDelete=(i)=>{
        let colorArray = [...colors];
        if( i != 0){
            colorArray.splice(i,1);
        }
        setColors(colorArray);
        console.log(colors);
    }


    /////////////

    const [errors, setErrors] = useState({
        CategoryId: "",
        CollectionId: "",
        Name: "",
        Description: "",
        Categories: "",
        ShowPrice: "",
        InStock: "",
        Active: "",
        ImageFile: ""
    });

    const [focus, setFocus] = useState({
        CategoryId: false,
        CollectionId: false,
        Name: false,
        Description: false,
        Categories: false,
        ShowPrice: false,
        InStock: false,
        Active: false,
        ImageFile: false
    });

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[]);

    const getSelectedCollection = async (id) => {
        collections.map(col => {
            if(col.id == id) {
                setSelectedCollection(col);
                //console.log("Selected Collection: ", col);
            }
        });
    };

    // useEffect(()=>{
    //     setValues({ ...values, ["CollectionNameUrl"]: selectedCollection.nameUrl });
    // },[selectedCollection]);

    const getProductCategories = async (id) => {
        let cats = [];
        productCategories.map(x => {
            if(x.collectionCategoryId == id) {
                cats.push(x);
            }
        });
        setProductCategories(cats);
    };

    const validateInput = (inputName, InputValue) => {
        let regex = "";

        let valid = true;

        let error_msg = {...errors};

        //// Category Ids /////
        if (inputName === "CollectionId" || inputName === "ProductCategoryId") {
            if(InputValue == "") {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Select a category"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Name /////
        if (inputName === "Name") {
            //regex = /^(?=.{3,100}$)[^\W_]+(?: [^\W_]+)*$/;
            regex = /^[A-Za-z0-9 ]{3,100}$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "character length should be between 3 and 100. Only alphabets and numbers."
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Description /////
        if (inputName === "Description") {
            //regex = /^[A-Za-z0-9 -/,.]{3,10}$/;
            regex = /^.{10,500}$/s;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "character length should be between 10 and 500"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Price /////
        if (inputName === "Price") {
            regex = /^[1-9][0-9]*$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Enter a number greater than 0"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Url Links /////
        if (inputName === "PurchaseUrl") {
            regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Enter a valid url with http protocol (http:// or https://)"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //console.log(errors);
        setErrors(error_msg);

    };

    const checkRequiredInputs = () => {
        let error_msg = {...errors};
        let _focus = {...focus};

        let result = true;

        if (values.CoverImageFile == "" ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["ImageFile"]: "Choose a cover image for this product"
            };
            _focus = {
                ..._focus, 
                ["ImageFile"]: true
            };
        }

        if (values.CollectionId == "" ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["CollectionId"]: "Select a collection"
            };
            _focus = {
                ..._focus, 
                ["CollectionId"]: true
            };
        }
        if (values.ProductCategoryId == "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["ProductCategoryId"]: "Select a product category"
            };
            _focus = {
                ..._focus, 
                ["ProductCategoryId"]: true
            };
        }
        if (values.Name == "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Name"]: "Enter your collection name"
            };
            _focus = {
                ..._focus, 
                ["Name"]: true
            };
        }

        setErrors(error_msg);
        setFocus(_focus);
        return result;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let result = true; //checkRequiredInputs();

        if(result) {
            dispatch(StartLoader());

            let formData = new FormData();


            formData.append('Name', values.Name);
            formData.append('CategoryId', values.CategoryId);
            formData.append('CollectionId', values.CollectionId);
            formData.append('Description', values.Description);
            formData.append('ShowPrice', values.ShowPrice);
            formData.append('InStock', values.InStock);
            formData.append('Active', values.Active);

            for (var i = 0; i < values.Categories.length; i++) {
                formData.append('Categories['+i+']' , values.Categories[i]);
            }
            // formData.append("assignedUsers[0].uuid", assignedUsers[0].uuid);
            // formData.append("assignedUsers[0].firstName", assignedUsers[0].firstName);

            // formData.append("assignedUsers[1].uuid", assignedUsers[1].uuid);
            // formData.append("assignedUsers[1].firstName", assignedUsers[1].firstName);

            for (var i = 0; i < sizes.length; i++) {
                formData.append('Sizes['+i+'].Name' , sizes[i].Name);
                formData.append('Sizes['+i+'].Description' , sizes[i].Description);
            }

            for (var i = 0; i < colors.length; i++) {
                formData.append('Colors['+i+'].Name' , colors[i].Name);

                formData.append('Colors['+i+'].ColorSampleImageFile' , colors[i].ColorSampleImageFile);
                formData.append('Colors['+i+'].CoverImageFile' , colors[i].CoverImageFile);
                formData.append('Colors['+i+'].AltImage1File' , colors[i].AltImage1File);
                formData.append('Colors['+i+'].AltImage2File' , colors[i].AltImage2File);

                formData.append('Colors['+i+'].PriceNGN' , parseInt(colors[i].PriceNGN));
                formData.append('Colors['+i+'].PriceUSD' , parseInt(colors[i].PriceUSD));
                formData.append('Colors['+i+'].PriceGBP' , parseInt(colors[i].PriceGBP));
                formData.append('Colors['+i+'].PriceEUR' , parseInt(colors[i].PriceEUR));
            }

    
            try {
                const res = await axios.post("http://localhost:5200/api/Product", formData);
    
                dispatch(AddToProducts(res.data));
                dispatch(updateResponse(
                    {
                        active: true,
                        success: true,
                        message: "Success: Your product was created successfully"
                    }
                ));
                dispatch(StopLoader());
                navigate("/user/products");
            } catch (err) {
                console.log(err);
                dispatch(updateResponse(
                    {
                        active: true,
                        success: false,
                        message: "Error: The operation failed. Check your internet and try again"
                    }
                ));
                dispatch(StopLoader());
                navigate("/user/products");
            }
        }else {
            window.scrollTo(0, 0);
            console.log("FORM IS NOT GOOD");
        }
    };


  return (
    <Container>
        <Wrapper>
        <Header>
            <PageTitle>new product settings</PageTitle>
        </Header>

        <ProgressBarWrap>
            <ProgressBar>
                <ProgressBarItem width={100/3}>
                    <ProgressBarText active={progressBar.first}>description</ProgressBarText>
                </ProgressBarItem>
                <ProgressBarItem width={100/3}>
                    <ProgressBarText active={progressBar.second}>sizes</ProgressBarText>
                </ProgressBarItem>
                <ProgressBarItem width={100/3}>
                    <ProgressBarText active={progressBar.third}>colors & images</ProgressBarText>
                </ProgressBarItem>
            </ProgressBar>
        </ProgressBarWrap>
        <FormContainer xValue={sectionXvalue}>
        <FormSectionA>
        <FormItem>
                <Left>
                    <label for="type">Category</label>
                    <LabelDesc>
                        Select the product type
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <select 
                            className="main_input c_input"
                            name="CategoryId" 
                            id="category_id"
                            value={values.CategoryId}
                            onChange={onChange}
                            onBlur={handleFocus}
                            >
                                <option key={0}  value="" selected>Select category</option>
                            {_categories.map(col => (
                                <option 
                                    key={col.id} 
                                    value={col.id}>
                                        {col.name}
                                </option>
                            ))}
                        </select>                
                    </InputItem>
                    <ErrorMsg>{focus.CategoryId && errors.CategoryId != "" && errors.CategoryId}</ErrorMsg>
                </Right>
            </FormItem>
            <FormItem>
                <Left>
                    <label for="type">Collection</label>
                    <LabelDesc>
                        Select the collection of for product
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <select 
                            className="main_input c_input"
                            name="CollectionId" 
                            id="collection_id"
                            value={values.CollectionId}
                            onChange={onChange}
                            onBlur={handleFocus}
                            >
                                <option key={0}  value="" selected>Select collection</option>
                            {collections.map(col => (
                                <option 
                                    key={col.id} 
                                    value={col.id}>
                                        {col.name}
                                </option>
                            ))}
                        </select>                
                    </InputItem>
                    <ErrorMsg>{focus.CollectionId && errors.CollectionId != "" && errors.CollectionId}</ErrorMsg>
                </Right>
            </FormItem>
            <FormItem>
                <Left>
                    <label for="Name">Name</label>
                    <LabelDesc>
                        Enter the name of your product
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <input
                            className="main_input c_input"
                            type="text" 
                            name="Name"
                            onChange={onChange}
                            onBlur={handleFocus}
                            placeholder=''
                            required />              
                    </InputItem>
                    <ErrorMsg>{focus.Name && errors.Name != "" && errors.Name}</ErrorMsg>
                </Right>
            </FormItem>
            <FormItem>
                <Left>
                    <label for="desc_id">Description</label>
                    <LabelDesc>
                        Describe your product in less than 500 words
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <textarea
                            className=""
                            id="desc_id"
                            rows="5"
                            name="Description"
                            onBlur={handleFocus}
                            onChange={onChange}
                            placeholder=''
                            />             
                    </InputItem>
                    <ErrorMsg>{focus.Description && errors.Description != "" && errors.Description}</ErrorMsg>
                </Right>
            </FormItem>
            {/* <FormItem>
                <Left>
                    <label for="Categories">Categories</label>
                    <LabelDesc>
                        Add Categories
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <SubItemWrap>
                        {categories.map((data,i)=>{
                            return(
                                <SubItem key={i}>
                                    <SubItemBody>
                                        <SubItemLabel>
                                            <label for="Name">Name:</label>
                                        </SubItemLabel>
                                        <SubItemValue>
                                            <input
                                                className="main_input c_input"
                                                type="text" 
                                                name="Name"
                                                placeholder=''
                                                value={data}
                                                onChange={e=>handleCategoryChange(e,i)}
                                                required />  
                                        </SubItemValue>
                                    </SubItemBody>
                                <SubCta>
                                    <SubCtaWrap>
                                        <SubRemoveBtn onClick={()=>handleCategorySave(i)}>
                                            <SubRemoveText>Save</SubRemoveText>
                                        </SubRemoveBtn>
                                        <SubRemoveBtn onClick={()=>handleCategoryDelete(i)}>
                                            <SubRemoveText>Remove item</SubRemoveText>
                                        </SubRemoveBtn>
                                    </SubCtaWrap>
                                </SubCta>
                            </SubItem>
                            )
                        })}                         
                            <SubAddBtnWrap>
                                <SubAddBtn onClick={()=>handleAddCategory()}>
                                    <SubAddBtnText>Add category</SubAddBtnText>
                                </SubAddBtn>
                            </SubAddBtnWrap>
                        </SubItemWrap>           
                    </InputItem>
                    <ErrorMsg>{focus.Name && errors.Name != "" && errors.Name}</ErrorMsg>
                </Right>
            </FormItem> */}
            <CtaWrap>
                <BtnWrap>
                    <Link to={`/user/products`} style={{ textDecoration: 'none' }} >
                        <CancelBtn>
                            <CancelBtnText>cancel</CancelBtnText>
                        </CancelBtn>
                    </Link>
                    <ContinueBtn onClick={handleSectionClick("B")}>
                        <ContinueBtnText>continue</ContinueBtnText>
                    </ContinueBtn>
                </BtnWrap>
            </CtaWrap>
        </FormSectionA>
        <FormSectionB>
            <FormItem>
                <Left>
                    <label for="Size">Sizes</label>
                    <LabelDesc>
                        Add available sizes for this product
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <SubItemWrap>
                        {sizes.map((data,i)=>{
                            return(
                                <SubItem key={i}>
                                    <SubItemBody>
                                        <SubItemLabel>
                                            <label for="Name">Name:</label>
                                        </SubItemLabel>
                                        <SubItemValue>
                                            <input
                                                className="main_input c_input"
                                                type="text" 
                                                name="Name"
                                                placeholder=''
                                                value={data.Name}
                                                onChange={e=>handleSizeChange(e,i)}
                                                required />  
                                        </SubItemValue>
                                    </SubItemBody>
                                    <SubItemBody>
                                        <SubItemLabel>
                                            <label for="Name">Description:</label>
                                        </SubItemLabel>
                                        <SubItemValue>
                                            <input
                                                className="main_input c_input"
                                                type="text" 
                                                name="Description"
                                                placeholder=''
                                                value={data.Description}
                                                onChange={e=>handleSizeChange(e,i)}
                                                required />  
                                        </SubItemValue>
                                    </SubItemBody>
                                <SubCta>
                                    <SubCtaWrap>
                                        <SubRemoveBtn onClick={()=>handleSizeDelete(i)}>
                                            <SubRemoveText>Remove item</SubRemoveText>
                                        </SubRemoveBtn>
                                    </SubCtaWrap>
                                </SubCta>
                            </SubItem>
                            )
                        })}
                           
                            <SubAddBtnWrap>
                                <SubAddBtn onClick={()=>handleAddSize()}>
                                    <SubAddBtnText>Add size</SubAddBtnText>
                                </SubAddBtn>
                            </SubAddBtnWrap>
                        </SubItemWrap>           
                    </InputItem>
                    <ErrorMsg>{focus.Name && errors.Name != "" && errors.Name}</ErrorMsg>
                </Right>
            </FormItem>
            <CtaWrap>
                <BtnWrap>
                        <CancelBtn onClick={handleSectionClick("A")}>
                            <CancelBtnText>cancel</CancelBtnText>
                        </CancelBtn>
                    <ContinueBtn onClick={handleSectionClick("C")}>
                        <ContinueBtnText>continue</ContinueBtnText>
                    </ContinueBtn>
                </BtnWrap>
            </CtaWrap>
    </FormSectionB>
    <FormSectionC>
        <FormItem>
                <Left>
                    <label for="Name">Colors</label>
                    <LabelDesc>
                        Add available colors for this product
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <SubItemWrap>
                        {colors.map((data,i)=>{ 
                            return (
                                <SubItem key={i}>
                                <SubItemBody>
                                    <SubItemLabel>
                                        <label for="Name">Color name:</label>
                                    </SubItemLabel>
                                    <SubItemValue>
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="Name"
                                            placeholder=''
                                            value={data.Name}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />  
                                    </SubItemValue>
                                </SubItemBody>
                                <SubItemBody>
                                    <SubItemLabel>
                                        <label for="Name">Upload Color Sample Image:</label>
                                    </SubItemLabel>
                                    <SubItemValue>
                                        <ColorSamplePhoto style={{ backgroundImage: `url(${data.ColorSampleImageUrl})`}}>
                                            <AddPhotoBtn onClick={()=>handleImageClick(imageRefs[i][3])}>
                                                <AddAPhoto className={classes.AddBgImgIcon} />
                                                <AddPhotoText>color sample photo</AddPhotoText>
                                            </AddPhotoBtn>
                                        </ColorSamplePhoto>
                                    </SubItemValue>
                                </SubItemBody>
                                <SubItemBody>
                                    <SubItemLabel>
                                        <label for="Name">images:</label>
                                    </SubItemLabel>
                                    <SubItemValue>
                                        <SubImageWrap>
                                            <SubImageItem>
                                                <CoverPhoto style={{ backgroundImage: `url(${data.CoverImageUrl})`}}>
                                                    <AddPhotoBtn onClick={()=>handleImageClick(imageRefs[i][0])}>
                                                        <AddAPhoto className={classes.AddBgImgIcon} />
                                                        <AddPhotoText>cover photo</AddPhotoText>
                                                    </AddPhotoBtn>
                                                </CoverPhoto>
                                            </SubImageItem>
                                            <SubImageItem>
                                                <AltPhoto style={{ backgroundImage: `url(${data.AltImage1Url})`}}>
                                                    <AddPhotoBtn onClick={()=>handleImageClick(imageRefs[i][1])}>
                                                        <AddAPhoto className={classes.AddBgImgIcon} />
                                                        <AddPhotoText>alt photo</AddPhotoText>
                                                    </AddPhotoBtn>
                                                </AltPhoto>
                                            </SubImageItem>
                                            <SubImageItem>
                                                <AltPhoto style={{ backgroundImage: `url(${data.AltImage2Url})`}}>
                                                    <AddPhotoBtn onClick={()=>handleImageClick(imageRefs[i][2])}>
                                                        <AddAPhoto className={classes.AddBgImgIcon} />
                                                        <AddPhotoText>alt photo</AddPhotoText>
                                                    </AddPhotoBtn>
                                                </AltPhoto>
                                            </SubImageItem>
                                        </SubImageWrap>
                                        <input
                                            ref={imageRefs[i][0]}
                                            type="file"
                                            name="CoverImage"
                                            style={{display: 'none'}}
                                            onChange={e=>handleFileChange(e,i)}
                                            />
                                        <input
                                            ref={imageRefs[i][1]}
                                            type="file"
                                            name="AltImage1"
                                            style={{display: 'none'}}
                                            onChange={e=>handleFileChange(e,i)}
                                            />
                                        <input
                                            ref={imageRefs[i][2]}
                                            type="file"
                                            name="AltImage2"
                                            style={{display: 'none'}}
                                            onChange={e=>handleFileChange(e,i)}
                                            /> 
                                        <input
                                            ref={imageRefs[i][3]}
                                            type="file"
                                            name="ColorSampleImage"
                                            style={{display: 'none'}}
                                            onChange={e=>handleFileChange(e,i)}
                                            /> 
                                    </SubItemValue>
                                </SubItemBody>
                                <SubItemBody>
                                    <SubItemLabel>
                                        <label for="Name">Prices:</label>
                                    </SubItemLabel>
                                    <SubItemValue>
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="PriceNGN"
                                            placeholder='NGN'
                                            value={data.PriceNGN}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="PriceUSD"
                                            placeholder='USD'
                                            value={data.PriceUSD}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="PriceGBP"
                                            placeholder='GBP'
                                            value={data.PriceGBP}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="PriceEUR"
                                            placeholder='EUR'
                                            value={data.PriceEUR}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />  
                                    </SubItemValue>
                                </SubItemBody>
                                <SubCta>
                                    <SubCtaWrap>
                                        <SubRemoveBtn onClick={()=>handleColorDelete(i)}>
                                            <SubRemoveText>Remove item</SubRemoveText>
                                        </SubRemoveBtn>
                                    </SubCtaWrap>
                                </SubCta>
                            </SubItem>
                            )})}
                            <SubAddBtnWrap>
                                <SubAddBtn onClick={()=>handleAddColor()}>
                                    <SubAddBtnText>Add item</SubAddBtnText>
                                </SubAddBtn>
                            </SubAddBtnWrap>
                        </SubItemWrap>           
                    </InputItem>
                    <ErrorMsg>{focus.Name && errors.Name != "" && errors.Name}</ErrorMsg>
                </Right>
            </FormItem>
            <CtaWrap>
                <BtnWrap>
                    <CancelBtn onClick={handleSectionClick("B")}>
                        <CancelBtnText>back</CancelBtnText>
                    </CancelBtn>
                    <ContinueBtn onClick={handleSubmit}>
                        <ContinueBtnText>create product</ContinueBtnText>
                    </ContinueBtn>
                </BtnWrap>
            </CtaWrap>
        </FormSectionC>
        </FormContainer>
        </Wrapper>
        {responseLoader.active == true ?
            <ResponseLoader />
            :
            <></>
        }
    </Container>
  )
}
