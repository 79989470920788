import React from 'react';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { mobile, tablet } from '../responsive';
import { publicRequest } from "../requestMethods";


import { makeStyles } from '@material-ui/core/styles';
import { 
    VisibilityOutlined,
    VisibilityOffOutlined,
  } from "@material-ui/icons";
import { Link } from "react-router-dom";

import "../assets/css/mainStyle.css";
import BgImage from '../assets/images/main_bg.jpg';
import LogoMain from '../assets/images/Store-Manager-Logo.png';


const Container = styled.div`
    width: 100%;
    height: 100vh;
  ${mobile({
      height: "auto",
      padding: "50px 15px"
  })}  
`;
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    ${mobile({
      height: "auto",  
      marginBottom: "30px"
  })}  
`;
const Left = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${mobile({
        width: "100%",
        height: "auto",
        flexDirection: "column",
        justifyContent: "flex-start",
    })}  
`;
const LeftWrap = styled.div`
    width: 80%;
    max-width: 400px;
    overflow: hidden;
    //border: 1px solid #000;
    ${mobile({
        width: "100%"
    })} 
`;
const SectionWrap = styled.div`
    width: 300%;
    display: flex;
    transition: all 0.3s ease;
    transform: translateX(${props=>props.xValue}%);
`;
const Section = styled.div`
    width: 100%;
`;
const Logo = styled.img`
    width: 170px;
`;
const LogoDesc = styled.div`
    color: #6e6e6e;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 40px;
`;
const ServerResponse = styled.div`
    color: #000;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 20px;
    background-color: #fad7d7;
    margin-bottom: 10px;
    display: ${props=>props.error === false ? "none" : "block"};
`;
const FormWrap = styled.div`
    width: 100%;
    ${mobile({
        //marginTop: "50px"
    })} 
`;
const FormGroup = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    //border: 1px solid #000;
    ${mobile({
        flexDirection: "column",
        marginBottom: "0px"
    })}  
`;
const FormInput = styled.div`
    width: calc( 50% - 5px);
    display: flex;
    flex-direction: column;
    //margin-bottom: 20px;
    ${mobile({
        width: "100%",
        marginBottom: "20px"
    })}  
`;
const PasswordWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
const VisibilityIconWrap = styled.div`
    width: 40px;
    height: 30px;
    background-color: #eeeeee;
    border-left: none;
    border-right: 1px solid #bdbdbd;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const Error = styled.div`
    color: red;
    font-size: 14px;
    font-weight: 300;
`;
const CheckBoxWrap = styled.div`
    margin-bottom: 30px;
`;
const CBHolder = styled.div`
    display: flex;
    align-items: flex-start;
`;
const Terms = styled.div`
    color: #8d8d8d;
    font-size: 13px;
    font-weight: 300;
`;
const SubmitBtnText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;

const SubmitBtn = styled.div`
    width: 100%;
    height: 40px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #ec7fbb;
        ${SubmitBtnText} {
            color: #000;
        }
        }
    ${mobile({
        height: "40px"
    })}  
`;


const ErrorNotice = styled.div`
    width: 80%;
    height: 40px;
    background-color: aquamarine;
    position: absolute;
    top: 0px;
    display: none;
    //max-width: 400px;
    ${mobile({
        width: "100%"
    })} 
`;

////// Section B
const Loader = styled.div`
    width: 100%;
    height: 100%;
    //background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
`;

/////// Section C
const Confirmation = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
`;

const ConfirmHead = styled.div`
    color: #000;
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
`;
const ConfirmSub = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 10px;
`;
const ConfirmText = styled.div`
    color: #6b6b6b;
    font-size: 16px;
    font-weight: 300;
`;



const Right = styled.div`
    width: 40%;
    height: 100%;
    background-color: #a7a7a7;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-position: center;
    ${mobile({
      display: "none"
    })}  
`;

const RightWrap = styled.div`
    width: 100%;
    margin: 0px 20px 40px 20px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
`;
const Top = styled.div`
    width: 100%;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
`;

const Bottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
const BottomLeft = styled.div`
    width: calc(50% - 10px);
`;
const RefName = styled.div`
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    //margin-bottom: 5px;
`;
const RefJob = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    //margin-bottom: 5px;
`;
const RefDesc = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    text-transform: capitalize;
`;
const BottomRight = styled.div`
    width: calc(50% - 10px);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;
const Stars = styled.div`
    width: 70px;
`;
const Star = styled.img`
    width: 100%;
`;




  const useStyles = makeStyles({
    ActionIcons: {
        color: "#00000060",
        fontSize: 18,
        cursor: "pointer",
        '@media (max-width: 768px)': {
          fontSize: 22,
        }
    },
    AddIcon: {
      color: "#000",
      fontSize: 35
    },
    SortIcon: {
      color: "#fff",
      fontSize: 15
    },
    ShowIcon: {
        color: "#9e9e9e",
        fontSize: 16
    },
  })

export default function Register() {
    const classes = useStyles();


    const navigate = useNavigate();

    const [serverResponse, setServerResponse,] = useState({
        error: false,
        message: ""
    });

    const [sectionXvalue, setSectionXvalue] = useState(0);

    const [values, setValues] = useState({
        Username: "",
        Email: "",
        Password: "",
        RepeatPassword: "",
        Terms: false
    });

    const [errors, setErrors] = useState({
        Username: "",
        Email: "",
        Password: "",
        RepeatPassword: "",
        Terms: ""
    });

    const [focus, setFocus] = useState({
        Username: false,
        Email: false,
        Password: false,
        RepeatPassword: false
    });

    
    //// Password Visibility///
    const [passwordShown, setPasswordShown] = useState(false);
    const [repeatPassShown, setRepeatPassShown] = useState(false);

    const togglePassword = (type) => {
            setPasswordShown(!passwordShown);
            setRepeatPassShown(!repeatPassShown);
      };

    const onChange = (e) => {
        setServerResponse({
            error: false,
            message: ""
        })
        setValues({ ...values, [e.target.name]: e.target.value });
        validateInput(e.target.name, e.target.value);
    };

    const handleCheckBox = (e) => {
        setValues({ ...values, [e.target.name]: e.target.checked });
    };

    
    const handleFocus = (e) => {
        setFocus({ ...focus, [e.target.name]: true });
    };

    const validateInput = (inputName, InputValue) => {
        let regex = "";

        let valid = true;

        let error_msg = {...errors};

        ///UserName ////

        if (inputName == "Username") {
            regex = /^[A-Za-z0-9-_.]{6,}$/;
            valid = regex.test(InputValue);

            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Username must be atleast 6 characters long with no whitespace"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Email /////
        if (inputName === "Email") {
            regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Enter a valid email address"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        if (inputName == "Password") {
            regex = /^[^\s]{8,}$/;
            valid = regex.test(InputValue);

            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Password must have no whitespace and atleast 8 characters long"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        if (inputName == "RepeatPassword") {
            regex = /^[^\s]{8,}$/;
            valid = regex.test(InputValue);

            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Password must have no whitespace and atleast 8 characters long"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        console.log(errors);
        setErrors(error_msg);

    };

    const checkRequiredInputs = () => {
        let error_msg = {...errors};
        let _focus = {...focus};

        let result = true;

        if (values.Username == "" || errors.Username != "" ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Username"]: "Enter a valid email"
            };
            _focus = {
                ..._focus, 
                ["Username"]: true
            };
        }
        if (values.Email == "" || errors.Email != "" ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Email"]: "Enter a valid email"
            };
            _focus = {
                ..._focus, 
                ["Email"]: true
            };
        }
        if (values.Password == "" || errors.Password != "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Password"]: "Password must have no whitespace and atleast 8 characters long"
            };
            _focus = {
                ..._focus, 
                ["Password"]: true
            };
        }
        if (values.RepeatPassword != values.Password) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Password"]: "Your passwords do not match"
            };
            _focus = {
                ..._focus, 
                ["Password"]: true
            };
        }
        if (values.RepeatPassword == "" || errors.RepeatPassword != "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["RepeatPassword"]: "Password must have no whitespace and atleast 8 characters long"
            };
            _focus = {
                ..._focus, 
                ["RepeatPassword"]: true
            };
        }

        if (values.Terms == false ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Terms"]: "You must accept the terms of service and privacy policy to sign up."
            };
        }


        setErrors(error_msg);
        setFocus(_focus);
        return result;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        let result = checkRequiredInputs();
        if(result) {
            setSectionXvalue(-(100/3));

            let registerDto = {
                Username: values.Username,
                Email: values.Email,
                Password: values.Password,
                RepeatPassword: values.RepeatPassword
            }

            try {
                const res = await publicRequest.post("/Authentication/register", registerDto);
                console.log(res.data);
                console.log("Registration successfull");
                setSectionXvalue(-((2/3)*100));
 
            } catch (err) {
                let _response = {};
                if(err.response) {
                    _response = err.response.data.errors;
                }else {
                    _response = "No connection"
                }

                setServerResponse({
                    error: true,
                    message: _response
                })
                setSectionXvalue(0);
            }
        }

    };


  return (
    <Container>
        <Wrapper>
            <Left>
                <LeftWrap>
                    <SectionWrap xValue={sectionXvalue}>
                        <Section>
                            <Logo src={LogoMain} />
                            <LogoDesc></LogoDesc>
                            <ServerResponse error={serverResponse.error}>
                                {serverResponse.message}
                            </ServerResponse>
                            <FormWrap>
                                <FormGroup>
                                    <FormInput>
                                        <label className="register_labels">username</label>
                                        <input
                                            className="register_inputs"
                                            type="text"
                                            name="Username"
                                            onChange={onChange}
                                            onBlur={handleFocus}
                                            placeholder=''
                                            required />
                                        <Error>{focus.Username && errors.Username != "" && errors.Username}</Error>
                                    </FormInput>
                                    <FormInput>
                                        <label className="register_labels">email</label>
                                        <input
                                            className="register_inputs"
                                            type="text"
                                            name="Email"
                                            onChange={onChange}
                                            onBlur={handleFocus}
                                            placeholder=''
                                            required />
                                        <Error>{focus.Email && errors.Email != "" && errors.Email}</Error>
                                    </FormInput>
                                </FormGroup>
                                <FormGroup>
                                    <FormInput>
                                        <label className="register_labels">password</label>
                                        <PasswordWrap>
                                            <input
                                                className="register_password_inputs"
                                                type={passwordShown ? "text" : "password"}
                                                name="Password"
                                                onChange={onChange}
                                                onBlur={handleFocus}
                                                placeholder=''
                                                required />
                                                <VisibilityIconWrap onClick={togglePassword}>
                                                {
                                                    passwordShown == true ?
                                                        <VisibilityOutlined className={classes.ShowIcon} />
                                                        :
                                                        <VisibilityOffOutlined className={classes.ShowIcon} />
                                                }
                                                </VisibilityIconWrap>
                                        </PasswordWrap>
                                        <Error>{focus.Password && errors.Password != "" && errors.Password}</Error>
                                    </FormInput>
                                    <FormInput>
                                        <label className="register_labels">re-type password</label>
                                        <PasswordWrap>
                                            <input
                                                className="register_password_inputs"
                                                type={repeatPassShown ? "text" : "password"}
                                                name="RepeatPassword"
                                                onChange={onChange}
                                                onBlur={handleFocus}
                                                placeholder=''
                                                required />
                                                <VisibilityIconWrap onClick={togglePassword}>
                                                {
                                                    passwordShown == true ?
                                                        <VisibilityOutlined className={classes.ShowIcon} />
                                                        :
                                                        <VisibilityOffOutlined className={classes.ShowIcon} />
                                                }
                                                </VisibilityIconWrap>
                                        </PasswordWrap>
                                        <Error>{focus.RepeatPassword && errors.RepeatPassword != "" && errors.RepeatPassword}</Error>
                                    </FormInput>
                                </FormGroup>
                                <CheckBoxWrap>
                                    <CBHolder>
                                        <input 
                                            type="checkbox" 
                                            name="Terms"
                                            onChange={handleCheckBox}
                                            required
                                        />
                                        <Terms>I acknowledge that I have read and understood the terms stated the Ahyam Group <Link to={`/terms`} style={{ textDecoration: 'none'}}><span className="terms">Terms and Conditions</span></Link></Terms>
                                    </CBHolder>
                                    <Error>{errors.Terms != "" && errors.Terms}</Error>
                                </CheckBoxWrap>
                                <SubmitBtn onClick={handleSubmit}>
                                    <SubmitBtnText>Register</SubmitBtnText>
                                </SubmitBtn>
                            </FormWrap>
                            <ErrorNotice></ErrorNotice>
                        </Section>
                        <Section>
                            <Loader>
                                <span className="loader"></span>
                            </Loader>
                        </Section>
                        <Section>
                            <Confirmation>
                                <ConfirmHead>Just one more step...</ConfirmHead>
                                <ConfirmSub>Verify your email</ConfirmSub>
                                <ConfirmText>
                                    A verification link was sent to your email.<br /> Follow the link provided to complete
                                    your registration.
                                </ConfirmText>
                            </Confirmation>
                        </Section>
                    </SectionWrap>
                </LeftWrap>
            </Left>
            <Right style={{ backgroundImage: `url(${BgImage})`}} >
                {/* <div className='right_wrap'>
                    <Top>
                        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                    </Top>
                    <Bottom>
                        <BottomLeft>
                            <RefName>Andi lane</RefName>
                            <RefJob>Founder, catalog</RefJob>
                            <RefDesc>web design agency</RefDesc>
                        </BottomLeft>
                        <BottomRight>
                            <Stars>
                                <Star src={StarsImg}/>
                            </Stars>
                        </BottomRight>
                    </Bottom>
                </div> */}
            </Right>
        </Wrapper>
    </Container>
  )
}
