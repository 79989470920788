import React, { useState } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Create,
  AddBoxOutlined,
  DeleteOutlined,
  ChevronRight
  } from "@material-ui/icons";
import { Link } from "react-router-dom";


const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    margin-left: 200px;
    padding: 40px;
    background-color: #e2e2e2;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "10px 15px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
`;
const AddBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5e68f5;
    padding: 10px 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #9299fc;
    }
`;
const AddBtnText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-top: 2px;
    text-transform: capitalize;
`;
const Body = styled.div`
    /* position: relative; */
    width: 100%;
`;
const CollectionsWrap = styled.div`
    width: 100%;
`;
const Item = styled.div`
    width: 100%;
    background-color: #ffffff;
    padding: 20px 20px;
    margin-bottom: 10px;
    display: flex;
    transition: all 0.3s ease;
    background-color: ${props=>props.open === false && "#e9faf3"};
    cursor: pointer;
    &:hover {
      background-color: #ececeb;
      }
    ${mobile({
        padding: "15px 10px",
        marginBottom: "10px"
    })}
`;
const Image = styled.div`
  width: calc(100% - 170px);
  margin-right: 20px;
  display: flex;
  ${mobile({
    width: "calc(100% - 110px)",
    marginRight: "10px",
    flexDirection: "column"
  })}
`;
const From = styled.div`
    display: flex;
    flex-direction: column;
    width:  calc(35% - 20px);
    margin-right: 20px;
    ${mobile({
        width: "100%",
        marginRight: "0px",
    })}
`;
const FromName = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
    margin-bottom: 5px;
    ${mobile({
        display: "none"
    })}
`;
const FromTitle = styled.div`
    color: #00000060;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;
    ${mobile({
        color: "#000000",
        fontWeight: "700",
        marginBottom: "5px"
    })}
`;

const SubjectBody = styled.div`
    width:  60%;
    display: flex;
    flex-direction: column;
    ${mobile({
        width: "100%"
    })}
`;
const Subject = styled.div`
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 5px;
    ${mobile({
        fontWeight: "400"
    })}
`;
const SubjectShort = styled.div`
    color: #00000060;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    ${mobile({
        display: "none"
    })}
`;
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 150px;
  ${mobile({
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    width: "100px"
  })}
`;
const Date = styled.div`
  width: 100px;
  color: #00000060;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
  text-transform: capitalize;
  ${mobile({
        marginRight: "0px",
        marginBottom: "20px",
        textAlign: "right"
    })}
`;
const DelBtn = styled.div`
  /* width: 30px;
  height: 30px; */
  display: flex;
  /* background-color: #f89a89; */
  /* justify-content: center;
  align-items: center; */
  transition: all 0.3s ease;
  cursor: pointer;
  /* &:hover {
    background-color: #f8755e;
    } */
`;

const Pagination = styled.div`
    width: 100%;
    height: 40px;
    background-color: #f1f2f3;
`;


const useStyles = makeStyles({
  ActionIcons: {
      color: "#00000050",
      fontSize: 20
  },
  AddIcon: {
    color: "#fff",
    marginRight: 4,
    fontSize: 16
  },
  SortIcon: {
    color: "#fff",
    fontSize: 15
  },
})

export default function Messages() {
  const classes = useStyles();

  return (
    <Container>
        <Wrapper>
          <Header>
            <PageTitle>Inbox</PageTitle>
            {/* <Link to={`/user/collection/add`} style={{ textDecoration: 'none' }}>
              <AddBtn>
                <AddBoxOutlined className={classes.AddIcon}/>
                <AddBtnText>Add collection</AddBtnText>
              </AddBtn>
            </Link> */}
          </Header>
          <Body>
            <CollectionsWrap>
                <Link to={`/user/message`} style={{ textDecoration: 'none' }}>
                    <Item open={false}>
                    <Image>
                        <From>
                            <FromName>Obasa tunde</FromName>
                            <FromTitle>MyCatalog Customer care</FromTitle>
                        </From>
                        <SubjectBody>
                            <Subject>Upgrade your account to premium</Subject>
                            <SubjectShort>
                                But I must explain to you how all this mistaken idea of denouncing pleasure and...
                            </SubjectShort>
                        </SubjectBody>
                    </Image>
                    <Actions>
                        <Date>12/05/2023</Date>
                        <DelBtn>
                        <DeleteOutlined className={classes.ActionIcons} />
                        </DelBtn>
                    </Actions>
                    </Item>
                </Link>
                <Link to={`/user/message`} style={{ textDecoration: 'none' }}>
                    <Item open={false}>
                    <Image>
                        <From>
                            <FromName>Obasa tunde</FromName>
                            <FromTitle>MyCatalog Customer care</FromTitle>
                        </From>
                        <SubjectBody>
                            <Subject>Upgrade your account to premium</Subject>
                            <SubjectShort>
                                But I must explain to you how all this mistaken idea of denouncing pleasure and...
                            </SubjectShort>
                        </SubjectBody>
                    </Image>
                    <Actions>
                        <Date>12/05/2023</Date>
                        <DelBtn>
                        <DeleteOutlined className={classes.ActionIcons} />
                        </DelBtn>
                    </Actions>
                    </Item>
                </Link>
                <Link to={`/user/message`} style={{ textDecoration: 'none' }}>
                    <Item open={true}>
                    <Image>
                        <From>
                            <FromName>Obasa tunde</FromName>
                            <FromTitle>MyCatalog Customer care</FromTitle>
                        </From>
                        <SubjectBody>
                            <Subject>Upgrade your account to premium</Subject>
                            <SubjectShort>
                                But I must explain to you how all this mistaken idea of denouncing pleasure and...
                            </SubjectShort>
                        </SubjectBody>
                    </Image>
                    <Actions>
                        <Date>12/05/2023</Date>
                        <DelBtn>
                        <DeleteOutlined className={classes.ActionIcons} />
                        </DelBtn>
                    </Actions>
                    </Item>
                </Link>
                <Link to={`/user/collection`} style={{ textDecoration: 'none' }}>
                    <Item open={true}>
                    <Image>
                        <From>
                            <FromName>Obasa tunde</FromName>
                            <FromTitle>MyCatalog Customer care</FromTitle>
                        </From>
                        <SubjectBody>
                            <Subject>Upgrade your account to premium</Subject>
                            <SubjectShort>
                                But I must explain to you how all this mistaken idea of denouncing pleasure and...
                            </SubjectShort>
                        </SubjectBody>
                    </Image>
                    <Actions>
                        <Date>12/05/2023</Date>
                        <DelBtn>
                        <DeleteOutlined className={classes.ActionIcons} />
                        </DelBtn>
                    </Actions>
                    </Item>
                </Link>
                <Link to={`/user/collection`} style={{ textDecoration: 'none' }}>
                    <Item open={true}>
                    <Image>
                        <From>
                            <FromName>Obasa tunde</FromName>
                            <FromTitle>MyCatalog Customer care</FromTitle>
                        </From>
                        <SubjectBody>
                            <Subject>Upgrade your account to premium</Subject>
                            <SubjectShort>
                                But I must explain to you how all this mistaken idea of denouncing pleasure and...
                            </SubjectShort>
                        </SubjectBody>
                    </Image>
                    <Actions>
                        <Date>12/05/2023</Date>
                        <DelBtn>
                        <DeleteOutlined className={classes.ActionIcons} />
                        </DelBtn>
                    </Actions>
                    </Item>
                </Link>
            </CollectionsWrap>
            <Pagination />
          </Body>
        </Wrapper>
    </Container>
  )
}
