import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile, mobileXL, tablet } from '../responsive';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { 
    Cached,
    Add
  } from "@material-ui/icons";
import { useNavigate, Link } from "react-router-dom";

import { SetProfiles } from '../redux/profileRedux';
import { SetProducts } from '../redux/productRedux';
import { SetCollections } from '../redux/collectionRedux';

import useUserRequest from '../hooks/useUserRequest';
import useLogout from '../hooks/useLogout';

const Container = styled.div`
    width: calc(100% - 200px);
    margin-top: 50px;
    margin-left: 200px;
    padding: 40px;
    background-color: #e2e2e2;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "10px 0px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
    ${mobile({
        marginTop: "40px"
    })}  
`;
const Header = styled.div`
    width: 100%;
    //border-bottom: 1px solid #00000030;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none",
      padding: "0px 15px"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    ${mobile({
        fontSize: "23px"
    })}
`;
const Main = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    /* ${tablet({
        flexDirection: "column"
    })}   */
`;
const Left = styled.div`
    width: 100%;
    /* width: calc(50% - 30px); */
    border: 1px soild #000;
    display: flex;
    justify-content: space-between;
    ${tablet({
        flexDirection: "column"
    })}  
`;
const ItemTitle = styled.div`
    color: #858585;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
    ${mobile({
      padding: "0px 15px"
    })}
`;
const ItemBody = styled.div`
    width: 100%;
    padding: 20px;
    background-color: #f3f3f3;
`;
const AssetCount = styled.div`
    width: calc(50% - 10px);
    ${tablet({
        width: "100%",
        marginBottom: "30px"
    })}  
`;
const CountWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    ${mobile({
        flexWrap: "wrap",
        justifyContent: "space-between"
    })} 
`;
const CountItem = styled.div`
    width: calc((100% / 3) - 10px);
    background-color: #88cdf5;
    //border: 1px solid #e2e2e2;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #e2e2e2;
    }
    ${mobile({
        width: "calc(50% - 5px)",
        marginBottom: "10px"
    })} 
`;
const CountHead = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
   //margin-bottom: 5px;
`;

const CountName = styled.div`
    font-family: 'Work Sans', sans-serif;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
`;
const CountValue = styled.div`
    font-family: 'Work Sans', sans-serif;
    color: #000;
    font-size: 40px;
    font-weight: 700;
`;

//// ADD Item

const AddAsset = styled.div`
    width: calc(50% - 10px);
    margin-bottom: 30px;
    ${tablet({
        width: "100%",
        marginBottom: "30px"
    })}  
`;
const AddItemBody = styled.div`
    width: 100%;
    padding: 20px;
    background-color: #f3f3f3;
`;
const AddItemWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    ${mobile({
        flexWrap: "wrap",
        justifyContent: "space-between"
    })} 
`;
const AddItem = styled.div`
    width: calc((100% / 3) - 5px);
    /* aspect-ratio: 4 / 5; */
    //border: 1px solid #212121;
    background-color: #89f5c8;
    /* border-radius: 15px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px 15px 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #cecece;
    }
    ${mobile({
        width: "calc(50% - 5px)",
        marginBottom: "10px"
    })} 
`;
const AddBody = styled.div`
    display: flex;
    flex-direction: column;
`;
const AddIcon = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border: 1px solid #00000050;
    //background-color: #559dc7;
`;
const AddItemName = styled.div`
    //height: 50px;
    font-family: 'Work Sans', sans-serif;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
`;



const PageViews = styled.div`
    width: 100%;
    margin-bottom: 30px;
`;
const PvItemBody = styled.div`
    width: 100%;
`;
const PageViewHead = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #f3f3f3;
    padding: 10px 20px;
    ${mobileXL({
        justifyContent: "flex-end"
    })}  
`;
const PvHeadIndex = styled.div`
    width: 40px;
    ${mobileXL({
        display: "none"
    })}  
`;
const PvHeadPage = styled.div`
    width: 150px;
    color: #9b9b9b;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    ${mobileXL({
        display: "none"
    })}  
`;
const PvHeadName = styled.div`
    width: calc(100% - 320px);
    color: #9b9b9b;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    ${mobileXL({
        display: "none"
    })}  
`;
const PvHeadCount = styled.div`
    width: 100px;
    display: flex;
    align-items: center;
   justify-content: flex-end;
   ${mobile({
        //display: "none"
    })}  
`;
const PvHeadCountText = styled.div`
    color: #9b9b9b;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
    text-align: right; 
`;
const RefreshWrap = styled.div`
    width: 40px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 0.3;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

const PvWrap = styled.div`
    width: 100%;
    margin-top: 10px;
    background-color: #fff;
    //padding-bottom: 40px;
`;
const PvItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 15px 20px;
    border-bottom: 1px solid #e2e2e2;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #B8F904;
    }
`;
const PvIndex = styled.div`
    width: 40px;
    color: #a3a3a3;
    font-size: 14px;
    font-weight: 300;
`;
const PageNameWrap = styled.div`
    width: calc(100% - 110px);
    display: flex;
    justify-content: space-between;
    ${mobileXL({
        flexDirection: "column"
    })}  
`;
const PvPage = styled.div`
    width: 150px;
    color: #6e6e6e;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    ${mobileXL({
        width: "100%",
        marginBottom: "10px"
    })} 
`;
const PvName = styled.div`
    width: calc(100% - 160px);
    color: #000;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    ${mobileXL({
        width: "100%"
    })} 
`;
const PvCount = styled.div`
    width: 50px;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    text-align: right;
`;
const ItemBreak = styled.div`
    width: 100%;
    height: 20px;
    background-color: #e2e2e2;
`;


const Right = styled.div`

`;

const useStyles = makeStyles({
  ActionIcons: {
      color: "#00000060",
      fontSize: 18,
      cursor: "pointer",
      '@media (max-width: 768px)': {
        fontSize: 22,
      }
  },
  AddIcon: {
    color: "#000",
    fontSize: 35
  },
  CacheIcon: {
    color: "#000",
    fontSize: 21
  },
  LoadIcon: {
    color: "#000",
    fontSize: 15
  },
  SortIcon: {
    color: "#fff",
    fontSize: 15
  },
})

export default function Dashboard() {
    const classes = useStyles();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.currentUser);
    const profiles = useSelector(state=>state.profile.profiles);
    const collections = useSelector(state=>state.collection.collections);
    const products = useSelector(state=>state.product.products);

    const userRequest = useUserRequest();
    const logout = useLogout();


    const [profilesSorted, setProfilesSorted] = useState([]);
    const [collectionsSorted, setCollectionsSorted] = useState([]);
    const [productsSorted, setProductsSorted] = useState([]);

    const [isRefreshingCache, setIsRefreshingCache] = useState(false);
    const [loadPercent, setLoadPercent] = useState(0);

    useEffect(()=>{
        if(loadPercent == 100){
            //navigate("/user/dashboard");
            console.log("Refresh Complete!!!");
            setIsRefreshingCache(false);
        }
   },[loadPercent]);

    useEffect(()=>{
        let _profiles = [...profiles];
        let _collections = [...collections];
        let _products = [...products];

        setProfilesSorted(_profiles.sort((a, b) => b.viewCount - a.viewCount));
        setCollectionsSorted(_collections.sort((a, b) => b.viewCount - a.viewCount));
        setProductsSorted(_products.sort((a, b) => b.viewCount - a.viewCount));
        
        //window.scrollTo(0, 0);

    },[profiles, collections, products ]);

    const handleNavigate = (url) => {
        navigate(url);
      };

    const handleRefreshCache = () => {
        setIsRefreshingCache(true);
        setLoadPercent(0);

        getUserProfiles(user.userId);
        getUserCollections(user.userId);
        getUserProducts(user.userId);
    };

    const getUserProfiles = async (userId) => {
        try {
        const res = await userRequest.get(
            "/Business/user/" + userId);
            dispatch(SetProfiles(res.data));
            setLoadPercent((loadPercent) => loadPercent + (100 / 3));
        } catch (err) {
            const res = await logout();
        }
    };

    const getUserCollections = async (userId) => {
        try {
        const res = await userRequest.get(
            "/Collection/user/" + userId);
            dispatch(SetCollections(res.data));
            setLoadPercent((loadPercent) => loadPercent + (100 / 3));
        } catch (err) {
            const res = await logout();
        }
    };

    const getUserProducts = async (userId) => {
        try {
           const res = await userRequest.get(
             "/product/user/" + userId);
            dispatch(SetProducts(res.data));
            setLoadPercent((loadPercent) => loadPercent + (100 / 3));
        } catch (err) {
            const res = await logout();
        }
    };


  return (
    <Container>
        <Wrapper>
            <Header>
                <PageTitle>Welcome, {user?.userName}</PageTitle>
            </Header>
            <Main>
                <Left>
                    <AssetCount>
                        <ItemTitle>assets</ItemTitle>
                        <ItemBody>
                            <CountWrap>
                                <CountItem>
                                    <Link to={`/user/profiles`} style={{ textDecoration: 'none' }}>
                                        <CountValue>{profiles?.length}</CountValue>
                                        <CountHead>
                                            <CountName>profiles</CountName>
                                        </CountHead>
                                    </Link>
                                </CountItem>
                                <CountItem>
                                    <Link to={`/user/collections`} style={{ textDecoration: 'none' }}>
                                        <CountValue>{collections?.length}</CountValue>
                                        <CountHead>
                                            <CountName>collections</CountName>
                                        </CountHead>
                                    </Link>
                                </CountItem>
                                <CountItem>
                                    <Link to={`/user/Products`} style={{ textDecoration: 'none' }}>
                                        <CountValue>{products?.length}</CountValue>
                                        <CountHead>
                                            <CountName>products</CountName>
                                        </CountHead>
                                    </Link> 
                                </CountItem>
                            </CountWrap>
                        </ItemBody>
                    </AssetCount>

                    <AddAsset>
                        <ItemTitle>Create asset</ItemTitle>
                        <AddItemBody>
                            <AddItemWrap>
                                <AddItem onClick={()=> handleNavigate("/user/profile/add")}>
                                    <AddBody>
                                        <AddIcon>
                                            <Add className={classes.AddIcon}/>
                                        </AddIcon>
                                        <AddItemName>profile</AddItemName>
                                    </AddBody>
                                </AddItem>
                                <AddItem onClick={()=> handleNavigate("/user/collection/add")}>
                                    <AddBody>
                                        <AddIcon>
                                            <Add className={classes.AddIcon}/>
                                        </AddIcon>
                                        <AddItemName>collection</AddItemName>
                                    </AddBody>
                                </AddItem>
                                <AddItem onClick={()=> handleNavigate("/user/product/add")}>
                                    <AddBody>
                                        <AddIcon>
                                            <Add className={classes.AddIcon}/>
                                        </AddIcon>
                                        <AddItemName>product</AddItemName>
                                    </AddBody>
                                </AddItem>
                            </AddItemWrap>
                        </AddItemBody>
                    </AddAsset>
                </Left>
                <Right>
                    <PageViews>
                        <ItemTitle>top page views</ItemTitle>
                        <PvItemBody>
                            <PageViewHead>
                                <PvHeadIndex></PvHeadIndex>
                                <PvHeadPage>Page</PvHeadPage>
                                <PvHeadName>Name</PvHeadName>
                                <PvHeadCount>
                                    <PvHeadCountText>Views</PvHeadCountText>
                                    <RefreshWrap onClick={() => handleRefreshCache()}>
                                        {
                                            isRefreshingCache === true ?
                                                <CircularProgress size="1rem" className={classes.LoadIcon} />
                                                :
                                                <Cached className={classes.CacheIcon}/>
                                        }
                                    </RefreshWrap>
                                </PvHeadCount>
                            </PageViewHead>
                            <PvWrap>
                                {
                                    profilesSorted?.slice(0, 3).map((profile, index) => (
                                        <Link key ={profile.id} to={`/user/profile/${profile.id}`} style={{ textDecoration: 'none' }}>
                                            <PvItem>
                                                <PvIndex>{index + 1}</PvIndex>
                                                <PageNameWrap>
                                                    <PvPage>profile</PvPage>
                                                    <PvName>{profile.businessName}</PvName>
                                                </PageNameWrap>
                                                <PvCount>{profile.viewCount}</PvCount>
                                            </PvItem>
                                        </Link>
                                    ))
                                }
                                <ItemBreak />
                                {
                                    collectionsSorted?.slice(0, 3).map((col, index) => (
                                        <Link key={col.id} to={`/user/collection/${col.id}`} style={{ textDecoration: 'none' }}>
                                            <PvItem>
                                                <PvIndex>{index + 1}</PvIndex>
                                                <PageNameWrap>
                                                    <PvPage>Collection</PvPage>
                                                    <PvName>{col.name}</PvName>
                                                </PageNameWrap>
                                                <PvCount>{col.viewCount}</PvCount>
                                            </PvItem>
                                        </Link>
                                    ))
                                }
                                <ItemBreak />
                                {
                                    productsSorted?.slice(0, 3).map((product, index) => (
                                        <Link key={product.id} to={`/user/product/${product.id}`} style={{ textDecoration: 'none' }}>
                                            <PvItem>
                                                <PvIndex>{index + 1}</PvIndex>
                                                <PageNameWrap>
                                                    <PvPage>product</PvPage>
                                                    <PvName>{product.name}</PvName>
                                                </PageNameWrap>
                                                <PvCount>{product.viewCount}</PvCount>
                                            </PvItem>
                                        </Link>
                                    ))
                                }

                            </PvWrap>
                        </PvItemBody>
                    </PageViews>
                </Right>
            </Main>
        </Wrapper>
    </Container>
  )
}
