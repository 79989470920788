//import React from "react";
import axios from "axios";
//import { useDispatch, useSelector } from "react-redux";


// export const BASE_URL = "http://localhost:5200/api";
// export const BASE_RSEOURCE_URL = "http://localhost:5200";
// export const BASE_PUBLIC_URL = "http://localhost:3001";

export const BASE_URL = "https://lisafolawiyo01-001-site1.btempurl.com/api";
export const BASE_RSEOURCE_URL = "https://lisafolawiyo01-001-site1.btempurl.com";
export const BASE_PUBLIC_URL = "https://lisafolawiyo.com";

// export const BASE_URL = "http://192.168.43.25:8081/api";
// export const BASE_RSEOURCE_URL = "http://192.168.43.25:8081";
// export const BASE_PUBLIC_URL = "http://localhost:3001";



export const publicRequest = axios.create({
    baseURL: BASE_URL,
});

export const privateRequest = axios.create({
    baseURL: BASE_URL
});

export const userRequest = axios.create({
    baseURL: BASE_URL
});


