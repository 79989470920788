import React from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import UserDefaultImg from '../../assets/images/user.jpg';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Email } from "@material-ui/icons";
import { Link } from "react-router-dom";

const Container = styled.div`
    width: 100%;
    height: 50px;
    top: 0px;
    display: flex;
    position: fixed;
    background-color: #fff;
    z-index: 5;
    ${mobile({
        height: "65px",
    })}
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 20px;
    ${mobile({
        justifyContent: "start",
    })}
`;
const InnerWrap = styled.div`
    display: flex;
    align-items: center;
`;
const MessageNotice = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    ${mobile({
        marginRight: "20px",
    })}
`;
const MessageCountWrap = styled.div`
    width: 15px;
    height: 15px;
    position: absolute;
    right: -5px;
    top: -5px;
    border-radius: 50%;
    background-color: #f3730a;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const MessageCount = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: #000;
`;
const UserWrap = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const Username = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: #00000050;
    margin-right: 5px;
    /* cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        color: #00000090;
    } */
`;
const ImageWrap = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: azure;
    background-size: cover;
    background-position: center;
    cursor: pointer;
`;
const useStyles = makeStyles({
    MessageIcon: {
        color: "#00000080",
        fontSize: 18,
        '@media (max-width: 768px)': {
            fontSize: 22,
          }
    },
  })

export default function TopMenu() {
    const dispatch = useDispatch();
    const  user = useSelector((state) => state.user.currentUser);
    const classes = useStyles();
  return (
    <Container>
        <Wrapper>
            <InnerWrap>
                {/* <Link to={`/user/messages`} style={{ textDecoration: 'none' }}>
                    <MessageNotice>
                        <Email className={classes.MessageIcon} />
                        <MessageCountWrap>
                            <MessageCount>2</MessageCount>
                        </MessageCountWrap>
                    </MessageNotice>
                </Link> */}
                <UserWrap>
                    <Username>{user?.userName}</Username>
                    <ImageWrap style={{ backgroundImage: `url(${UserDefaultImg})`}} />
                </UserWrap>
            </InnerWrap>
            {/* <UserDropDown></UserDropDown> */}
        </Wrapper>
    </Container>
  )
}
