import React, { useEffect, useState } from "react";
import styled, { keyframes }  from 'styled-components';
import { mobile, mobileXL, tablet } from '../../responsive';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from "@material-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { updateResponse, StartLoader, StopLoader } from '../../redux/responseRedux';
import { useDispatch, useSelector } from 'react-redux';
import {publicRequest , BASE_URL} from "../../requestMethods";
import { RemoveCollection } from '../../redux/collectionRedux';
import useUserRequest from '../../hooks/useUserRequest';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #00000050;
    align-items: center;
    justify-content: center;
    ${({ dragging }) => dragging ? 'display: flex;' : 'display: none;'}
    z-index: 11;
`;
const Wrapper = styled.div`
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    margin: 0px 15px;
    position: relative;
`;
const Top= styled.div`
    width: 100%;
    padding: 20px;
`;
const Title= styled.div`
    color: #000000;
    font-size: 20px;
    font-weight: 600px;
    text-transform: capitalize;
    margin-bottom: 20px;
`;
const Desc = styled.div`
    color: #777777;
    font-size: 16px;
    font-weight: 300px;
`;
const Bottom = styled.div`
    width: 100%;
    padding: 15px 20px;
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const Cancel = styled.div`
    color: #000000;
    font-size: 16px;
    font-weight: 600px;
    text-transform: capitalize;
    cursor: pointer;
`;
const DeleteBtn = styled.div`
    padding: 8px 15px;
    background-color: #f17474;
    margin-left: 30px;
    cursor: pointer;
`;
const DelText = styled.div`
    color: #fff;
    font-size: 16px;
    font-weight: 600px;
    text-transform: capitalize;
`;
const CloseIcon = styled.div`
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    z-index: 12;
`;


const useStyles = makeStyles({
    CloseIcon: {
        color: "#000000",
        fontSize: 22
      },
  })

export default function DeleteCollection({
    deleteId,
    isDeleteOpen, 
    setIsDeleteOpen, 
    CloseDeleteModal,
    collectionsInView,
    setCollectionsInview

 }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const userRequest = useUserRequest();

    const dispatch = useDispatch();

    const Delete = async () => {
        dispatch(StartLoader());
        //console.log("DeleteId: ", deleteId);
        try {
            const res = await userRequest.delete("/collection/" + deleteId);

            let collections = collectionsInView.map(a => {
                return {...a}
            });
            
            collections.splice(
                collections.findIndex((x) => x.id === deleteId ),
                1
            );
            setCollectionsInview(collections);

            dispatch(RemoveCollection(deleteId));
            dispatch(StopLoader());
            dispatch(updateResponse(
                {
                    active: true,
                    success: true,
                    message: "Success: The Collection was deleted successfully"
                }
            ));

        } catch (err) {
            console.log(err);
            dispatch(StopLoader());
            dispatch(updateResponse(
                {
                    active: true,
                    success: false,
                    message: "Error: The operation failed. Try again"
                }
            ));
        }
        setIsDeleteOpen(false);
        navigate("/user/collections");
    };


  return (
    <Container dragging={isDeleteOpen}>
        <Wrapper>
            <Top>
                <Title>Delete</Title>
                <Desc>
                    Are you sure you want to delete? By doing this you'll
                    lose this data permanently.
                </Desc>
            </Top>
            <Bottom>
                <Cancel onClick={CloseDeleteModal}>Cancel</Cancel>
                <DeleteBtn onClick={Delete}>
                    <DelText>Delete</DelText>
                </DeleteBtn>
            </Bottom>
            <CloseIcon onClick={CloseDeleteModal}>
                <Close className={classes.CloseIcon}/>
            </CloseIcon>
        </Wrapper>
    </Container>
  )
}
