import {createSlice} from "@reduxjs/toolkit";


const profileSlice = createSlice({
    name: "profile",
    initialState : {
        profiles : []
    },
    reducers: {

        SetProfiles: (state, action) => {
            state.profiles = action.payload;
        },
        AddProfile: (state, action) => {
            state.profiles.push(action.payload);
        },
        UpdateProfile: (state, action) => {
            let new_updated_data = 
                state.profiles.map((profile) => {
                    if (profile.id === action.payload.id) {
                        return {
                            ...profile,
                            ...action.payload,
                        };
                    }
                    return profile;
                });
            
            state.profiles = new_updated_data;
        },
        RemoveProfile: (state, action) => {
            state.profiles.splice(
                state.profiles.findIndex((x) =>  x.id === action.payload ),
                1
              );
        },
        ClearProfiles: (state) => {
            state.profiles = [];
        },
    },
});

export const { SetProfiles, AddProfile, UpdateProfile, RemoveProfile, ClearProfiles } = profileSlice.actions;
export default profileSlice.reducer;