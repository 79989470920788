import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import {BASE_PUBLIC_URL, BASE_RSEOURCE_URL} from "../../requestMethods";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { 
    Link as LinkIcon,
    AddAPhoto
  } from "@material-ui/icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../assets/css/form.css";
import axios from 'axios';
import useUserRequest from '../../hooks/useUserRequest';
// import ItemImg14 from '../../assets/images/items/item14.jpg';
// import ItemImg14a from '../../assets/images/items/item14a.jpg';
// import ItemImg14b from '../../assets/images/items/item14b.jpg';
import { updateResponse, StartLoader, StopLoader } from '../../redux/responseRedux';
import { UpdateProduct } from '../../redux/productRedux';

import ResponseLoader from '../../components/generic/ResponseLoader';
import ProductQrCodePoster from '../../components/ProductQrCodePoster';

const Container = styled.div`
    width: calc(100% - 200px);
    min-height: 100vh;
    margin-top: 50px;
    margin-left: 200px;
    padding: 60px 60px;
    background-color: #e2e2e2;
    ${tablet({
        padding: "20px 15px"
    })}  
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "65px",
        padding: "20px 0px"
    })}  
`;
const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;
    ${mobile({
        marginTop: "40px"
    })}  
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 20px 0px;
    ${mobile({
      borderBottom: "none",
      padding: "10px 15px 20px 15px"
    })}
`;
const PageTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    ${mobile({
        fontSize: "23px"
    })}
`;
const ProgressBarWrap = styled.div`
    width: 100%;
    background-color: #d8d8d8;
    border-bottom: 1px solid #61616140;
    padding: 0px 20px;
    ${tablet({
        padding: "0px 15px",
        height: "63px"
    })} 
`;
const ProgressBar = styled.div`
    width: 100%;
    display: flex;
    ${tablet({
      display: "block",
      position: "relative"
    })}
`;

const ProgressBarItem = styled.div`
    width: ${props=>props.width}%;
    //border: 1px solid #000;
    @media (max-width: 1024px) {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        //transform: ${props=>props.dragging === true ? "translateX(0%)" : "translateX(-101%)"};
    }
`;
const ProgressBarText = styled.div`
    width: max-content;
    transition: all 0.3s ease;
    color: ${props=>props.active === true ? "#0000FD" : "#7e7e7e"};
    font-size: 14px;
    font-weight: ${props=>props.active === true ? "600" : "300"};
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 20px 0px;
    border-bottom: ${props=>props.active === true ? "2px solid #0000FD" : "none"};
    @media (max-width: 1024px) {
        opacity:  ${props=>props.active === true ? 1 : 0};
    }
`;
const FormContainer = styled.div`
    width: 300%;
    margin-top: 30px;
    display: flex;
    transition: all 0.3s ease;
    transform: translateX(${props=>props.xValue}%);
    ${tablet({
        //marginTop: "100px"
    })}
`;
const FormSectionA = styled.div`
    width: 100%;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;
const FormSectionB = styled.div`
    width: 100%;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;
const FormSectionC = styled.div`
    width: 100%;
    ${mobile({
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    })} 
`;
const FormItem = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    ${tablet({
        flexDirection: "column"
    })}  
`;
const Left = styled.div`
    width: 35%;
    margin-right: 5%;
    ${tablet({
        width: "100%",
        marginRight: "0%",
        marginBottom: "10px"
    })}  
`;
const LabelDesc = styled.div`
    width: 80%;
    margin-top: 5px;
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 300;
`;


const Right = styled.div`
    width: 60%;
    ${tablet({
        width: "100%"
    })}  
`;
const InputItem = styled.div`

`;
const LinkSamleText = styled.div`
    color: #a8a8a8;
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
`;
const ErrorMsg = styled.div`
    color: #f53b3b;
    font-size: 16px;
    font-weight: 300;
    margin-top: 5px;
`;
const CtaWrap = styled.div`
    width: 100%;
    margin-top: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${tablet({
        width: "calc(100% - 20px)"
    })}  
`;
const BtnWrap = styled.div`
    display: flex;
    width: max-content;
    ${mobile({
        width: "100%",
        justifyContent: "space-between"
    })} 
`;
const CancelBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    margin-right: 20px;
    cursor: pointer;
`;
const CancelBtnText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
`;
const ContinueBtnText = styled.div`
    color: #B8F904;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;
const ContinueBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    background-color: #000;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #B8F904;
      ${ContinueBtnText} {
        color: #000;
      }
    }
    ${mobile({
        padding: "15px 30px",
    })} 
`;


const MainImgWrap = styled.div`
    width: 100%;
    display: flex;
    ${mobile({ 
      flexDirection: "column"
    })}
`;
const AddPhotoBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 5px 8px;
    background-color: #f0f1f5;
    border-radius: 7px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #e9e6e6;
    }
    ${mobile({
      bottom: "5px",
      left: "5px"
    })}
`;
const AddPhotoText = styled.div`
    color: #000;
    font-size: 13px;
    font-weight: 400;
    text-transform: capitalize;
    margin-top: 3px;
    ${mobile({
      fontSize: "11px"
    })}
`;
const ColorSamplePhoto = styled.div`
    width: 150px;
    height: 150px;
    background-color: #6e6e6e;
    background-size: cover;
    background-position: center;
    position: relative;
    /* z-index: 2; */
    cursor: pointer;
`;
const CoverPhoto = styled.div`
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #6e6e6e;
    background-size: cover;
    background-position: center;
    position: relative;
    /* z-index: 2; */
    cursor: pointer;
    &:before {
        float: left;
        padding-top: 100%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
`;
const AltImages = styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    ${mobile({ 
      width: "100%",
      flexDirection: "row",
      marginTop: "10px",
      justifyContent: "space-between"
    })}
`;
const AltPhoto = styled.div`
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #9b9999;
    background-size: cover;
    background-position: center;
    position: relative;
    /* z-index: 2; */
    cursor: pointer;
    &:before {
        float: left;
        padding-top: 100%;
        content: '';
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
`;

/// SubItem ///

const SubItemWrap = styled.div`
    width: 100%;
`;
const SubItem = styled.div`
    width: 100%;
    background-color: #f3f3f3;
    padding: 20px;
    margin-bottom: 10px;
`;
const SubItemBody = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;
const SubItemLabel = styled.div`
    width: 100%;
`;
const SubItemValue = styled.div`
    width: 100%;
`;
const SubCta = styled.div`
    width: 100%;
    margin-top: 20px;
`;
const SubCtaWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

`;
const SubRemoveBtn = styled.div`
    width: max-content;
    border: 1px solid #000;
    padding: 5px 15px;
    cursor: pointer;
`;
const SubRemoveText = styled.div`
    color: #000;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
`;

const SubAddBtnWrap = styled.div`
    width: 100%;
    margin-top: 20px;
`;
const SubAddBtn = styled.div`
    width: max-content;
    background-color: blue;
    padding: 5px 15px;
    cursor: pointer;
`;
const SubAddBtnText = styled.div`
    color: #000;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
`;
const SubImageWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

`;
const SubImageItem = styled.div`
    width: 30%;
`;


const PriceSection = styled.div`
    width: 100%;
    display: flex;

`;
const Currency = styled.div`
    width: 80px;
    height: 40px;
    background-color: #eeeeee;
    border: 1px solid #bdbdbd;
    padding: 0px 10px;
    display: flex;
    align-items: center;
`;
const CurrencyText = styled.div`
    color: #000000;
    font-size: 16px;
    text-transform: uppercase;
`;
const PriceInput = styled.div`
    width: calc(100% - 80px);
`;



const useStyles = makeStyles({
    AddBgImgIcon: {
      color: "#000",
      marginRight: 4,
      fontSize: 16,
      '@media (max-width: 768px)': {
        fontSize: 14,
      }
    },
    ProductIcon: {
        color: "#fff",
        marginRight: 4,
        fontSize: 18
      },
  })

export default function Product() {
    const classes = useStyles();

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const responseLoader = useSelector(state=>state.response.loader);

    const productId = location.pathname.split("/")[3];

    const coverImageRef = useRef(null);
    const altImage1Ref = useRef(null);
    const altImage2Ref = useRef(null);

    const user = useSelector((state) => state.user.currentUser);
    const collections = useSelector(state=>state.collection.collections);
    const _categories = useSelector(state=>state.category.categories);
    const productCategories = useSelector(state=>state.nonentity.productCategories);
    const products = useSelector(state=>state.product.products);
    const currencies = useSelector(state=>state.nonentity.currencies);
    const profiles = useSelector((state) => state.profile.profiles);

    const userRequest = useUserRequest();

    const [business, setBusiness] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState({});


        /////// Progress Bar ///////////////
        const [sectionXvalue, setSectionXvalue] = useState(0);
        const [progressBar, setProgressBar] = useState({
            first: true,
            second: false,
            third: false
        });
    
        const handleSectionClick = section => () => {
            let bar = {
                first: true,
                second: false,
                third: false
            }
            let value = 0;
    
            let result = true;//isNext(section);
    
            if(section == "A" && result == true){
                bar = {first: true, second: false, third: false}
                value = 0;
                //value = -(100/3);
            }
            if(section == "B" && result == true){
                bar = {first: false, second: true, third: false};
                value = -(100/3);
                //value = -((2/3)*100);
            }
            if(section == "C" && result == true){
                bar = {first: false, second: false, third: true}
                value = -((2/3)*100);
            }
    
            setProgressBar(bar);
            setSectionXvalue(value);
            window.scrollTo(0, 0);
        };
    
    const isNext = (section) => {

            let error_msg = {...errors};
            let _focus = {...focus};
            let next = true;
    
            if (section == "B"){
    
                if (values.collectionId == "" ) {
                    next = false;
                    error_msg = {
                        ...error_msg, 
                        ["collectionId"]: "Select a collection"
                    };
                    _focus = {
                        ..._focus, 
                        ["collectionId"]: true
                    };
                }
                if (values.productCategoryId == "") {
                    next  = false;
                    error_msg = {
                        ...error_msg, 
                        ["productCategoryId"]: "Select a product category"
                    };
                    _focus = {
                        ..._focus, 
                        ["productCategoryId"]: true
                    };
                }
                if (values.name == "") {
                    next  = false;
                    error_msg = {
                        ...error_msg, 
                        ["name"]: "Enter your collection name"
                    };
                    _focus = {
                        ..._focus, 
                        ["name"]: true
                    };
                }
    
        }
    
        setErrors(error_msg);
        setFocus(_focus);
    
        return next;
    
    };
        ////////// End ////////////////
        // const [selectedImageUrl, setSelectedImageUrl] = useState({
        //     CoverImageFile: "",
        //     AltImage1File: "",
        //     AltImage2File: ""
        // });
        const [_productCategories, setProductCategories] = useState([]);

        const [values, setValues] = useState({
            categoryId: "",
            collectionId: "",
            name: "",
            description: "",
            categories: [],
            showPrice: true,
            inStock: true,
            active: true,
        });

        const onChange = (e) => {
            let value = e.target.value;
            let name = e.target.name;
    
            setValues({ ...values, [name]: value });
            console.log(values);
            //validateInput(name, value);
        };
    
        const handleFocus = (e) => {
            setFocus({ ...focus, [e.target.name]: true });
        };

    /// Categories ////
    const [categories, setCategories] = useState([""]);

    const handleAddCategory = () => {
        let abc = [...categories, []];
        setCategories(abc);
    }
    const handleCategorySave = (e,i) => {

        let value = e.target.value;
        //let name = e.target.name;

        let catArray = [...categories];
        //let catItem = {...catArray[i]};
         
        // sizeObject[name] = value;

        // sizeArray[i] = sizeObject;
        catArray.push(value);

        setCategories(catArray);
        console.log(categories);
    }

    const handleCategoryDelete=(i)=>{
        let sizeArray = [...sizes];
        if( i != 0){
            sizeArray.splice(i,1);
        }
        setSizes(sizeArray);
        console.log(sizes);
    }

        //// SIZES ////

        const [sizes, setSizes] = useState([]);
    
        const handleAddSize = () => {
            let abc = [...sizes,{
                name: "",
                description: "",
            }];
            setSizes(abc);
        }
        const handleSizeChange = (e,i) => {
    
            let value = e.target.value;
            let name = e.target.name;
    
            let sizeArray = [...sizes];
            let sizeObject = {...sizeArray[i]};
             
            sizeObject[name] = value;
    
            sizeArray[i] = sizeObject;
    
            setSizes(sizeArray);
            console.log(sizes);
        }
    
        const handleSizeDelete=(i)=>{
            let sizeArray = [...sizes];
            if( i != 0){
                sizeArray.splice(i,1);
            }
            setSizes(sizeArray);
            console.log(sizes);
        }

        
    /// COLORS ///

    // const myRefs = useRef([]);

    // myRefs.current = [0,0,0].map(
    //     (ref, index) =>   myRefs.current[index] = React.createRef()
    // );

    const [imageRefs, setImageRefs] = useState([
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        },
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        },
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        },
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        },
        {
            0: useRef(null),
            1: useRef(null),
            2: useRef(null),
            3: useRef(null)
        }
    ]);

    // useEffect(()=>{

    // },[colors]);

    const [colors, setColors] = useState([]);

    const handleAddColor = () => {
        let abc;
        let len = colors.length;

        abc = [...colors];
        
        if (len <= 4) {
            abc = [...colors, {
                name: "",
                colorSampleImageFile: "",
                coverImageFile: "",
                altImage1File: "",
                altImage2File: "",
                colorSampleImageUrl: "",
                coverImageUrl: "",
                altImage1Url: "",
                altImage2Url: "",
                priceNGN: 0,
                priceUSD: 0,
                priceGBP: 0,
                priceEUR: 0
            }];
        }

        setColors(abc);
    }
    const handleColorChange = (e,i) => {

        let value = e.target.value;
        let name = e.target.name;

        let colorArray = [...colors];
        let colorObject = {...colorArray[i]};
         
        colorObject[name] = value;

        colorArray[i] = colorObject;

        setColors(colorArray);
        console.log(colors);
    }

    const handleImageClick = (ref) => {
        ref.current.click();
    };

    const handleFileChange = (event, i)=> {
        let colorArray = [...colors];
        let colorObject = {...colorArray[i]};

        let error_msg = {...errors};
        let _focus = {...focus};
        const fileObj = event.target.files && event.target.files[0];

        if (!fileObj) {
          return;
        }
        if (fileObj.type == "image/jpeg" || fileObj.type == "image/jpg" || fileObj.type == "image/png") {
            if((fileObj.size/1024)/1024 < 2.1)  {
                //console.log('fileObj is', fileObj);
                colorObject[event.target.name.concat("UrlTemp")] = URL.createObjectURL(event.target.files[0]);
                colorObject[event.target.name.concat("File")] = event.target.files[0];

                colorArray[i] = colorObject;
                setColors(colorArray);
                console.log(colors);

                // setSelectedImageUrl({...selectedImageUrl, [event.target.name]: URL.createObjectURL(event.target.files[0]) });
                // setValues({ ...values, [event.target.name]: event.target.files[0] });

                event.target.value = null;
                error_msg = {
                    ...error_msg, 
                    ["imageFile"]: ""
                }; 
            }else{
                error_msg = {
                    ...error_msg, 
                    ["imageFile"]: "Image size should not be more than 2mb"
                };
            }
            
        }else {
            error_msg = {
                ...error_msg, 
                ["imageFile"]: "Invalid image format"
            };
        }  
        _focus = {
            ..._focus, 
            ["imageFile"]: true
        };
        //setFocus(_focus);
        //setErrors(error_msg);
    };

    const handleColorDelete=(i)=>{
        let colorArray = [...colors];
        if( i != 0){
            colorArray.splice(i,1);
        }
        setColors(colorArray);
        console.log(colors);
    }

        /////////////

        const [errors, setErrors] = useState({
            categoryId: "",
            collectionId: "",
            name: "",
            description: "",
            categories: "",
            showPrice: "",
            inStock: "",
            active: "",
            imageFile: ""
        });
    
        const [focus, setFocus] = useState({
            categoryId: false,
            collectionId: false,
            name: false,
            description: false,
            categories: false,
            showPrice: false,
            inStock: false,
            active: false,
            imageFile: false
        });
    
        useEffect(()=>{
            window.scrollTo(0, 0);
        },[]);

        
    const getProduct = () => {
        products.map((product) => {
            if (product.id === productId) {
                setValues({
                    categoryId: product.categoryId,
                    collectionId: product.collectionId,
                    name: product.name,
                    description: product.description,
                    showPrice: product.showPrice,
                    inStock: product.inStock,
                    active: product.active,
                });
                console.log(product);
                setSizes(product.sizes);
                initializeColors(product.colors);               
                setProduct(product);
                // setSelectedImageUrl({
                //     ["CoverImageFile"]: BASE_RSEOURCE_URL + "/uploads/user/product/images/" + product.colors[0].coverImage,
                //     ["AltImage1File"]: BASE_RSEOURCE_URL + "/uploads/user/product/images/" + product.colors[0].altImage1,
                //     ["AltImage2File"]: BASE_RSEOURCE_URL + "/uploads/user/product/images/" + product.colors[0].altImage2,
                // });
                getSelectedCollection(product.collectionId);
            }
        });
    };

    const initializeColors = (colors) => {
        let _colors = [];
        colors.map((color) => {
            let x = {
                name: color.name,
                colorSampleImageFile: "",
                coverImageFile: "",
                altImage1File: "",
                altImage2File: "",
                colorSampleImageUrlTemp: BASE_RSEOURCE_URL + "/uploads/images/product/" + color.colorSampleImage,
                coverImageUrlTemp: BASE_RSEOURCE_URL + "/uploads/images/product/" + color.coverImage,
                altImage1UrlTemp: BASE_RSEOURCE_URL + "/uploads/images/product/" + color.altImage1,
                altImage2UrlTemp: BASE_RSEOURCE_URL + "/uploads/images/product/" + color.altImage2,
                colorSampleImageUrl: color.colorSampleImage,
                coverImageUrl: color.coverImage,
                altImage1Url: color.altImage1,
                altImage2Url: color.altImage2,
                priceNGN: color.priceNGN,
                priceUSD: color.priceUSD,
                priceGBP: color.priceGBP,
                priceEUR: color.priceEUR
            };
            _colors.push(x);
        });
        setColors(_colors);
    };

    const getSelectedCollection = async (id) => {
        collections.map(col => {
            if(col.id == id) {
                setSelectedCollection(col);
                getBusiness(col.businessId);
            }
        });
    };

    const getBusiness = async (id) => {
        profiles.map(business => {
            if(business .id == id) {
                setBusiness(business);
            }
        });
    };


    const validateInput = (inputName, InputValue) => {
        let regex = "";

        let valid = true;

        let error_msg = {...errors};


        //// Name /////
        if (inputName === "name") {
            //regex = /^(?=.{3,100}$)[^\W_]+(?: [^\W_]+)*$/;
            regex = /^[A-Za-z0-9 ]{3,100}$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "character length should be between 3 and 100. Only alphabets and numbers."
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Description /////
        if (inputName === "description") {
            //regex = /^[A-Za-z0-9 -/,.]{3,10}$/;
            regex = /^.{10,500}$/s;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "character length should be between 10 and 500"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //// Price /////
        if (inputName === "Price") {
            regex = /^[1-9][0-9]*$/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Enter a number greater than 0"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }
        //// Url Links /////
        if (inputName === "PurchaseUrl") {
            regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            valid = regex.test(InputValue);
            if(!valid) {
                error_msg = {
                    ...error_msg, 
                    [inputName]: "Enter a valid url with http protocol (http:// or https://)"
                };
            }else {
                error_msg = {
                    ...error_msg, 
                    [inputName]: ""
                };
            }
        }

        //console.log(errors);
        setErrors(error_msg);

    };

    const checkRequiredInputs = () => {
        let error_msg = {...errors};
        let _focus = {...focus};

        let result = true;

        if (values.CoverImageFile == "" ) {
            result = false;
            error_msg = {
                ...error_msg, 
                ["ImageFile"]: "You must choose a cover image for this product"
            };
            _focus = {
                ..._focus, 
                ["ImageFile"]: true
            };
        }

        if (values.Name == "") {
            result = false;
            error_msg = {
                ...error_msg, 
                ["Name"]: "Enter your collection name"
            };
            _focus = {
                ..._focus, 
                ["Name"]: true
            };
        }

        setErrors(error_msg);
        setFocus(_focus);
        return result;
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();

        let result = true;//checkRequiredInputs();

        if(result) {
            //dispatch(StartLoader());

            let formData = new FormData();

            formData.append('Name', values.name);
            formData.append('CategoryId', values.categoryId);
            formData.append('CollectionId', values.collectionId);
            formData.append('Description', values.description);
            formData.append('ShowPrice', values.showPrice);
            formData.append('InStock', values.inStock);
            formData.append('Active', values.active);

            for (var i = 0; i < categories.length; i++) {
                formData.append('Categories['+i+']' , categories[i]);
            }

            for (var i = 0; i < sizes.length; i++) {
                formData.append('Sizes['+i+'].Name' , sizes[i].name);
                formData.append('Sizes['+i+'].Description' , sizes[i].description);
            }

            for (var i = 0; i < colors.length; i++) {
                formData.append('Colors['+i+'].Name' , colors[i].name);

                formData.append('Colors['+i+'].ColorSampleImageFile' , colors[i].colorSampleImageFile);
                formData.append('Colors['+i+'].CoverImageFile' , colors[i].coverImageFile);
                formData.append('Colors['+i+'].AltImage1File' , colors[i].altImage1File);
                formData.append('Colors['+i+'].AltImage2File' , colors[i].altImage2File);
                
                formData.append('Colors['+i+'].ColorSampleImage' , colors[i].colorSampleImageUrl);
                formData.append('Colors['+i+'].CoverImage' , colors[i].coverImageUrl);
                formData.append('Colors['+i+'].AltImage1' , colors[i].altImage1Url);
                formData.append('Colors['+i+'].AltImage2' , colors[i].altImage2Url);

                formData.append('Colors['+i+'].PriceNGN' , parseInt(colors[i].priceNGN));
                formData.append('Colors['+i+'].PriceUSD' , parseInt(colors[i].priceUSD));
                formData.append('Colors['+i+'].PriceGBP' , parseInt(colors[i].priceGBP));
                formData.append('Colors['+i+'].PriceEUR' , parseInt(colors[i].priceEUR));
            }
    
            try {
                const res = await axios.put("http://localhost:5200/api/Product/"+ product.id, formData);
    
                dispatch(UpdateProduct(res.data));
                dispatch(updateResponse(
                    {
                        active: true,
                        success: true,
                        message: "Success: Your product was updated successfully"
                    }
                ));
                dispatch(StopLoader());
                navigate("/user/products");
            } catch (err) {
                console.log(err);
                dispatch(updateResponse(
                    {
                        active: true,
                        success: false,
                        message: "Error: The operation failed. Check your internet and try again"
                    }
                ));
                dispatch(StopLoader());
                navigate("/user/products");
            }
        }else {
            window.scrollTo(0, 0);
            console.log("FORM IS NOT GOOD");
        }
    };

    useEffect(()=>{
        window.scrollTo(0, 0);
        getProduct();

    },[]);

    return (
        <Container>
            <Wrapper>
                <Header>
                    <PageTitle>{product.name}</PageTitle>
                </Header>
                <ProgressBarWrap>
                    <ProgressBar>
                        <ProgressBarItem width={100/3}>
                            <ProgressBarText active={progressBar.first}>description</ProgressBarText>
                        </ProgressBarItem>
                        <ProgressBarItem width={100/3}>
                            <ProgressBarText active={progressBar.second}>sizes</ProgressBarText>
                        </ProgressBarItem>
                        <ProgressBarItem width={100/3}>
                            <ProgressBarText active={progressBar.third}>colors & images</ProgressBarText>
                        </ProgressBarItem>
                    </ProgressBar>
                </ProgressBarWrap>
        <FormContainer xValue={sectionXvalue}>
            <FormSectionA>
                <FormItem>
                    <Left>
                        <label for="type">Category</label>
                        <LabelDesc>
                            Select the product type
                        </LabelDesc>
                    </Left>
                    <Right>
                        <InputItem>
                            <select 
                                className="main_input c_input"
                                name="categoryId" 
                                id="category_id"
                                value={values.categoryId}
                                onChange={onChange}
                                onBlur={handleFocus}
                                >
                                {_categories.map(col => {
                                    if(col.id == values.categoryId) {
                                        return  <option
                                                    key={col.id} 
                                                    value={col.id}
                                                    selected>
                                                        {col.name}
                                                    </option>
                                    }else {
                                        return <option 
                                                    key={col.id} 
                                                    value={col.id}>
                                                        {col.name}
                                                    </option>
                                    }
                                    

                                })}
                            </select>                
                        </InputItem>
                        <ErrorMsg>{focus.categoryId && errors.categoryId != "" && errors.categoryId}</ErrorMsg>
                    </Right>
                </FormItem>
                <FormItem>
                <Left>
                    <label for="type">Collection</label>
                    <LabelDesc>
                        Select the collection of for product
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <select 
                            className="main_input c_input"
                            name="collectionId" 
                            id="collection_id"
                            value={values.collectionId}
                            onChange={onChange}
                            onBlur={handleFocus}
                            >
                                <option key={0}  value="" selected>Select collection</option>
                            {collections.map(col => {
                                    if(col.id == values.collectionId) {
                                        return  <option
                                                    key={col.id} 
                                                    value={col.id}
                                                    selected>
                                                        {col.name}
                                                    </option>
                                    }else {
                                        return <option 
                                                    key={col.id} 
                                                    value={col.id}>
                                                        {col.name}
                                                    </option>
                                    }
                                    

                                })}
                        </select>                
                    </InputItem>
                    <ErrorMsg>{focus.collectionId && errors.collectionId != "" && errors.collectionId}</ErrorMsg>
                </Right>
            </FormItem>
            <FormItem>
                <Left>
                    <label for="name">Name</label>
                    <LabelDesc>
                        Enter the name of your product
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <input
                            className="main_input c_input"
                            type="text" 
                            name="name"
                            value={values.name}
                            onChange={onChange}
                            onBlur={handleFocus}
                            placeholder=''
                            required />              
                    </InputItem>
                    <ErrorMsg>{focus.name && errors.name != "" && errors.name}</ErrorMsg>
                </Right>
            </FormItem>
                <FormItem>
                    <Left>
                        <label for="desc_id">Description</label>
                        <LabelDesc>
                            Describe your product in less than 500 words
                        </LabelDesc>
                    </Left>
                    <Right>
                        <InputItem>
                            <textarea
                                className=""
                                id="desc_id"
                                rows="5"
                                name="description"
                                onBlur={handleFocus}
                                onChange={onChange}
                                value={values.description}
                                placeholder=''
                                />             
                        </InputItem>
                        <ErrorMsg>{focus.description && errors.description != "" && errors.description}</ErrorMsg>
                    </Right>
                </FormItem>
                <CtaWrap>
                <BtnWrap>
                    <Link to={`/user/products`} style={{ textDecoration: 'none' }} >
                        <CancelBtn>
                            <CancelBtnText>cancel</CancelBtnText>
                        </CancelBtn>
                    </Link>
                    <ContinueBtn onClick={handleSectionClick("B")}>
                        <ContinueBtnText>continue</ContinueBtnText>
                    </ContinueBtn>
                </BtnWrap>
            </CtaWrap>
        </FormSectionA>
        <FormSectionB>
            <FormItem>
                <Left>
                    <label for="Size">Sizes</label>
                    <LabelDesc>
                        Add available sizes for this product
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <SubItemWrap>
                        {sizes.map((data,i)=>{
                            return(
                                <SubItem key={i}>
                                    <SubItemBody>
                                        <SubItemLabel>
                                            <label for="Name">Name:</label>
                                        </SubItemLabel>
                                        <SubItemValue>
                                            <input
                                                className="main_input c_input"
                                                type="text" 
                                                name="name"
                                                placeholder=''
                                                value={data.name}
                                                onChange={e=>handleSizeChange(e,i)}
                                                required />  
                                        </SubItemValue>
                                    </SubItemBody>
                                    <SubItemBody>
                                        <SubItemLabel>
                                            <label for="Name">Description:</label>
                                        </SubItemLabel>
                                        <SubItemValue>
                                            <input
                                                className="main_input c_input"
                                                type="text" 
                                                name="description"
                                                placeholder=''
                                                value={data.description}
                                                onChange={e=>handleSizeChange(e,i)}
                                                required />  
                                        </SubItemValue>
                                    </SubItemBody>
                                <SubCta>
                                    <SubCtaWrap>
                                        <SubRemoveBtn onClick={()=>handleSizeDelete(i)}>
                                            <SubRemoveText>Remove item</SubRemoveText>
                                        </SubRemoveBtn>
                                    </SubCtaWrap>
                                </SubCta>
                            </SubItem>
                            )
                        })}
                           
                            <SubAddBtnWrap>
                                <SubAddBtn onClick={()=>handleAddSize()}>
                                    <SubAddBtnText>Add size</SubAddBtnText>
                                </SubAddBtn>
                            </SubAddBtnWrap>
                        </SubItemWrap>           
                    </InputItem>
                    <ErrorMsg></ErrorMsg>
                </Right>
            </FormItem>
            <CtaWrap>
                <BtnWrap>
                        <CancelBtn onClick={handleSectionClick("A")}>
                            <CancelBtnText>cancel</CancelBtnText>
                        </CancelBtn>
                    <ContinueBtn onClick={handleSectionClick("C")}>
                        <ContinueBtnText>continue</ContinueBtnText>
                    </ContinueBtn>
                </BtnWrap>
            </CtaWrap>
    </FormSectionB>
    <FormSectionC>
        <FormItem>
                <Left>
                    <label for="Name">Colors</label>
                    <LabelDesc>
                        Add available colors for this product
                    </LabelDesc>
                </Left>
                <Right>
                    <InputItem>
                        <SubItemWrap>
                        {colors.map((data,i)=>{ 
                            return (
                                <SubItem key={i}>
                                <SubItemBody>
                                    <SubItemLabel>
                                        <label for="Name">Color name:</label>
                                    </SubItemLabel>
                                    <SubItemValue>
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="name"
                                            placeholder=''
                                            value={data.name}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />  
                                    </SubItemValue>
                                </SubItemBody>
                                <SubItemBody>
                                    <SubItemLabel>
                                        <label for="Name">Upload Color Sample Image:</label>
                                    </SubItemLabel>
                                    <SubItemValue>
                                        <ColorSamplePhoto style={{ backgroundImage: `url(${data.colorSampleImageUrlTemp})`}}>
                                            <AddPhotoBtn onClick={()=>handleImageClick(imageRefs[i][3])}>
                                                <AddAPhoto className={classes.AddBgImgIcon} />
                                                <AddPhotoText>color sample photo</AddPhotoText>
                                            </AddPhotoBtn>
                                        </ColorSamplePhoto>
                                    </SubItemValue>
                                </SubItemBody>
                                <SubItemBody>
                                    <SubItemLabel>
                                        <label for="Name">images:</label>
                                    </SubItemLabel>
                                    <SubItemValue>
                                        <SubImageWrap>
                                            <SubImageItem>
                                                <CoverPhoto style={{ backgroundImage: `url(${data.coverImageUrlTemp})`}}>
                                                    <AddPhotoBtn onClick={()=>handleImageClick(imageRefs[i][0])}>
                                                        <AddAPhoto className={classes.AddBgImgIcon} />
                                                        <AddPhotoText>cover photo</AddPhotoText>
                                                    </AddPhotoBtn>
                                                </CoverPhoto>
                                            </SubImageItem>
                                            <SubImageItem>
                                                <AltPhoto style={{ backgroundImage: `url(${data.altImage1UrlTemp})`}}>
                                                    <AddPhotoBtn onClick={()=>handleImageClick(imageRefs[i][1])}>
                                                        <AddAPhoto className={classes.AddBgImgIcon} />
                                                        <AddPhotoText>alt photo</AddPhotoText>
                                                    </AddPhotoBtn>
                                                </AltPhoto>
                                            </SubImageItem>
                                            <SubImageItem>
                                                <AltPhoto style={{ backgroundImage: `url(${data.altImage2UrlTemp})`}}>
                                                    <AddPhotoBtn onClick={()=>handleImageClick(imageRefs[i][2])}>
                                                        <AddAPhoto className={classes.AddBgImgIcon} />
                                                        <AddPhotoText>alt photo</AddPhotoText>
                                                    </AddPhotoBtn>
                                                </AltPhoto>
                                            </SubImageItem>
                                        </SubImageWrap>
                                        <input
                                            ref={imageRefs[i][0]}
                                            type="file"
                                            name="coverImage"
                                            style={{display: 'none'}}
                                            onChange={e=>handleFileChange(e,i)}
                                            />
                                        <input
                                            ref={imageRefs[i][1]}
                                            type="file"
                                            name="altImage1"
                                            style={{display: 'none'}}
                                            onChange={e=>handleFileChange(e,i)}
                                            />
                                        <input
                                            ref={imageRefs[i][2]}
                                            type="file"
                                            name="altImage2"
                                            style={{display: 'none'}}
                                            onChange={e=>handleFileChange(e,i)}
                                            /> 
                                        <input
                                            ref={imageRefs[i][3]}
                                            type="file"
                                            name="colorSampleImage"
                                            style={{display: 'none'}}
                                            onChange={e=>handleFileChange(e,i)}
                                            /> 
                                    </SubItemValue>
                                </SubItemBody>
                                <SubItemBody>
                                    <SubItemLabel>
                                        <label for="Name">Prices:</label>
                                    </SubItemLabel>
                                    <SubItemValue>
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="priceNGN"
                                            placeholder='NGN'
                                            value={data.priceNGN}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="priceUSD"
                                            placeholder='USD'
                                            value={data.priceUSD}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="priceGBP"
                                            placeholder='GBP'
                                            value={data.priceGBP}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />
                                        <input
                                            className="main_input c_input"
                                            type="text" 
                                            name="priceEUR"
                                            placeholder='EUR'
                                            value={data.priceEUR}
                                            onChange={e=>handleColorChange(e,i)}
                                            required />  
                                    </SubItemValue>
                                </SubItemBody>
                                <SubCta>
                                    <SubCtaWrap>
                                        <SubRemoveBtn onClick={()=>handleColorDelete(i)}>
                                            <SubRemoveText>Remove item</SubRemoveText>
                                        </SubRemoveBtn>
                                    </SubCtaWrap>
                                </SubCta>
                            </SubItem>
                            )})}
                            <SubAddBtnWrap>
                                <SubAddBtn onClick={()=>handleAddColor()}>
                                    <SubAddBtnText>Add item</SubAddBtnText>
                                </SubAddBtn>
                            </SubAddBtnWrap>
                        </SubItemWrap>           
                    </InputItem>
                    <ErrorMsg></ErrorMsg>
                </Right>
            </FormItem>
            <CtaWrap>
                <BtnWrap>
                    <CancelBtn onClick={handleSectionClick("B")}>
                        <CancelBtnText>back</CancelBtnText>
                    </CancelBtn>
                    <ContinueBtn onClick={handleSubmit}>
                        <ContinueBtnText>create product</ContinueBtnText>
                    </ContinueBtn>
                </BtnWrap>
            </CtaWrap>
        </FormSectionC>
        </FormContainer>
            </Wrapper>
            { responseLoader.active == true ?
                <ResponseLoader />
                :
                <></>
            }
        </Container>
      )
}
