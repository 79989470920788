import React, { useState, useEffect } from 'react';
import "../../assets/css/menuIconStyle.css";
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import { makeStyles } from '@material-ui/core/styles';
import { 
    BarChart,
    AccountBox,
    Collections,
    Settings,
    RecentActors,
    StoreOutlined,
    MailOutline,
    Dashboard,
    LocalOffer,
    ExitToApp,
    PermIdentity,
    Contacts
 } from "@material-ui/icons";

import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogoutUser } from "../../redux/userRedux";

import useLogout from '../../hooks/useLogout';

import LogoWhiteImg from '../../assets/images/Store-Manager-Logo-white.png';

const Container = styled.div`
    //width: 100%;
    /* z-index: 10; */
`;
const MainWrap = styled.div`
    width: 200px;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #000;
    transition: all 0.3s ease;
    z-index: 5;
    @media (max-width: 558px) {
        transform: ${props=>props.dragging === true ? "translateX(0%)" : "translateX(-101%)"};
    }
    ${mobile({
        width: "100%",
    })}
`;
const Wrapper = styled.div`
    width: 100%;
`;
const MobileMenuIconWrap = styled.div`
    position: fixed;
    top: 10px;
    right: 20px;
    display: none;
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 6;
    ${mobile({
        display: "block",
    })}
`;
const MobileClose = styled.div`
    width: 40px;
    height: 40px;
    background-color: yellow;
    display: none;
    transition: all 0.3s ease;
    cursor: pointer;
    ${mobile({
        display: "block",
    })}
`;
const LogoWrap = styled.div`
    width: 100%;
    padding: 20px 20px 20px 30px;
`;
const Logo = styled.img`
    width: 120px;
    ${mobile({
        //width: "60px",
    })}
`;
const Menu = styled.div`
    margin-top: 50px;
    ${mobile({
        display: "flex",
        flexDirection: "column"
    })}
`;
// const MobileMenu = styled.div`
//     margin-top: 50px;
//     display: none;
//     ${mobile({
//         display: "flex",
//         flexDirection: "column"
//     })}
// `;
const Item = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 30px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #ec7fbb;
        padding: 10px 15px 10px 25px;
    }
    ${mobile({
        padding: "20px 20px 20px 20px",
        borderBottom: "1px solid #fff"
    })}
`;
const ItemText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 700px;
    text-transform: capitalize;
    ${mobile({
        //fontSize: "20px",
    })}
`;
const UserMenu = styled.div`
    width: 100%;
    margin-top: 50px;
    ${mobile({
        
    })}
`;
const UserItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 30px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #ec7fbb;
        padding: 10px 15px 10px 25px;
    }
    ${mobile({
        padding: "20px 20px 20px 20px",
        borderBottom: "1px solid #fff"
    })}
`;
const UserItemText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 300px;
    text-transform: capitalize;
`;
const useStyles = makeStyles({
    MenuIcons: {
        color: "#ffffff",
        fontSize: 18,
        marginRight: 10,
        '@media (max-width: 768px)': {
            fontSize: 22,
          }
    },
  })
export default function Sidebar() {
    const classes = useStyles();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = useLogout();

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
      }
    
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        //console.log(screenSize.width);

        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const toggleMenuBtn = () => {

        if(screenSize.width < 559) {
            setIsMenuOpen(!isMenuOpen);
            const button = document.getElementById("menu-button").classList,
            isopened = "is-opened";
            let isOpen = button.contains(isopened);
            if(isOpen) {
                button.remove(isopened);
            }else {
                button.add(isopened);
            }
        }

    }; 

    const handleLogout = () => {
        logout();
        navigate("/");
    }

  return (
    <Container>
        <MobileMenuIconWrap onClick={toggleMenuBtn}>
            <button class="button is-text" id="menu-button" onclick="buttonToggle()">
                <div class="button-inner-wrapper">
                    <span class="icon menu-icon"></span>
                </div>
            </button>
        </MobileMenuIconWrap>
        <MainWrap dragging={isMenuOpen}>
            <Wrapper>
                {/* <MobileClose onClick={toggleMenuBtn}></MobileClose> */}
                <LogoWrap>
                    <Logo src={LogoWhiteImg} />
                </LogoWrap>
                <Menu>
                    {/* <Link to={`/user/dashboard`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                        <Item>
                            <Dashboard className={classes.MenuIcons}/>
                            <ItemText>Dashboard</ItemText>
                        </Item>
                    </Link> */}
                    <Link to={`/user/orders`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                        <Item>
                            <StoreOutlined className={classes.MenuIcons}/>
                            <ItemText>Orders</ItemText>
                        </Item>
                    </Link>
                    <Link to={`/user/collections`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                        <Item>
                            <Collections className={classes.MenuIcons}/>
                            <ItemText>Collections</ItemText>
                        </Item>
                    </Link>
                    <Link to={`/user/Products`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                        <Item>
                            <LocalOffer className={classes.MenuIcons}/>
                            <ItemText>Products</ItemText>
                        </Item>
                    </Link>
                    {/* <Link to={`/user/messages`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                        <Item>
                            <MailOutline className={classes.MenuIcons}/>
                            <ItemText>Messages (2)</ItemText>
                        </Item>
                    </Link> */}
                    {/* <Link to={`/`} style={{ textDecoration: 'none' }}>
                        <Item>
                            <BarChart className={classes.MenuIcons}/>
                            <ItemText>Statistics</ItemText>
                        </Item>
                    </Link> */}
                    <UserMenu>
                        {/* <Link to={`/user/account`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                            <UserItem>
                                <PermIdentity className={classes.MenuIcons}/>
                                <UserItemText>Account</UserItemText>
                            </UserItem>
                        </Link> */}
                        <UserItem onClick={handleLogout}>
                            <ExitToApp className={classes.MenuIcons}/>
                            <UserItemText>Logout</UserItemText>
                        </UserItem>
                    </UserMenu>
                </Menu>

            </Wrapper>
        </MainWrap>
    </Container>
  )
}
