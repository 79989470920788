import React, { useState, useEffect } from 'react';
import "../../assets/css/menuIconStyle.css";
import styled from 'styled-components';
import { mobile, tablet } from '../../responsive';
import { makeStyles } from '@material-ui/core/styles';
import { 
    BarChart,
    PermContactCalendar,
    Collections,
    Settings,
    StoreOutlined,
    ArrowBack,
    ChevronRight
 } from "@material-ui/icons";
import { Link } from "react-router-dom";

const Container = styled.div`
    width: 210px;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #B8F904;
    transition: all 0.3s ease;
    z-index: 10;
    overflow-y: scroll;
    transform: ${props=>props.dragging === true ? "translateX(0px)" : "translateX(-221%)"};
    @media (max-width: 768px) {
        transform: ${props=>props.dragging === true ? "translateX(0%)" : "translateX(-101%)"};
    }
    ${mobile({
        width: "100%",
    })}
`;
const Wrapper = styled.div`
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
`;
const CloseBtnWrap = styled.div`
    /* width: 30px;
    height: 30px; */
    padding: 4px;
    border-radius: 50%;
    background-color: #000;
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
`;
const Category = styled.div`
     width: 100%;
     padding: 20px 20px;
     border-bottom: 1px solid #00000010;
`;
const HeadText = styled.div`
    color: #000;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
`;
const ItemsWrap = styled.div`
    width: 100%;
    margin-top: 20px;
`;
const ItemText = styled.div`
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-right: 10px;
    text-transform: capitalize;
    transition: all 0.3s ease;
`;
const ItemCount = styled.div`
    color: #00000050;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease;
`;
const Item = styled.div`
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 0px;
    transition: all 0.3s ease;
    border-bottom: 1px solid #000000;
    cursor: pointer;
    &:hover {

        padding: 10px 0px 10px 5px;

        ${ItemText} {
            color: #000000;
        }
        ${ItemCount} {
            color: #00000080;
        }
    }
`;
const useStyles = makeStyles({
    BackIcon: {
      color: "#B8F904",
      fontSize: 18
    },
    ForwardIcon: {
        color: "#00000050",
        fontSize: 22
      },
  })
export default function SortCategories({
    isSortOpen, 
    setIsSortOpen,
    collections,
    getProductsByCollection
}) {
    const classes = useStyles();


    const CloseSort = () => {
        setIsSortOpen(false);
    }; 


  return (
    <Container dragging={isSortOpen}>
        <Wrapper>
            <CloseBtnWrap onClick={CloseSort}>
                <ArrowBack className={classes.BackIcon} />
            </CloseBtnWrap>
            <Category>
                <ItemsWrap>
                    <Item onClick={()=>getProductsByCollection(0)}>
                        <ItemText>All Products</ItemText>
                        {/* <ChevronRight className={classes.ForwardIcon}/> */}
                    </Item>
                </ItemsWrap>
            </Category>
            <Category>
                <HeadText>Sort by collections</HeadText>
                <ItemsWrap>
                {collections.map(col => (
                    <Item key={col.id} onClick={()=>getProductsByCollection(col)}>
                        <ItemText>{col.name}</ItemText>
                        {/* <ItemCount>(12)</ItemCount> */}
                    </Item>
                ))}
                </ItemsWrap>
            </Category>
        </Wrapper>
    </Container>
  )
}
