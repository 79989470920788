import React from 'react';
import styled from 'styled-components';
import {theme_colors} from '../data';
import { mobile, mobileXL, tablet } from '../responsive';
import { useNavigate, Link } from "react-router-dom";

import bgImg from '../assets/images/notfound.png';


const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme_colors.bg};
  margin-bottom: 100px;
  ${mobile({
    marginBottom: "50px",
  })}
`;
const Wrapper = styled.div`
    width: 90%;
    //margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border: 1px solid #000;
    ${tablet({
        marginTop: "50px",
    })}
    ${mobileXL({
        width: "85%",
    })}
    ${mobile({
        width: "100%",
        padding: "0px 15px",
        flexDirection: "column"
    })}
`;
const Left = styled.div`
    width: 50%;
    ${mobile({
        width: "100%",
        display: "none"
    })}
`;
const Right = styled.div`
    width: calc(50% - 40px);
    ${mobile({
        width: "100%",
    })}
`;
const LeftImg = styled.img`
    width: 100%;
`;
const RightWrap = styled.div`
    width: 100%;
    max-width: 300px;
    ${mobile({
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    })}

`;
const Header = styled.div`
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 65px;
    color: ${theme_colors.blue};
    /* ${tablet({
        fontSize: "17px",
        lineHeight: "23px"
    })} */
    ${mobile({
        // marginRight: "0px",
        // fontSize: "21px",
        // marginBottom: "20px",
        textAlign: "center"
    })}
`;
const SubHead = styled.div`
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 25px;
    color: ${theme_colors.black};
    /* ${tablet({
        fontSize: "17px",
        lineHeight: "23px"
    })} */
    ${mobile({
        // marginRight: "0px",
        // fontSize: "21px",
        // marginBottom: "20px",
        textAlign: "center"
    })}
`;
const Desc = styled.div`
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${theme_colors.black};
    /* ${tablet({
        fontSize: "17px",
        lineHeight: "23px"
    })} */
    ${mobile({
        // marginRight: "0px",
        // fontSize: "21px",
        // marginBottom: "20px",
        textAlign: "center"
    })}
`;
const LoginBtnText = styled.div`
    color: ${theme_colors.black};
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    transition: all 0.3s ease;
    ${mobile({
        color: `${theme_colors.black}`
    })} 
`;

const LoginBtn = styled.div`
    margin-top: 30px;
    width: 170px;
    height: 45px;
    margin-right: 10px;
    background-color: ${theme_colors.lime};
    //border: 1px solid ${theme_colors.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 25px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: ${theme_colors.black};
        ${LoginBtnText} {
            color: ${theme_colors.lime};
        }
        }
    ${mobile({
        height: "60px",
        width: "100%",
        backgroundColor: `${theme_colors.lime}`,
        border: "none"
    })}  
`;

export default function InvalidUrl() {
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  };

  return (
    <Container>
      <Wrapper>
        <Left>
          <LeftImg src={bgImg} />
        </Left>
        <Right>
          <RightWrap>
            <Header>404</Header>
            <SubHead>Not found</SubHead>
            <Desc>
              The page you're looking for is not found or you assembled the link incorrectly
            </Desc>
              <LoginBtn onClick={()=> handleNavigate("/")}>
                  <LoginBtnText>go to website</LoginBtnText>
              </LoginBtn>
          </RightWrap>
        </Right>
      </Wrapper>
    </Container>
  )
}
