//import MainApp from './layouts/MainApp'
import PrivateRoutes from './utils/PrivateRoutes';

//import Home from './pages/main/Home';
import Register from './pages/Register';
import VerifyEmail from './pages/VerifyEmail';

import InvalidUrl from './pages/InvalidUrl';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

import Orders from './pages/order/Orders';
import Order from './pages/order/Order';

import Profiles from './pages/profile/Profiles';
import Profile from './pages/profile/Profile';
import AddProfile from './pages/profile/AddProfile';

import Collections from './pages/collection/Collections';
import Collection from './pages/collection/Collection';
import AddCollection from './pages/collection/AddCollection';

import Products from './pages/product/Products';
import Product from './pages/product/Product';
import AddProduct from './pages/product/AddProduct';

import Messages from './pages/message/Messages';
import Message from './pages/message/Message';

import Account from './pages/account/Account';

// import Test from './pages/Test';
// import TestQrCode from './pages/TestQrCode';
// import TestQrCode2 from './pages/TestQrCode2';
// import TestStart from './pages/TestStart';
// import TestStart2 from './pages/TestStart2';


import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const App = () => {


  return (
    <Router>
      <Routes>
        {/* <Route path="/test" element={<TestQrCode2  />} /> */}

        <Route path="/invalidurl" element={<InvalidUrl />} />
        <Route path="/*" element={<InvalidUrl />} />

        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/VerifyEmail/:userId/:code" element={<VerifyEmail />} />

        <Route path="/user" element={<PrivateRoutes />}>
          <Route path="/user/dashboard" element={<Dashboard />} />
          {/* <Route path="/user/test" element={<Test />} /> */}

          <Route path="/user/orders" element={<Orders />} />
          <Route path="/user/order/:id" element={<Order />} />

          <Route path="/user/profiles" element={<Profiles />} />
          <Route path="/user/profile/:id" element={<Profile />} />
          <Route path="/user/profile/add" element={<AddProfile />} />

          <Route path="/user/collections" element={<Collections />} />
          <Route path="/user/collection/:id" element={<Collection />} />
          <Route path="/user/collection/add" element={<AddCollection />} />

          <Route path="/user/products" element={<Products />} />
          <Route path="/user/product/:id" element={<Product />} />
          <Route path="/user/product/add" element={<AddProduct />} />

          <Route path="/user/messages" element={<Messages />} />
          <Route path="/user/message" element={<Message />} />

          <Route path="/user/account" element={<Account />} />
        </Route>

      </Routes>
    </Router>
  );

};

export default App;



