import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {  useLocation, Link } from "react-router-dom";
import { mobile, tablet } from '../responsive';
import { publicRequest } from "../requestMethods";

import { makeStyles } from '@material-ui/core/styles';
import { 
    Contacts ,
    Collections,
    StoreOutlined
  } from "@material-ui/icons";

  import "../assets/css/mainStyle.css";
  import BgImage from '../assets/images/register_bg2.jpg';
  import StarsImg from '../assets/images/stars.png';

  const Container = styled.div`
    width: 100%;
    height: 100vh;
  ${mobile({
      padding: "50px 15px"
  })}  
`;
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    ${mobile({
      // height: "auto",  
      // marginBottom: "30px"
  })}  
`;
const Left = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${mobile({
        width: "100%",
        flexDirection: "column",
    })}  
`;
const LeftWrap = styled.div`
    width: 80%;
    max-width: 400px;
    overflow: hidden;
    ${mobile({
        width: "100%"
    })} 
`;
const SectionWrap = styled.div`
    width: 300%;
    display: flex;
    transition: all 0.3s ease;
    transform: translateX(${props=>props.xValue}%);
`;
const Section = styled.div`
    width: 100%;
`;


//// Invalid section
const InvalidLink = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;

`;
const InvalidHead = styled.div`
    color: #000;
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 10px;
`;
const InvalidDesc = styled.div`
    color: #747474;
    font-size: 16px;
    font-weight: 300;
`;



////// Section B
const Loader = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

/////// Section C
const Confirmation = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;
`;

const ConfirmHead = styled.div`
    color: #000;
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 10px;
`;
const ConfirmText = styled.div`
    color: #747474;
    font-size: 16px;
    font-weight: 300;
`;



const Right = styled.div`
    width: 40%;
    height: 100%;
    background-color: #d4d4d4;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: cover;
    background-position: center;
    ${mobile({
      display: "none"
    })}  
`;

const RightWrap = styled.div`
    width: 100%;
    margin: 0px 20px 40px 20px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
`;
const Top = styled.div`
    width: 100%;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
`;

const Bottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
const BottomLeft = styled.div`
    width: calc(50% - 10px);
`;
const RefName = styled.div`
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    //margin-bottom: 5px;
`;
const RefJob = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    //margin-bottom: 5px;
`;
const RefDesc = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    text-transform: capitalize;
`;
const BottomRight = styled.div`
    width: calc(50% - 10px);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;
const Stars = styled.div`
    width: 90px;
`;
const Star = styled.img`
    width: 100%;
`;




  const useStyles = makeStyles({
    ActionIcons: {
        color: "#00000060",
        fontSize: 18,
        cursor: "pointer",
        '@media (max-width: 768px)': {
          fontSize: 22,
        }
    },
    AddIcon: {
      color: "#000",
      fontSize: 35
    },
    SortIcon: {
      color: "#fff",
      fontSize: 15
    },
  })
  
export default function VerifyEmail() {
    const classes = useStyles();

    const location = useLocation();

    const userId = location.pathname.split("/")[2];
    const code = location.pathname.split("/")[3];

    // console.log(userId, code);

    const [sectionXvalue, setSectionXvalue] = useState(-(100/3));


    const handleVerifyEmail = async () => {
        
        try {
            const res = await publicRequest.get("/Authentication/ConfirmEmail/" + userId + "/" + code);
            // console.log(res.data);
            setSectionXvalue(-((2/3)*100));

        } catch (err) {
            // let _response = {};
            // if(err.response) {
            //     _response = err.response.data.errors;
            // }else {
            //     _response = "No connection"
            // }
            setSectionXvalue(0);
        }
    }


    useEffect(()=>{

        handleVerifyEmail();

   },[]);

       


  return (
    <Container>
        <Wrapper>
            <Left>
                <LeftWrap>
                    <SectionWrap xValue={sectionXvalue}>
                        <Section>
                          <InvalidLink>
                            <InvalidHead>email verification failed!</InvalidHead>
                            <InvalidDesc>
                                The verification link is invalid. <Link to={`/`} style={{ textDecoration: 'none' }}><span className="new_link">Request a new link</span></Link> or <Link to={`/register`} style={{ textDecoration: 'none' }}><span className="new_link">Create a free account</span></Link>
                            </InvalidDesc>
                          </InvalidLink>

                        </Section>
                        <Section>
                            <Loader>
                                <span className="loader"></span>
                            </Loader>
                        </Section>
                        <Section>
                            <Confirmation>
                                <ConfirmHead>Registration complete</ConfirmHead>
                                <ConfirmText>
                                    Your account was verified successfully. <Link to={`/`} style={{ textDecoration: 'none' }}><span className="new_link">Go to login page</span></Link>
                                </ConfirmText>
                            </Confirmation>
                        </Section>
                    </SectionWrap>
                </LeftWrap>
            </Left>
            <Right style={{ backgroundImage: `url(${BgImage})`}} >
                <div className='right_wrap'>
                    {/* <Frost /> */}
                    <Top>
                        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                    </Top>
                    <Bottom>
                        <BottomLeft>
                            <RefName>Andi lane</RefName>
                            <RefJob>Founder, catalog</RefJob>
                            <RefDesc>web design agency</RefDesc>
                        </BottomLeft>
                        <BottomRight>
                            <Stars>
                                <Star src={StarsImg}/>
                            </Stars>
                        </BottomRight>
                    </Bottom>
                </div>
            </Right>
        </Wrapper>
    </Container>
  )
}
