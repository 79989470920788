import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState : {
        currentUser: {
            userId: "",
            userName: ""
        },
        accessToken: ""
    },
    reducers: {
        LoginUser:(state,action)=>{
            state.currentUser = action.payload.user;
            state.accessToken = action.payload.token;
        },
        LogoutUser:(state)=>{
            state.currentUser = {
                userId: "",
                userName: ""
            };
            state.accessToken = "";
        },
        SetUserToken:(state,action)=>{
            state.accessToken = action.payload;
        },
    },
});

export const { LoginUser, LogoutUser, SetUserToken} = userSlice.actions;
export default userSlice.reducer;