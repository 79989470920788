import {createSlice} from "@reduxjs/toolkit";


const categorySlice = createSlice({
    name: "category",
    initialState : {
        categories : []
    },
    reducers: {

        SetCategories: (state, action) => {
            state.categories = action.payload;
        },
        AddCategories: (state, action) => {
            state.categories.push(action.payload);
        },
        UpdateCategories: (state, action) => {
            let new_updated_data = 
                state.categories.map((col) => {
                    if (col.id === action.payload.id) {
                        return {
                            ...col,
                            ...action.payload,
                        };
                    }
                    return col;
                });
            
            state.categories = new_updated_data;
        },
        RemoveCategory: (state, action) => {
            state.categories.splice(
                state.categories.findIndex((x) =>  x.id === action.payload ),
                1
              );
        },
        ClearCategories: (state) => {
            state.categories = [];
        },
    },
});

export const { SetCategories, AddCategory, UpdateCategory, RemoveCategory, ClearCategories } = categorySlice.actions;
export default categorySlice.reducer;